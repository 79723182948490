/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';
import useSignup from 'components/SignUp/useSignup';
import useScrollDown from 'hooks/useScrollDown';
import { ICONS, LOGO } from 'constants/images';
import { LOGIN } from 'constants/routes';
import useAuthorisation from 'hooks/useAuthorisation';
import Button from 'components/Common/Button';

enum SignUpFields {
  email = 'email',
  password = 'password',
}

const Signup: React.FC = () => {
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const [isMaxUsers, setIsMaxUsers] = useState(true);
  const {
    handleInputChange,
    form,
    handleSignupClicked,
    handleGoogleSignupClicked,
    handleFacebookSignupClicked,
    handleTwitterSignupClicked,
    handleGithubSignupClicked,
    handleMicrosoftSignupClicked,
    requestError,
  } = useSignup();

  const { getAppUsage, appUsageData } = useAuthorisation();

  const [isMobile, setIsMobile] = useState<boolean>(false);
  const history = useHistory();

  useScrollDown(() => setShowLoginForm(true));


  useEffect(() => {
    const mobile = window.innerWidth <= 770;

    if (mobile) {
      setIsMobile(mobile);
      setShowLoginForm(true);
    }
  }, []);

  const scrollDownClass = classNames({
    'scroll-down': true,
    hidden: showLoginForm,
  });
  const signInClass = classNames({
    'sign-up': true,
    visible: showLoginForm,
  });

  useEffect(() => {
    if (!appUsageData) {
      getAppUsage();
    } else {
      const userCount = appUsageData?.users?.length || 0;
      if (userCount <= appUsageData.maxUsers) {
        setIsMaxUsers(false);
      } else {
        setIsMaxUsers(true);
      }
    }
  }, [appUsageData, getAppUsage]);

  return (
    <div className="navigation">
      <div className="navigation__main-panel">
      {!isMobile && (
        <a className="half-circle-btn half-circle-btn-left" href="#">
          Learn
        </a>
             )}
        <div className="sign-up-wrapper">
          <p className="upper-logo-text">Welcome to</p>
          <img className="main-logo"
           src={LOGO.WHITE_BIG} alt="Jectrospects logo" />
          <div className={signInClass}>
            {isMaxUsers ? (
              <p  className="sign-up--social-label">
                Maximum amout of users has been reached.</p>
            ) : (
              <>
                <input
                  type="text"
                  className="sign-up__input"
                  placeholder="Email"
                  onChange={handleInputChange}
                  name={SignUpFields.email}
                  value={form.email}
                />
                <input
                  type="password"
                  className="sign-up__input"
                  placeholder="Password"
                  onChange={handleInputChange}
                  name={SignUpFields.password}
                  value={form.password}
                />
                <div
                  className="sign-up__button sign-up--email"
                  onClick={handleSignupClicked}
                  onKeyDown={handleSignupClicked}
                  role="button"
                  tabIndex={-1}
                >
                  <i className="fa fa-envelope sign-up__icon-email" />
                  Sign up
                </div>
                {requestError.length > 0 && (
                  <p className="sign-up__error">{requestError}</p>
                )}
                <p className="sign-up--social-label">
                  Or create an account using social media
                </p>
                <div className="sign-up__socials-container">
                  <button
                    type="button"
                    onClick={handleGoogleSignupClicked}
                    className="sign-up__button sign-up--google"
                  >
                    <i className="fab fa-google sign-up__icon" />
                  </button>
                  <button
                    type="button"
                    onClick={handleFacebookSignupClicked}
                    className="sign-up__button sign-up--facebook"
                  >
                    <i className="fab fa-facebook-square sign-up__icon" />
                  </button>
                  <button
                    type="button"
                    onClick={handleTwitterSignupClicked}
                    className="sign-up__button sign-up--twitter"
                  >
                    <i className="fab fa-twitter sign-up__icon" />
                  </button>
                  <button
                    type="button"
                    onClick={handleGithubSignupClicked}
                    className="sign-up__button sign-up--github"
                  >
                    <i className="fab fa-github sign-up__icon" />
                  </button>
                  <button
                    type="button"
                    onClick={handleMicrosoftSignupClicked}
                    className="sign-up__button sign-up--microsoft"
                  >
                    <i className="fab fa-microsoft sign-up__icon" />
                  </button>
                </div>
              </>
            )}
          </div>
          <div className={scrollDownClass}>
            <img
              className="down-arrow"
              src={ICONS.DOWN_ARROW}
              alt="arrow pointing down"
            />
            <p className="lading-page-text">Scroll to sign up</p>
          </div>
        </div>
        {!isMobile && (
        <Link to={LOGIN} className="half-circle-btn half-circle-btn-right">
          Login
        </Link>
         )}
        {isMobile && (
          <div className="bottom-btns">
            <Button
              styleType="lightGreen"
              onClick={() => history.push(LOGIN)}
            >
              Login
            </Button>
            <Button styleType="lightGreen" onClick={() => history.push('#')}>
              Learn
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Signup;
