
import { getAvatar } from 'api/queries';
import { ICONS } from 'constants/images';
import React, { useEffect, useState } from 'react'

    
type Props = {
    email: string | undefined;
}

 const ProjectReviewer: React.FC<Props> = ({email}: Props) => {

const [avatarUrl, setAvatarUrl] = useState<string>(ICONS.USER_PLACEHOLDER)
  useEffect(() => {
    getAvatar(email).then(url => {
      setAvatarUrl(url)})
  }, [email])


  return <img
   src={avatarUrl}
   className="reviewer"
   alt="reviewer"
 />
  
}
export default ProjectReviewer