import Resizer from "react-image-file-resizer";

  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const resizeFile = (file: File) =>
  new Promise((resolve) => {
    Resizer.imageFileResizer(file, 400, 400, 'PNG', 80, 0, (uri) => 
    resolve(uri),
    "file");
  });

  export default resizeFile