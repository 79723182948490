import React, { useReducer } from 'react';

import { TCreateTemplateActions } from 'context/modules/createTemplate/actions';
import reducer from 'context/modules/createTemplate/reducer';

import { ITemplateField } from 'models/createTemplate';
import { initialState } from './modules/createTemplate/helpers';

interface ICreateTemplateProvider {
  children: React.ReactNode;
}

export interface ICreateTemplateContext {
  questionsList: ITemplateField[];
  dispatch: React.Dispatch<TCreateTemplateActions>;
}

export const CreateTemplateContext =
  React.createContext<ICreateTemplateContext>({
    questionsList: initialState.questionsList,
    dispatch: () => undefined,
  });

const CreateTemplateProvider: React.FC<ICreateTemplateProvider> = ({
  children,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const { questionsList } = state;

  const value = {
    questionsList,
    dispatch,
  };

  return (
    <>
      <CreateTemplateContext.Provider value={value}>
        {children}
      </CreateTemplateContext.Provider>
    </>
  );
};

export default CreateTemplateProvider;