
import {
  useEffect,
  useState
} from 'react';
import {
  signOut as signOutFirebase,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  onAuthStateChanged,
  isSignInWithEmailLink,
  signInWithEmailLink,
  sendEmailVerification,
  User,
  GoogleAuthProvider,
  FacebookAuthProvider,
  TwitterAuthProvider,
  GithubAuthProvider,
  OAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useHistory } from 'react-router-dom';

import {
  auth
} from 'api/config';
import { UserRoles } from 'models/roles';
import {
  LOGIN,
  ONBOARDING,
} from 'constants/routes';
import { queries } from 'api';
import { Collections } from 'api/constants';
// eslint-disable-next-line camelcase
import { unstable_batchedUpdates } from "react-dom";


interface IUseAuthorisation {
  isLoading: boolean;
  isUserLoggedIn: boolean;
  isUserEmailVerified: boolean | undefined;
  currentUser: User | null;
  userRole?: UserRoles;
  hasPasswordLoginMethod: boolean;
  signOut: () => Promise<void>;
  signIn: (email: string, password: string) => Promise<void>;
  signInWithGoogle: () => Promise<void>;
  signInWithFacebook: () => Promise<void>;
  signInWithTwitter: () => Promise<void>;
  signInWithGithub: () => Promise<void>;
  sendVerificationEmail: () => Promise<void>;
  signInWithMicrosoft: () => Promise<void>;
  sendForgotPasswordEmail: (email: string) => Promise<void>;
  signInWithLinkFromEmail: (email: string) => Promise<void>;
  signUpWithEmailAndPassword: (email: string, password: string) =>
    Promise<void>;
  refreshAuthState: () => void
  refreshAuthRoleState: () => void
  getAppUsage: () => void
  appUsageData: any
  subscriptionStatus: string
}

const useAuthorisation = (): IUseAuthorisation => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = useState<boolean>(false);
  const [userRole, setUserRole] = useState<UserRoles>();
  const [isUserEmailVerified, setIsUserEmailVerified] = useState
    <boolean | undefined>();
  const [hasPasswordLoginMethod, setHasPasswordLoginMethod] = useState(false)
  const [appUsageData, setAppUsageData] = useState()
  const [subscriptionStatus, setSubscriptionStatus] = useState('none')

  // Social Media Authentication
  const googleProvider = new GoogleAuthProvider();
  const twitterProvider = new TwitterAuthProvider();
  const githubProvider = new GithubAuthProvider();
  const microsoftProvider = new OAuthProvider('microsoft.com');
  const facebookProvider = new FacebookAuthProvider();
  facebookProvider.setCustomParameters({
    'display': 'popup'
  });

  const history = useHistory();

  const { currentUser } = auth;

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      unstable_batchedUpdates(() => {
        if (user) {
          user?.getIdTokenResult().then(IdTokenResult => {
            if (IdTokenResult.claims) {
              setIsUserEmailVerified(
                Boolean(IdTokenResult?.claims?.email_verified))
              if (!IdTokenResult.claims.email_verified) {
                user.reload()
              }
              setUserRole(IdTokenResult.claims.stripeRole as UserRoles)
              setSubscriptionStatus(
                String(IdTokenResult?.claims?.subscriptionStatus))
            }

            if (user.providerData && user.providerData.find(d => d &&
              d.providerId === 'password')) {

              setHasPasswordLoginMethod(true)
            }
          });

          setIsUserLoggedIn(true)
          setIsLoading(false);
        } else {
          setIsLoading(false);
        }
      });

    });

    return () => unsubscribe();
  }, [isLoading]);


  const refreshAuthState = (): void => {
    const authInterval = setInterval(() => {
      if (window.location.pathname === ONBOARDING
        && !isUserEmailVerified) {
        auth.currentUser?.reload().then(() => {
          setIsUserEmailVerified(auth.currentUser?.emailVerified)
          if (auth.currentUser?.emailVerified) {
            clearInterval(authInterval);
          }
        })
      } else {
        clearInterval(authInterval);
      }
    }, 2000)
  }

  const refreshAuthRoleState = (): void => {
    const authInterval = setInterval(() => {
      if (window.location.pathname === ONBOARDING
      ) {
        auth.currentUser?.reload().then(async () => {
          await auth?.currentUser?.getIdToken(true)
          const decodedToken = await auth?.currentUser?.getIdTokenResult();
          if (decodedToken?.claims.stripeRole) {
            setUserRole(decodedToken?.claims.stripeRole as UserRoles)
            setSubscriptionStatus(
              String(decodedToken?.claims?.subscriptionStatus))
            clearInterval(authInterval);
          }
        })
      } else {
        clearInterval(authInterval);
      }
    }, 2000)
  }

  const signOut = async (): Promise<void> => {
    await signOutFirebase(auth).then(() => {
      window.location.replace(LOGIN)
    });
  };

  const signInWithGoogle = async (): Promise<void> => {
    try {
      await signInWithPopup(auth, googleProvider)

    } catch (err) {
      console.error(err);
    }
  };

  const signInWithFacebook = async (): Promise<void> => {
    try {
      await signInWithPopup(auth, facebookProvider)

    } catch (err) {
      console.error(err);
    }
  };

  const signInWithTwitter = async (): Promise<void> => {
    try {
      await signInWithPopup(auth, twitterProvider)

    } catch (err) {
      console.error(err);
    }
  };

  const signInWithGithub = async (): Promise<void> => {
    try {
      await signInWithPopup(auth, githubProvider)

    } catch (err) {
      console.error(err);
    }
  };

  const signInWithMicrosoft = async (): Promise<void> => {
    try {
      await signInWithPopup(auth, microsoftProvider)

    } catch (err) {
      console.error(err);
    }
  };


  const signInWithLinkFromEmail = async (email: string): Promise<void> => {
    if (isSignInWithEmailLink(auth, window.location.href)) {
      await signInWithEmailLink(auth, email, window.location.href)
    }
  };

  const signUpWithEmailAndPassword = async (email: string, password: string):
    Promise<void> => {
    await createUserWithEmailAndPassword(auth, email, password)
      .then(() => {
        if (auth?.currentUser) {
          sendEmailVerification(auth.currentUser)
        }
      })
      .then(() => history.push(ONBOARDING))
  };

  const signIn = async (email: string, password: string): Promise<void> => {
    await signInWithEmailAndPassword(auth, email, password);
  };

  const sendVerificationEmail = async (): Promise<void> => {
    try {
      if (auth?.currentUser) {
        await sendEmailVerification(auth.currentUser)
      }
    }
    catch (err) {
      console.log(err);
    }
  };


  const sendForgotPasswordEmail = async (email: string): Promise<void> => {
    await sendPasswordResetEmail(auth, email, {
      url: `${window.location.origin}/log-in`
    });
  };

  const getAppUsage = (): Promise<void> =>
    queries.getDocument(Collections.APP_USAGE, 'usageData', setAppUsageData)

  return {
    isLoading,
    isUserLoggedIn,
    currentUser,
    userRole,
    signOut,
    signIn,
    signInWithLinkFromEmail,
    hasPasswordLoginMethod,
    signUpWithEmailAndPassword,
    isUserEmailVerified,
    sendVerificationEmail,
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    signInWithGithub,
    signInWithMicrosoft,
    sendForgotPasswordEmail,
    refreshAuthState,
    refreshAuthRoleState,
    getAppUsage,
    appUsageData,
    subscriptionStatus
  };
};

export default useAuthorisation;
