import React, { useState, MouseEvent } from 'react';
import ContentContainer from 'components/Common/ContentContainer';
import UploadImage from 'components/Common/UploadImage';
import Button from 'components/Common/Button';
import { CREATE_PROJECT } from 'constants/routes';
import { IAddProject, ProjectPhase } from 'models/addProject';
// import { PLACEHOLDER_URL } from 'constants/common';
import { useHistory } from 'react-router-dom';
import PhaseReviewers from 'components/Common/PhaseReviewers';
import { PhaseReviewsTypes } from 'models/phaseReview';
import { ITemplateField } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';
import classNames from 'classnames';
import Questions from './Questions';

const checkSectionStatus = (
  selectedString: string,
  sectionId: string,
  formValues: IAddProject,
): string => {
  let status = '';
  if (selectedString === sectionId) {
    status = 'active';
  } else if (
    formValues.name &&
    formValues.clientName &&
    sectionId === 'projectInformation'
  ) {
    status = 'complete';
  } else if (!!formValues.phases.length && sectionId === 'projectPhases') {
    status = 'complete';
  } else if (
    !!formValues.phaseReviewers.length &&
    sectionId === 'phaseReviewers'
  ) {
    status = 'complete';
  } 
  // else if (
  //   formValues.logoSrc &&
  //   formValues.logoSrc !== PLACEHOLDER_URL &&
  //   sectionId === 'projectLogo'
  // ) {
  //   status = 'complete';
  // } 
  else if (
    formValues.status === 'published' &&
    sectionId === 'projectPublish'
  ) {
    status = 'complete';
  } else if (formValues.id) {
    status = 'error';
  }

  return status;
};

interface IProjectForm {
  form: IAddProject;
  onChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  isLoading: boolean;
  votingHasStarted: boolean;
  handleAddProjectInformationClick: () => void;
  handleAddProjectPhasesClick: () => void;
  handleAddPhaseReviewersClick: () => void;
  handleAddProjectLogoClick: () => void;
  templates: {
    name: string;
    id: string;
  }[];
  setProjectPhases: (templateId: string) => Promise<void>;
  // onPhaseRemove: (index: number) => void;
  showAddReviewer: boolean;
  reviewerEmail: string;
  handleRemovePhaseReviewer: (index: number) => void;
  handleAddReviewerClick: () => void;
  addProjectQuestion: (event: MouseEvent<HTMLElement>) => void;
  handleAddReviewerKeyDown: () => void;
  handleReviewerEmailInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleLogoSelect: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  setFormQuestions: (phases: ProjectPhase[]) => void;
  handlePublishProject: () => void;
}

const ProjectForm: React.FC<IProjectForm> = ({
  form,
  onChange,
  isLoading,
  votingHasStarted,
  handleAddProjectInformationClick,
  handleAddProjectPhasesClick,
  handleAddPhaseReviewersClick,
  handleAddProjectLogoClick,
  templates,
  setProjectPhases,
  showAddReviewer,
  reviewerEmail,
  handleRemovePhaseReviewer,
  handleAddReviewerClick,
  handleAddReviewerKeyDown,
  handleReviewerEmailInput,
  handleLogoSelect,
  addProjectQuestion,
  setFormQuestions,
  handlePublishProject,
}) => {
  const [selectedSection, setSelectedSection] = useState('projectInformation');
  const [showAddQuestionButton, setShowAddQuestionButton] =
    useState<boolean>(true);
  const history = useHistory();
  const isMobile = window.innerWidth <= 800; 

  const toggleAddQuestion = (): void => {
    setShowAddQuestionButton((prev) => !prev);
  };

  const addQuestionContainerClassNames = classNames({
    'add-question-container': true,
    hidden: !showAddQuestionButton || votingHasStarted,
  });

  const chooseQuestionClassNames = classNames({
    'not-visible': showAddQuestionButton,
    'choose-question': true,
  });

  const PROJECT_FORM_SECTIONS = [
    {
      id: 'projectInformation',
      status: '',
      title: 'Project Information',
      description: 'High level overview details about your project',
      onClick: () => setSelectedSection('projectInformation'),
      component: (
        <ContentContainer isHidden={selectedSection !== 'projectInformation'}>
          <h3>Setup Project Details</h3>
          <div className="form-field-container">
            <label>Project Name</label>
            <input
              name="name"
              type="text"
              value={form.name}
              onChange={onChange}
              disabled={form.status === 'published'}
            />
          </div>
          <div className="form-field-container">
            <label>Client</label>
            <input
              name="clientName"
              type="text"
              value={form.clientName}
              onChange={onChange}
              disabled={form.status === 'published'}
            />
          </div>
          <div className="form-field-container">
            <label>Description</label>
            <textarea
              name="description"
              value={form.description}
              onChange={onChange}
              rows={8}
              disabled={form.status === 'published'}
            />
          </div>
          <Button
            isLoading={isLoading}
            isDisabled={
              (!form.name && !form.clientName) || form.status === 'published'
            }
            onClick={() => {
              handleAddProjectInformationClick();
              setSelectedSection('projectPhases');
            }}
          >
            Save and Next
          </Button>
        </ContentContainer>
      ),
    },
    {
      id: 'projectPhases',
      isDisabled: !form.id,
      status: '',
      title: 'Phase Template',
      description: 'Detailed phases with questions for reviewers',
      onClick: () => setSelectedSection('projectPhases'),
      component: (
        <ContentContainer isHidden={selectedSection !== 'projectPhases'}>
          <h3>Manage Phase Template</h3>
          <div className="form-field-container">
            <label>Phase Template</label>
            <select
              name="selectedTemplateId"
              value={form.selectedTemplateId}
              onChange={(e) => setProjectPhases(e.target.value)}
              disabled={form.status === 'published'}
            >
              <option value="" disabled hidden>
                Choose your template
              </option>

              {templates?.map((template) => {
                const { name, id } = template;
                return (
                  <option key={id} value={id}>
                    {name}
                  </option>
                );
              }) || null}
            </select>
          </div>
          <div>
            <Questions
              setFormQuestions={setFormQuestions}
              projectQuestions={form.phases as ITemplateField[]}
              isDisabled={form.status === 'published'}
            />
            <div
              id="template-options"
              className="template-options full-width-container"
            />
            {form.status !== 'published' && (
              <div className="template-options__add-question no-focus-outline">
                <div className={addQuestionContainerClassNames}>
                  <Button
                    type="button"
                    onClick={toggleAddQuestion}
                    styleType="secondary"
                  >
                    <i className="fas fa-plus" />
                    Add Phase Question
                  </Button>
                </div>
                <div className={chooseQuestionClassNames}>
                  <p>Pick question type:</p>
                  <div className="choose-question__options no-focus-outline">
                    <Button
                      styleType="secondary"
                      id={TemplateFieldTypes.TEXT_CHOISE}
                      onClick={(e) => {
                        addProjectQuestion(e);
                        setShowAddQuestionButton(true);
                      }}
                    >
                      Text
                    </Button>
                    <Button
                      id={TemplateFieldTypes.IMAGE_CHOISE}
                      styleType="secondary"
                      onClick={(e) => {
                        addProjectQuestion(e);
                        setShowAddQuestionButton(true);
                      }}
                    >
                      Image
                    </Button>
                    <Button
                      id={TemplateFieldTypes.YES_NO_DECISION}
                      styleType="secondary"
                      onClick={(e) => {
                        addProjectQuestion(e);
                        setShowAddQuestionButton(true);
                      }}
                    >
                      Yes/No
                    </Button>
                    <Button
                      id={TemplateFieldTypes.TEXT_AREA}
                      styleType="secondary"
                      onClick={(e) => {
                        addProjectQuestion(e);
                        setShowAddQuestionButton(true);
                      }}
                    >
                      TextArea
                    </Button>
                  </div>
                </div>
              </div>
            )}
          </div>
          <Button
            isLoading={isLoading}
            // isDisabled={!!form.selectedTemplateId}
            onClick={() => {
              handleAddProjectPhasesClick();
              setSelectedSection('phaseReviewers');
            }}
            isDisabled={form.status === 'published'}
          >
            Save and Next
          </Button>
        </ContentContainer>
      ),
    },
    {
      id: 'phaseReviewers',
      isDisabled: !form.id,
      status: '',
      title: 'Phase Reviewers',
      description: 'Users that will review and approve your project',
      onClick: () => setSelectedSection('phaseReviewers'),
      component: (
        <ContentContainer isHidden={selectedSection !== 'phaseReviewers'}>
          <h3>Project Review Invitees</h3>
          <div className="form-field-container">
            <label>{`${form.phaseReviewers.length} Invitees`}</label>
            <PhaseReviewers
              phaseReviewers={form.phaseReviewers}
              showAddReviewer={showAddReviewer}
              reviewerEmail={reviewerEmail}
              handleRemovePhaseReviewer={handleRemovePhaseReviewer}
              handleAddReviewerClick={handleAddReviewerClick}
              handleAddReviewerKeyDown={handleAddReviewerKeyDown}
              handleReviewerEmailInput={handleReviewerEmailInput}
              type={PhaseReviewsTypes.ADD_TEMPLATE}
              isDisabled={form.status === 'published'}
            />
          </div>
          <Button
            isLoading={isLoading}
            isDisabled={!form?.phaseReviewers?.length 
              || form.status === 'published'}
            onClick={() => {
              handleAddPhaseReviewersClick();
              setSelectedSection('projectLogo');
            }}
          >
            Save and Next
          </Button>
        </ContentContainer>
      ),
    },
    {
      id: 'projectLogo',
      isDisabled: !form.id,
      status: '',
      title: 'Project Logo',
      description: 'A cover or logo image to easily identify your project',
      onClick: () => setSelectedSection('projectLogo'),
      component: (
        <ContentContainer isHidden={selectedSection !== 'projectLogo'}>
          <h3>Project Image Upload</h3>
          <div className="form-field-container">
            <UploadImage
              index={1}
              logoSrc={form.logoSrc}
              handleFileChange={handleLogoSelect}
              handleRemove={() => handleLogoSelect(undefined)}
              alt="project-logo"
            />
          </div>
          <Button
            isLoading={isLoading}
            isDisabled={form.status === 'published' } 
            onClick={() => {
              handleAddProjectLogoClick();
              setSelectedSection('projectPublish');
            }}
            
          >
            Save and Next
          </Button>
        </ContentContainer>
      ),
    },
    {
      id: 'projectPublish',
      isDisabled: !form.id,
      status: '',
      title: 'Publish Project',
      description: 'Publish your project and send invites',
      onClick: () => setSelectedSection('projectPublish'),
      component: (
        <ContentContainer isHidden={selectedSection !== 'projectPublish'}>
          <h3>Publish Project</h3>
          {form.status !== 'published' ? (
            <>
               <div className="form-field-container">
               <p>
                Publish your changes to send the reviewer invites and make the
                project available for review.
              </p>
               </div>
          
              <Button
                isLoading={isLoading}
                isDisabled={!form?.phaseReviewers?.length}
                onClick={() => {
                  handlePublishProject();
                  setSelectedSection('complete');
                }}
              >
                Publish Project
              </Button>
            </>
          ) : (
            <p>
              Project has been successfully published and reviewing has started.
              No further changes can be made.
            </p>
          )}
        </ContentContainer>
      ),
    },
  ];

  return (
    <ContentContainer className="create-project-form-container">
      <div className="create-project-selector-container">
       {isMobile ?
          <div className="form-field-container">
          <label>Form Stages</label>
          <select className="options"
            value={selectedSection}
            onChange={(e) => setSelectedSection(e.target.value)}
          >
            {PROJECT_FORM_SECTIONS.map((section) => (
               <option value={section.id}>{section.title}</option>
            ))}
          </select>
        </div>
       : 
       <div className="create-project-selections">
          {PROJECT_FORM_SECTIONS.map((section) => (
            <ContentContainer
              className={section.id !== 'projectLogo' ? 
              checkSectionStatus(selectedSection, section.id, form) : ''}
              onClick={section.onClick}
              isDisabled={section?.isDisabled}
            >
              <h4>{section.title}</h4>
              <span>{section.description}</span>
            </ContentContainer>
          ))}
        </div>}
      </div>
      <form className="create-project-form">
        {PROJECT_FORM_SECTIONS.map((section) => section.component)}
        <ContentContainer isHidden={selectedSection !== 'complete'}>
          <h3>Project Setup Complete</h3>
          <p className="project-complete-desc">
            You have successfully created a project.
          </p>

          <Button onClick={() => history.push(CREATE_PROJECT)}>
            Create Another Project
          </Button>
        </ContentContainer>
      </form>
    </ContentContainer>
  );
};

export default ProjectForm;
