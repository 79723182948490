/* eslint-disable no-return-assign */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import classNames from 'classnames';

import { IOption } from 'models/questions';
import { ANSWER_LETTERS } from 'constants/common';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

interface IArrow {
  onClick?: () => void;
  isNext?: boolean;
}

const Arrow: React.FC<IArrow> = ({ onClick, isNext }) => {
  const chevronClassNames = classNames({
    fas: true,
    'fa-chevron-left': !isNext,
    'fa-chevron-right': isNext,
  });

  const sliderNavClassNames = classNames({
    'hide-on-mobile': true,
    'slider-nav-left': !isNext,
    'slider-nav-right': isNext,
  });

  return (
    <div
      className={sliderNavClassNames}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={-1}
    >
      <i className={chevronClassNames} aria-hidden="true" />
    </div>
  );
};

interface ISlick {
  questionOptions: IOption[];
  selectedAnswer?: string;
  displayedValue?: string;
  onAnswerChange: (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
}

const Slick: React.FC<ISlick> = ({
  questionOptions,
  selectedAnswer,
  onAnswerChange,
  displayedValue,
}) => {
  const customSlider = useRef<Slider>(null);

  useEffect(() => {
    const currentSlideIndex = questionOptions.findIndex((option, index) => {
      const answerLetter = ANSWER_LETTERS[index];
      return answerLetter === displayedValue;
    });

    if (customSlider?.current) {
      customSlider.current.slickGoTo(currentSlideIndex, true);
    }
  }, [displayedValue, questionOptions]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <Arrow isNext />,
    prevArrow: <Arrow />,
  };

  return (
    <>
      <Slider {...settings} ref={customSlider}>
        {questionOptions.map((option, index) => {
          const letter = ANSWER_LETTERS[index];
          const isCurrentAnswer = selectedAnswer === letter;

          const logoSelectionBtnClassNames = classNames({
            'logo-selection-btn': true,
            selected: isCurrentAnswer,
          });

          return (
            <div className="modal__slide" key={letter}>
              <div className="modal__slide-selection">
                <p>Vote</p>
                <div
                  role="button"
                  className={logoSelectionBtnClassNames}
                  data-attr={letter}
                  onClick={onAnswerChange}
                  onKeyDown={onAnswerChange}
                  tabIndex={-1}
                >
                  {letter}
                </div>
              </div>
              <img
                className="modal__slide-img"
                src={option.imageSrc}
                alt="preview"
              />
            </div>
          );
        })}
      </Slider>
    </>
  );
};

export default Slick;
