import React from 'react';
import classNames from 'classnames';

import ProjectProgressBar from 'components/Common/ProgressBar';
import useProject from 'components/Dashboard/Project/useProject';

import { BACKGROUNDGS, TEMP_IMAGES } from 'constants/images';

import { IAddProject } from 'models/addProject';

interface IProject {
  project: IAddProject;
}

const Project: React.FC<IProject> = ({ project }) => {
  const { progressBarValue, areAllPhasesSubmitted, handleProjectClick } =
    useProject({ project });
  const { name, clientName, id, submittedPhases, logoSrc } = project;

  const templateCountClassNames = classNames({
    template__count: true,
    'template__count--animated': true,
    green: areAllPhasesSubmitted,
    // red: areAllPhasesSubmitted,
  });

  return (
    <div className="template">
      <div className="wrapper text-center">
        <div className="project-btn">
          <img
            className="project-btn__img"
            src={logoSrc || TEMP_IMAGES.TEMPLATE}
            alt="sth"
          />
          <button
            className="project-btn__link"
            type="button"
            onClick={handleProjectClick}
          >
            <img src={BACKGROUNDGS.LANDING_PAGE} alt="sth" />
          </button>
          <ProjectProgressBar id={`bar-${id}`} dataValue={progressBarValue} />
        </div>
        <p className="template__project-name">{name}</p>
        <p className="template__project-category">{clientName}</p>
        <span className={templateCountClassNames}>
          {areAllPhasesSubmitted ? (
            <i className="fa fa-check nav-icon" />
          ) : (
            <span className="num">{submittedPhases}</span>
          )}
        </span>
      </div>
    </div>
  );
};

export default Project;
