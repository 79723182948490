import React from 'react';

import { ITemplateField, ITemplateFieldOption } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';

import { CREATE_TEMPLETE_ACTIONS } from 'context/modules/createTemplate/actions';
import useCreateTemplateContext from 'context/modules/createTemplate/useCreateTemplateContext';

import { ANSWER_LETTERS } from 'constants/common';

const LABELS = {
  [TemplateFieldTypes.TEXT_CHOISE]: 'Text\nChoice',
  [TemplateFieldTypes.IMAGE_CHOISE]: 'Image\nChoice',
  [TemplateFieldTypes.YES_NO_DECISION]: 'Yes/No\nDecision',
  [TemplateFieldTypes.TEXT_AREA]: 'Textarea',
};

interface IUseQuestion {
  isQuestionEdit: boolean;
  isTextChoiseQuestion: boolean;
  isImageChoiseQuestion: boolean;
  isYesNoDecision: boolean;
  isTextArea: boolean;
  label: string;
  questionText: string;
  questionOptions: ITemplateFieldOption[] | undefined;
  handleQuestionChanged: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleEditClicked: () => void;
  handleRemoveQuestion: () => void;
}

const useQuestion = (question: ITemplateField): IUseQuestion => {
  const { dispatch } = useCreateTemplateContext();
  const {
    isEditing: isQuestionEdit,
    title: questionText,
    id,
    type,
    options,
  } = question;
  const isTextChoiseQuestion = type === TemplateFieldTypes.TEXT_CHOISE;
  const isImageChoiseQuestion = type === TemplateFieldTypes.IMAGE_CHOISE;
  const isYesNoDecision = type === TemplateFieldTypes.YES_NO_DECISION;
  const isTextArea = type === TemplateFieldTypes.TEXT_AREA;

  const label = LABELS[type];

  const questionOptions: ITemplateFieldOption[] = [];
  options!.forEach((option: ITemplateFieldOption, index: number) => {
    if (!option.isRemoved) {
      questionOptions.push({
        ...option,
        letter: ANSWER_LETTERS[questionOptions.length],
        index: index!,
      });
    }
  });

  const handleEditClicked = (): void => {
    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_IS_EDITING,
      payload: { id, isEditing: !isQuestionEdit },
    });
  };

  const handleQuestionChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;
    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_TEXT,
      payload: { id, title: value },
    });
  };

  const handleRemoveQuestion = (): void => {
    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.REMOVE_QUESTION,
      payload: { id },
    });
  };

  return {
    isQuestionEdit,
    isTextChoiseQuestion,
    isImageChoiseQuestion,
    isYesNoDecision,
    isTextArea,
    label,
    questionText,
    questionOptions,
    handleEditClicked,
    handleQuestionChanged,
    handleRemoveQuestion,
  };
};

export default useQuestion;