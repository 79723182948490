export enum Collections
{
  USERS = 'users',
  PROJECTS = 'projects',
  TEMPLATES = 'templates',
  TEMP_USERS = 'tempUsers',
  MAIL = 'mail',
  FEEDBACK = 'feedback',
  PRODUCTS = 'products',
  APP_USAGE = 'appUsage'
}

export enum SubCollections
{
  PHASES = 'phases',
  PHASE_REVIEWERS = 'phaseReviewers',
  PHASE_COMMENTS = 'phaseComments',
  PROJECT_IDS = 'projectIds',
  CHECKOUT_SESSIONS = 'checkout_sessions'
}

export enum StorageFolders
{
  TEMPLATE_IMAGES = 'templateImages',
  PROJECT_IMAGE = 'projectImages'
}
