/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import useTemplate, {
  ITemplate,
} from 'components/AddProject/Templates/useTemplates';
import LoaderWrapper from 'components/Common/LoaderWrapper';
import ContentContainer from 'components/Common/ContentContainer';
import Template from 'components/AddProject/Templates/Template';
import Button from 'components/Common/Button';
import { useHistory } from 'react-router-dom';
import { CREATE_TEMPLATE, TEMPLATE } from 'constants/routes';
import PageContainer from 'components/Common/PageContainer';

const TemplateListComponent: React.FC = () => {
  const { templates } = useTemplate();

  const [filteredTemplates, setFilteredTemplates] = useState<
    ITemplate[] | undefined
  >( []);
  const [filterValue, setFilterValue] = useState<string>('');

  const history = useHistory();

  useEffect(() => {
    if (filterValue !== '' && templates) {
      const filtered = templates.filter((template) =>
        template.name.toLowerCase().includes(filterValue.toLowerCase()),
      );
      setFilteredTemplates(filtered);
    } else {
      setFilteredTemplates(templates);
    }
  }, [filterValue, templates]);

  return (
    <>
      <PageContainer headerTitle="Templates" className="templates-list">
        <ContentContainer>
          <div className="actions-container">
            <div className="filters-container">
              <div className="form-field-container">
                {/* <label>Search</label> */}
                <input
                  name="search"
                  placeholder="Search"
                  type="text"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </div>
     
            </div>
            <div className="actions">
              <Button onClick={() => history.push(CREATE_TEMPLATE)}>
                Create Template
              </Button>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          <LoaderWrapper isLoading={!templates}>
            <div className="grid-container">
              {filteredTemplates?.length ? 
              filteredTemplates.map((template) => {
 
                const { name, id } = template; 

                return (
                  <Template
                    title={name}
                    key={`${id}_${name}`}
                    onClick={() => history.push(`${TEMPLATE}/${id}`)}
                    id={id}
                    isActive={false}
                  />
                );
              }) :  (
                <h3>You do not have any templates.</h3>
              )}
            </div>
          </LoaderWrapper>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default TemplateListComponent;