import React from 'react';
import ReactDOM from 'react-dom';

import App from 'components/App';
import Toast from 'components/Common/Toast';

import 'css/style.comp.css';
import 'react-toastify/dist/ReactToastify.css';

ReactDOM.render(
  <React.StrictMode>
    <App />
    <Toast />
  </React.StrictMode>,
  document.getElementById('root'),
);
