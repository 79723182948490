export interface IUser
{
  firstName: string,
  lastName: string,
  displayName: string,
  isProfileComplete: boolean
  role: UserRoles,
  projectIds: string[]
  avatarURL: string
  uid: string
  id: string
  email: string
}

export enum UserRoles
{
  admin = 'ADMIN',
  projectOwner = 'PROJECT_OWNER',
  phaseReviewer = 'PHASE_REVIEWER',
}