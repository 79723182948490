import React from 'react';
import classNames from 'classnames';
import FeedbackForm from './FeedbackForm';

interface IModal {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<IModal> = ({ isOpen = false, setIsOpen }) => {
  
  const modalOverlayClassNames = classNames({
    'modal-overlay': true,
    active: isOpen,
  });

  return (
    <>
      <div className={modalOverlayClassNames}>
        <span
          className="close-modal"
          onClick={() => setIsOpen(false)}
          onKeyDown={() => setIsOpen(false)}
          role="button"
          tabIndex={-1}
        >
          &times;
        </span>
      </div>
      <div id="logoModal" className="modal">
        <div className="modal__slider no-focus-outline">
          <FeedbackForm setIsOpen={setIsOpen} />
        </div>
      </div>
    </>
  );
};

export default Modal;
