import React from 'react';
import classNames from 'classnames';

import PhaseReviewer from 'components/Common/PhaseReviewers/PhaseReviewer';

import { PhaseReviewsTypes } from 'models/phaseReview';
import { IPhaseReviewer } from 'models/addProject';
import useAuthorisation from 'hooks/useAuthorisation';
import { UserRoles } from 'models/roles';
import { IVote } from 'models/questions';
import Button from '../Button';

interface IPhaseReviewers {
  phaseReviewers?: IPhaseReviewer[];
  showAddReviewer: boolean;
  areAllPhasesSubmitted?: boolean;
  isDisabled?: boolean;
  reviewerEmail: string;
  handleRemovePhaseReviewer?: (index: number) => void;
  handleAddReviewerClick: () => void;
  handleAddReviewerKeyDown: () => void;
  handleReviewerEmailInput?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  type: PhaseReviewsTypes;
  votes?: IVote[];
}

const PhaseReviewers: React.FC<IPhaseReviewers> = ({
  phaseReviewers,
  showAddReviewer,
  reviewerEmail,
  handleRemovePhaseReviewer,
  handleAddReviewerClick,
  handleReviewerEmailInput,
  handleAddReviewerKeyDown,
  type,
  votes,
  areAllPhasesSubmitted,
  isDisabled
}) => {
  const { userRole } = useAuthorisation();
  const searchReviewerClassNames = classNames({
    'search-reviewer': true,
    visible: showAddReviewer,
  });

  return (
    <>
      <ul className="acc-list phase-reviewers-spacing">
        {phaseReviewers?.map((reviewer, index) => {
          const userVote = votes?.find(
            (vote) => vote.userEmail === reviewer.email,
          ) || { answer: '' };

          return (
            <PhaseReviewer
              key={reviewer.id}
              reviewer={{ ...reviewer, selectedAnswer: userVote?.answer }}
              index={index}
              onRemovePhaseReviewer={handleRemovePhaseReviewer!}
              type={type}
            />
          );
        })}
        {userRole !== UserRoles.phaseReviewer && 
        !areAllPhasesSubmitted && !isDisabled && (
          <div className="add-reviewer no-focus-outline">
            <button
              type="button"
              className="add-reviewer__btn"
              onClick={handleAddReviewerClick}
            >
              <i className="fas fa-plus add-reviewer__icon" />
            </button>
            <p className="add-reviewer__text">Add Reviewer</p>
          </div>
        )}
      </ul>

      <div className={searchReviewerClassNames}>
        <input
          type="email"
          id="search-reviewer"
          className="search-reviewer__email"
          placeholder="Type and email address..."
          value={reviewerEmail}
          onChange={handleReviewerEmailInput}
          // onKeyDown={handleAddReviewerKeyDown}
        />
        <Button styleType='icon' onClick={handleAddReviewerKeyDown}>+</Button>
      </div>
    </>
  );
};

export default PhaseReviewers;
