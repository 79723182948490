/* eslint-disable no-console */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import Question from 'components/Template/Questions/Question';
import useQuestions from 'components/Template/Questions/useQuestions';

import useCreateTemplateContext from 'context/modules/createTemplate/useCreateTemplateContext';

const Questions: React.FC = () => {
  const { questionsList } = useCreateTemplateContext();
  const { handleOnDragEnd, questions } = useQuestions({ questionsList });

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="characters">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {questions.map((question, index) => (
              <Draggable
                key={question.id}
                draggableId={question.id}
                index={index}
              >
                {(providedItem) => {
                  if (!question.isRemoved) {
                    return (
                      <div
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                      >
                        <Question
                          question={question}
                          index={question.displayIndex!}
                          dragHandleProps={providedItem.dragHandleProps}
                        />
                      </div>
                    );
                  }
                  return (
                    <div
                      ref={providedItem.innerRef}
                      {...providedItem.draggableProps}
                      {...providedItem.dragHandleProps}
                    />
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Questions;