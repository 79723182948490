import {
  TCreateTemplateActions,
  CREATE_TEMPLETE_ACTIONS,
  ISetQuestionIsEditingPayload,
  ISetQuestionTextPayload,
  IRemoveQuestionPayload,
  IRemoveQuestionOptionPayload,
  ISetQuestionOptionTextPayload,
  IAddQuestionPayload,
  ISetQuestionsOrderPayload,
  ISetQuestionOptionImagePayload,
  ISetQuestionInitialPayload,
} from 'context/modules/createTemplate/actions';

import { INITIAL_OPTIONS_YES_NO, ITemplateField } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';

import { sortByOrder } from 'helpers/order';

interface IState {
  questionsList: ITemplateField[];
}

const getUpdateQuestionList = (
  questionsList: ITemplateField[],
  id: string,
  propertyName: string,
  propertyValue: string | boolean,
): ITemplateField[] =>
  questionsList.map((question) => {
    if (question.id === id) {
      const updatedQuestion = { ...question };
      updatedQuestion[propertyName] = propertyValue;
      updatedQuestion.isEdited = true;
      return updatedQuestion;
    }
    return question;
  });

export default (state: IState, action: TCreateTemplateActions): IState => {
  switch (action.type) {
    case CREATE_TEMPLETE_ACTIONS.ADD_QUESTION: {
      const { type } = action.payload as IAddQuestionPayload;
      const { questionsList } = state;
      const isYesNoDecision = type === TemplateFieldTypes.YES_NO_DECISION;
      const initialOptions = isYesNoDecision ? INITIAL_OPTIONS_YES_NO : [];
      const newTextQuestion: ITemplateField = {
        title: '',
        isEditing: true,
        isAdded: true,
        type,
        options: initialOptions,
        id: questionsList.length.toString(),
        order: questionsList.length - 1,
      };
      const updatedQuestionList = [...questionsList, newTextQuestion];

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.SET_QUESTION_IS_EDITING: {
      const { id, isEditing } = action.payload as ISetQuestionIsEditingPayload;
      const { questionsList } = state;
      const updatedQuestionList = getUpdateQuestionList(
        questionsList,
        id,
        'isEditing',
        isEditing,
      );

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.SET_QUESTION_TEXT: {
      const { id, title } = action.payload as ISetQuestionTextPayload;
      const { questionsList } = state;
      const updatedQuestionList = getUpdateQuestionList(
        questionsList,
        id,
        'title',
        title,
      );

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.REMOVE_QUESTION: {
      const { id } = action.payload as IRemoveQuestionPayload;
      const { questionsList } = state;
      let updatedQuestionList = [...questionsList];
      const removedItemIndex = updatedQuestionList.findIndex(
        (question) => question.id === id,
      );
      const removedItem = updatedQuestionList[removedItemIndex];
      if (removedItem.isAdded) {
        updatedQuestionList = questionsList.filter(
          (question) => question.id !== id,
        );
      } else {
        removedItem.isRemoved = true;
      }

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.REMOVE_QUESTION_OPTION: {
      const { id, optionIndex } =
        action.payload as IRemoveQuestionOptionPayload;
      const { questionsList } = state;
      const updatedQuestionList = questionsList.map((question) => {
        if (question.id === id) {
          const updatedQuestion = { ...question };
          let updatedOptions = [...updatedQuestion.options!];
          if (question.type === TemplateFieldTypes.TEXT_CHOISE) {
            updatedOptions = updatedQuestion.options!.filter(
              (option, index) => index !== optionIndex,
            );
          }
          if (question.type === TemplateFieldTypes.IMAGE_CHOISE) {
            const removedOptionItem = updatedQuestion.options![optionIndex];
            removedOptionItem.isRemoved = true;
          }

          updatedQuestion.options = updatedOptions;
          return updatedQuestion;
        }
        return question;
      });

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.ADD_QUESTION_OPTION: {
      const { id } = action.payload as IRemoveQuestionPayload;
      const { questionsList } = state;
      const updatedQuestionList = questionsList.map((question) => {
        const isTextChoise = question.type === TemplateFieldTypes.TEXT_CHOISE;
        const isImageChoise = question.type === TemplateFieldTypes.IMAGE_CHOISE;
        if (question.id === id) {
          const updatedQuestion = { ...question };
          const updatedOptions = [
            ...updatedQuestion.options!,
            {
              ...(isTextChoise ? { text: '' } : {}),
              ...(isImageChoise ? { src: undefined } : {}),
            },
          ];
          updatedQuestion.options = updatedOptions!;
          return updatedQuestion;
        }
        return question;
      });

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.SET_QUESTION_OPTION_TEXT: {
      const { id, optionIndex, title } =
        action.payload as ISetQuestionOptionTextPayload;
      const { questionsList } = state;
      const updatedQuestionList = questionsList.map((question) => {
        if (question.id === id) {
          const updatedQuestion = { ...question };
          const updatedOptions = updatedQuestion.options!.map(
            (option, index) => {
              if (index === optionIndex) {
                return {
                  ...option,
                  text: title,
                };
              }
              return option;
            },
          );
          updatedQuestion.options = updatedOptions;
          return updatedQuestion;
        }
        return question;
      });

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.SET_QUESTION_OPTION_IMAGE: {
      const { id, optionIndex, imageSrc, file } =
        action.payload as ISetQuestionOptionImagePayload;

      const { questionsList } = state;
      const updatedQuestionList = questionsList.map((question) => {
        if (question.id === id) {
          const updatedQuestion = { ...question };
          const updatedOptions = updatedQuestion.options!.map(
            (option, index) => {
              if (index === optionIndex) {
                return {
                  ...option,
                  file,
                  imageSrc: imageSrc.toString(),
                  isAdded: true,
                };
              }
              return option;
            },
          );
          updatedQuestion.options = updatedOptions;
          return updatedQuestion;
        }
        return question;
      });

      return {
        ...state,
        questionsList: updatedQuestionList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.SET_QUESTIONS_ORDER: {
      const { source, destination } =
        action.payload as ISetQuestionsOrderPayload;

      const [reorderedItem] = state.questionsList.splice(source.index, 1);
      state.questionsList.splice(destination.index, 0, reorderedItem);
      const udpatedQuestionsList = state.questionsList.map((question) => ({
        ...question,
        isEdited: true,
      }));

      return {
        ...state,
        questionsList: udpatedQuestionsList,
      };
    }

    case CREATE_TEMPLETE_ACTIONS.SET_QUESTION_INITIAL: {
      const { phases } = action.payload as ISetQuestionInitialPayload;

      const udpatedQuestionsList = phases.map((phase) => {
        const { title, options, type, id, order } = phase;

        return {
          title,
          options,
          type,
          isEditing: false,
          text: title,
          id,
          order,
        };
      });

      return {
        ...state,
        questionsList: sortByOrder(udpatedQuestionsList),
      };
    }

    default: {
      return state;
    }
  }
};