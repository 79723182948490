import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import ForgotPassword from 'pages/ForgotPassword';

import { LOGIN, SIGN_UP, FORGOT_PASSWORD } from 'constants/routes';

const PublicRoutes: React.FC = () => (
  <Switch>
    <Route path={LOGIN} component={Login} />
    <Route path={SIGN_UP} component={SignUp} exact />
    <Route path={FORGOT_PASSWORD} component={ForgotPassword} exact />
    <Redirect to={LOGIN} />
  </Switch>
);

export default PublicRoutes;
