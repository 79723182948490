import { Errors } from 'constants/notifications';
import { errorNotification } from 'helpers/notifications';
import { IAddProject } from 'models/addProject';
import { ITemplateField } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';
import { IUpdateProfile } from 'models/updateProfile';
import useAuthorisation from './useAuthorisation';

interface IUseValidation
{
  isTemplateValidationPassed: (
    templateName: string,
    questionsList: ITemplateField[],
  ) => boolean;
  isAddProjectValidationPassed:
  (form: IAddProject, sectionId?: string) => boolean;
  isUpdateProfileValidationPassed: (form: IUpdateProfile) => boolean;
  isCommentValidationPassed: (value: string) => boolean;
  isReviewerValidationPassed: (value: string) => boolean;
  isPasswordValid: (password: string) => string;
  isConfirmPasswordValid: (password: string, confirmPassword: string) => string;
}

export enum ValidationPatterns
{
  nonEmptiness = 'nonEmptiness',
  nonEmptinessArray = 'nonEmptinessArray',
  nonNull = 'nonNull',
  emailAddress = 'emailAddress',
  password = 'password',
  confirmPassword = 'confirmPassword',
}

export interface IRemovableOption
{
  isRemoved: boolean;
  title: string;
  [key: string]: string | boolean;
}

export interface IValues
{
  value: string | IRemovableOption[] | undefined | File;
  compareValue?: string
  propertyName?: string;
  fieldName: string;
  pattern: ValidationPatterns;
}

const EMAIL_REGEX =
  // eslint-disable-next-line max-len
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const useValidation = (): IUseValidation =>
{
  const { hasPasswordLoginMethod } = useAuthorisation();
  const checkIsEmpty = (fieldName: string, value: string): boolean =>
  {
    let isEmpty = false;
    if (value.length === 0)
    {
      errorNotification(`${fieldName} ${Errors.FIELD_IS_EMPTY}`);
      isEmpty = true;
    }
    return isEmpty;
  };

  const areValuesValid = (valuesForValidation: IValues[]): boolean =>
  {
    let isValid = true;
    valuesForValidation.forEach((valueForValidation) =>
    {
      const { value, fieldName, pattern, propertyName, compareValue = '' }
        = valueForValidation;
      switch (pattern)
      {
        case ValidationPatterns.nonEmptiness: {
          if (typeof value === 'string')
          {
            const validationNotPassed = checkIsEmpty(
              fieldName,
              value as string,
            );
            if (validationNotPassed)
            {
              isValid = false;
            }
          }
          if (Array.isArray(value) && propertyName)
          {
            const emptyValue = value.find((valueItem) =>
              valueItem.isRemoved
                ? false
                : checkIsEmpty(fieldName, valueItem[propertyName] as string),
            );
            if (emptyValue)
            {
              isValid = false;
            }
          }
          break;
        }
        case ValidationPatterns.nonEmptinessArray: {
          const values = value as IRemovableOption[];
          if (values.length)
          {
            const hasNonRemovedValues = values.find((item) => !item.isRemoved);
            if (!hasNonRemovedValues)
            {
              errorNotification(`${fieldName} ${Errors.IS_EMPTY}`);
              isValid = false;
            }
          } else
          {
            errorNotification(`${fieldName} ${Errors.IS_EMPTY}`);
            isValid = false;
          }
          break;
        }
        case ValidationPatterns.nonNull: {
          if (!value)
          {
            isValid = false;
            errorNotification(`${fieldName} ${Errors.IMAGE_OPTION_IS_EMPTY}`);
          }
          break;
        }
        case ValidationPatterns.emailAddress: {
          const isEmailValid = EMAIL_REGEX.test(String(value).toLowerCase());
          if (!isEmailValid)
          {
            isValid = false;
            errorNotification(`${fieldName} ${Errors.EMAIL_IS_NOT_VALID}`);
          }
          break;
        }
        case ValidationPatterns.password: {
          const passwordValue = String(value)
          if (passwordValue.length < 6)
          {
            isValid = false;
          }
          break;
        }
        case ValidationPatterns.confirmPassword: {
          const passwordValue = String(value)
          const passwordConfirmValue = String(compareValue)
          if (passwordValue !== passwordConfirmValue)
          {
            isValid = false;
          }
          break;
        }

        default: {
          isValid = true;
        }
      }
    });
    return isValid;
  };

  const isTemplateValidationPassed = (
    templateName: string,
    questionsList: ITemplateField[],
  ): boolean =>
  {
    const generalValidationPassed = areValuesValid([
      {
        value: templateName,
        pattern: ValidationPatterns.nonEmptiness,
        fieldName: 'Template name',
      },
      {
        value: questionsList as IRemovableOption[],
        pattern: ValidationPatterns.nonEmptinessArray,
        fieldName: 'Questions list',
      },
      {
        value: questionsList as IRemovableOption[],
        pattern: ValidationPatterns.nonEmptiness,
        propertyName: 'title',
        fieldName: 'Question title',
      },
    ]);
    const emptyOptions: IValues[] = [];
    const optionsValues = questionsList.map((question) =>
    {
      const { type, title, options } = question;

      if (
        type !== TemplateFieldTypes.TEXT_AREA &&
        type !== TemplateFieldTypes.YES_NO_DECISION
      )
      {
        emptyOptions.push({
          value: options as IRemovableOption[],
          pattern: ValidationPatterns.nonEmptinessArray,
          fieldName: 'Question options list',
        });
      }

      if (!question.isRemoved)
      {
        if (type === TemplateFieldTypes.TEXT_CHOISE)
        {
          return options?.map((option) => ({
            value: option.text!,
            pattern: ValidationPatterns.nonEmptiness,
            fieldName: `${title} option`,
          }));
        }
        if (type === TemplateFieldTypes.IMAGE_CHOISE)
        {
          return options?.map((option) => ({
            value: option.file || option.imageSrc,
            pattern: ValidationPatterns.nonNull,
            fieldName: `${title} image option`,
          }));
        }
      }
      return {};
    });

    const optionsValidationPassed = areValuesValid(
      optionsValues.flat() as IValues[],
    );
    const emptyOptionsValidationPassed = areValuesValid(emptyOptions);
    return (
      optionsValidationPassed &&
      generalValidationPassed &&
      emptyOptionsValidationPassed
    );
  };

  const isAddProjectValidationPassed =
    (form: IAddProject, sectionId?: string): boolean =>
    {
      const { id, clientName, name, phases } = form;

      if (sectionId === 'projectInformation')
      {
        return areValuesValid([
          {
            value: name,
            pattern: ValidationPatterns.nonEmptiness,
            fieldName: 'Project name',
          },
          {
            value: clientName,
            pattern: ValidationPatterns.nonEmptiness,
            fieldName: 'Client name',
          },
        ]);

      } if (sectionId === 'projectPhases')
      {
        return areValuesValid([
          {
            value: id,
            pattern: ValidationPatterns.nonEmptiness,
            fieldName: 'Project Information',
          },
          {
            value: phases as unknown as IRemovableOption[],
            pattern: ValidationPatterns.nonEmptinessArray,
            fieldName: 'Phase field',
          },
        ]);
      }
      return false

    };

  const isUpdateProfileValidationPassed = (form: IUpdateProfile): boolean =>
  {
    const { firstName, lastName } = form;
    const isFormValid = 
    hasPasswordLoginMethod ? areValuesValid([
      {
        value: firstName,
        pattern: ValidationPatterns.nonEmptiness,
        fieldName: 'First name',
      },
      {
        value: lastName,
        pattern: ValidationPatterns.nonEmptiness,
        fieldName: 'Last name',
      },
      // {
      //   value: password,
      //   pattern: ValidationPatterns.nonEmptiness,
      //   fieldName: 'Password',
      // },

    ])
     : areValuesValid([
      {
        value: firstName,
        pattern: ValidationPatterns.nonEmptiness,
        fieldName: 'First name',
      },
      {
        value: lastName,
        pattern: ValidationPatterns.nonEmptiness,
        fieldName: 'Last name',
      },
    ]);
    return isFormValid;
  };

  const isPasswordValid = (password: string): string =>
  {
    let error = ''
    const isValid = areValuesValid([
      {
        value: password,
        pattern: ValidationPatterns.password,
        fieldName: 'Password',
      },
    ]);

    if (!isValid)
    {
      error = 'Password must be at least 6 characters'
    } else
    {
      error = ''
    }
    return error
  }

  const isConfirmPasswordValid = (
    password: string, confirmPassword: string
  ): string =>
  {
    let error = ''
    const isValid = areValuesValid([
      {
        value: password,
        compareValue: confirmPassword,
        pattern: ValidationPatterns.confirmPassword,
        fieldName: 'Password',
      },
    ]);

    if (!isValid)
    {
      error = 'The passwords entered do not match'
    } else
    {
      error = ''
    }
    return error
  }

  const isCommentValidationPassed = (value: string): boolean =>
    areValuesValid([
      {
        value,
        pattern: ValidationPatterns.nonEmptiness,
        fieldName: 'Comment text',
      },
    ]);

  const isReviewerValidationPassed = (value: string): boolean =>
    areValuesValid([
      {
        value,
        pattern: ValidationPatterns.emailAddress,
        fieldName: 'Reviewer email',
      },
    ]);

  return {
    isTemplateValidationPassed,
    isAddProjectValidationPassed,
    isCommentValidationPassed,
    isReviewerValidationPassed,
    isUpdateProfileValidationPassed,
    isPasswordValid,
    isConfirmPasswordValid
  };
};

export default useValidation;
