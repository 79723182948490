import React from 'react';

import PageContainer from 'components/Common/PageContainer';
import useTemplates from 'components/AddProject/Templates/useTemplates';
import useAddProject from './useAddProject';
import ProjectForm from './ProjectForm';

const CreateProjectComponent: React.FC = () => {
  const {
    form,
    handleFormChange,
    isLoading,
    handleAddProjectInformationClick,
    handleAddProjectPhasesClick,
    handleAddPhaseReviewersClick,
    handleAddProjectLogoClick,
    setProjectPhases,
    // handlePhaseItemRemove,
    handleAddReviewerClick,
    handleAddReviewerKeyDown,
    handleReviewerEmailInput,
    handleRemovePhaseReviewer,
    reviewerEmail,
    showAddReviewer,
    handleLogoSelect,
    addProjectQuestion,
    setFormQuestions,
    handlePublishProject,
    votingHasStarted
  } = useAddProject();

  const {
    templates,
  } = useTemplates();
  return (
    <>
      <PageContainer className="projects-page">
        <ProjectForm
          form={form}
          onChange={handleFormChange}
          isLoading={isLoading}
          handleAddProjectInformationClick={handleAddProjectInformationClick}
          handleAddProjectPhasesClick={handleAddProjectPhasesClick}
          handleAddPhaseReviewersClick={handleAddPhaseReviewersClick}
          handleAddProjectLogoClick={handleAddProjectLogoClick}
          templates={templates}
          setProjectPhases={setProjectPhases}
          // onPhaseRemove={handlePhaseItemRemove}
          handleAddReviewerClick={handleAddReviewerClick}
          handleAddReviewerKeyDown={handleAddReviewerKeyDown}
          handleReviewerEmailInput={handleReviewerEmailInput}
          handleRemovePhaseReviewer={handleRemovePhaseReviewer}
          reviewerEmail={reviewerEmail}
          showAddReviewer={showAddReviewer}
          handleLogoSelect={handleLogoSelect}
          addProjectQuestion={addProjectQuestion}
          setFormQuestions={setFormQuestions}
          votingHasStarted={votingHasStarted}
          handlePublishProject={handlePublishProject}
        />
      </PageContainer>
    </>
  );
};

export default CreateProjectComponent;
