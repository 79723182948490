import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import useTemplate from 'components/Template/useTemplate';
import Questions from 'components/Template/Questions';
import LoaderWrapper from 'components/Common/LoaderWrapper';
import { TemplateFieldTypes } from 'models/template';
import ContentContainer from 'components/Common/ContentContainer';
import Button from 'components/Common/Button';

const TemplateForm: React.FC = () => {
  const {
    showAddQuestionButton,
    templateName,
    mainButtonText,
    labelText,
    isLoading,
    toggleAddQuestion,
    handleAddQuestion,
    handleEditTemplateClick,
    handleTemplateNameChange,
    createTemplateLoading
  } = useTemplate();

  const addQuestionContainerClassNames = classNames({
    'add-question-container': true,
    hidden: !showAddQuestionButton,
  });

  const chooseQuestionClassNames = classNames({
    'not-visible': showAddQuestionButton,
    'choose-question': true,
  });

  const textreaReference = useRef<any>(null);

  useEffect(() => {
    if (textreaReference?.current) {
      textreaReference.current.focus();
    }
  }, []);


  return (
    <ContentContainer>
      <header className="page-header">
        <div className="page-header__info">
          <label
            className="page-header__template-name-label"
            htmlFor="template-name"
          >
            {labelText}
          </label>
          <textarea
            id="template-name"
            ref={textreaReference}
            className="page-header__template-name"
            name="template name"
            placeholder="Template Name..."
            onChange={handleTemplateNameChange}
            value={templateName}
            rows={1}
          />
        </div>
      </header>
      <main className="template-options-container">
        
        <LoaderWrapper isLoading={isLoading }>
          <Questions />
        </LoaderWrapper>
        <div
          id="template-options"
          className="template-options full-width-container"
        />
        <div className="template-options__add-question no-focus-outline">
          <div className={addQuestionContainerClassNames}>
          <Button
              type="button"
              onClick={toggleAddQuestion}
              styleType= 'secondary'
            >
              <i className="fas fa-plus" />
              Add Phase Question
            </Button>
          </div>
          <div className={chooseQuestionClassNames}>
            <p>Pick question type:</p>
            <div className="choose-question__options no-focus-outline">
              <Button
                id={TemplateFieldTypes.TEXT_CHOISE}
                styleType= 'secondary'
                onClick={handleAddQuestion}
              >
                Text
              </Button>
              <Button
                id={TemplateFieldTypes.IMAGE_CHOISE}
                styleType= 'secondary'
                onClick={handleAddQuestion}
              >
                Image
              </Button>
              <Button
                id={TemplateFieldTypes.YES_NO_DECISION}
                styleType= 'secondary'
                onClick={handleAddQuestion}
              >
                Yes/No
              </Button>
              <Button
                id={TemplateFieldTypes.TEXT_AREA}
                styleType= 'secondary'
                onClick={handleAddQuestion}
              >
                TextArea
              </Button>
            </div>
          </div>
        </div>
      </main>
      <div className="template-footer no-focus-outline">  
        <button
          className="template-footer__toggle-visibility-btn private"
          type="button"
        >
          <span />
        </button>
        <Button onClick={handleEditTemplateClick} 
        isLoading={createTemplateLoading}>
          {`${mainButtonText} Template`}
        </Button>
      </div>
    </ContentContainer>
  );
};

export default TemplateForm;