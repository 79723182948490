import { PROJECTS } from 'constants/routes';

import { IAddProject } from 'models/addProject';
import { useHistory } from 'react-router-dom';

interface IUseProjectProps
{
  project: IAddProject;
}

interface IUseProject
{
  progressBarValue: number;
  project: IAddProject;
  areAllPhasesSubmitted: boolean;
  handleProjectClick: () => void;
}

const useProject = ({ project }: IUseProjectProps): IUseProject =>
{
  const history = useHistory();

  const { phasesOrder, id, activePhaseId, submittedPhases } = project;
  const length = phasesOrder?.length || 0
  const progressBarValue = (submittedPhases! / length) * 10;
  const phaseId = activePhaseId || phasesOrder![0];
  const link = `${PROJECTS}/${id}/phase/${phaseId}`;
  const areAllPhasesSubmitted = !activePhaseId;

  const handleProjectClick = (): void =>
  {
    history.push(link);
  };

  return {
    progressBarValue,
    project,
    areAllPhasesSubmitted,
    handleProjectClick,
  };
};

export default useProject;
