import { ITemplateField, ITemplateFieldOption } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';
import { ANSWER_LETTERS } from 'constants/common';

const LABELS = {
  [TemplateFieldTypes.TEXT_CHOISE]: 'Text\nChoice',
  [TemplateFieldTypes.IMAGE_CHOISE]: 'Image\nChoice',
  [TemplateFieldTypes.YES_NO_DECISION]: 'Yes/No\nDecision',
  [TemplateFieldTypes.TEXT_AREA]: 'Textarea',
};

interface IUseQuestion {
  isQuestionEdit: boolean;
  isTextChoiseQuestion: boolean;
  isImageChoiseQuestion: boolean;
  isYesNoDecision: boolean;
  isTextArea: boolean;
  label: string;
  questionText: string;
  questionOptions: ITemplateFieldOption[] | undefined;
}

const useQuestion = (question: ITemplateField): IUseQuestion => {
  const {
    isEditing: isQuestionEdit,
    title: questionText,
    type,
    options,
  } = question;
  const isTextChoiseQuestion = type === TemplateFieldTypes.TEXT_CHOISE;
  const isImageChoiseQuestion = type === TemplateFieldTypes.IMAGE_CHOISE;
  const isYesNoDecision = type === TemplateFieldTypes.YES_NO_DECISION;
  const isTextArea = type === TemplateFieldTypes.TEXT_AREA;

  const label = LABELS[type];

  const questionOptions: ITemplateFieldOption[] = [];
  options!.forEach((option: ITemplateFieldOption, index: number) => {
    if (!option.isRemoved) {
      questionOptions.push({
        ...option,
        letter: ANSWER_LETTERS[questionOptions.length],
        index: index!,
      });
    }
  });




  return {
    isQuestionEdit,
    isTextChoiseQuestion,
    isImageChoiseQuestion,
    isYesNoDecision,
    isTextArea,
    label,
    questionText,
    questionOptions,

  };
};

export default useQuestion;
