import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import Dashboard from 'pages/Dashboard';
import Profile from 'pages/Profile';
import Reviewers from 'pages/Reviewers';
import CreateProject from 'pages/CreateProject';
import ProjectsList from 'pages/ProjectsList';
import TemplateList from 'pages/TemplatesList';
import CreateTemplate from 'pages/CreateTemplate';
import ProjectPhase from 'pages/ProjectPhase';
import Onboarding from 'pages/Onboarding';

import {
  PROJECTS,
  CREATE_PROJECT,
  CREATE_TEMPLATE,
  DASHBOARD,
  TEMPLATES,
  PROFILE,
  ONBOARDING,
  TEMPLATE,
  EDIT_PROJECT,
  REVIEWERS
} from 'constants/routes';
import useProfile from 'components/Profile/useProfile';
import useAuthorisation from 'hooks/useAuthorisation';

const PrivateRoutes: React.FC = () => {
  const [onBoardingIsComplete, setOnBoardingIsComplete] = useState(false);
  const { profile } = useProfile();
  const {userRole}  =useAuthorisation()

  useEffect(() => {
    if (profile?.email && profile?.firstName && 
      profile?.role === userRole && profile?.isProfileComplete) {
      setOnBoardingIsComplete(true);
    } 
  }, [profile, userRole]);

  return (
    <Switch>
      <>
        <Route path={DASHBOARD} component={Dashboard} />
        <Route path={ONBOARDING} component={Onboarding} />
        <Route path={PROFILE} component={Profile} />
        <Route path={REVIEWERS} component={Reviewers} />

        <Route path={PROJECTS} exact component={ProjectsList} />
        <Route path={`${EDIT_PROJECT}/:id?`} exact component={CreateProject} />
        <Route
          path={`${PROJECTS}/:projectId/phase/:phaseId?`}
          exact
          component={ProjectPhase}
        />
        <Route path={CREATE_PROJECT} exact component={CreateProject} />

        <Route path={TEMPLATES} exact component={TemplateList} />
        <Route path={CREATE_TEMPLATE} exact component={CreateTemplate} />
        <Route
          path={`${TEMPLATE}/:templateId?`}
          exact
          component={CreateTemplate}
        />

        <Redirect to={onBoardingIsComplete ? DASHBOARD : ONBOARDING} />
      </>
    </Switch>
  );
};
export default PrivateRoutes;
