import React from 'react';

import PublicRoutes from 'components/Routes/PublicRoutes';
import PrivateRoutes from 'components/Routes/PrivateRoutes';

import useAuthorisation from 'hooks/useAuthorisation';

const Routes: React.FC = () => {
  const { isUserLoggedIn } = useAuthorisation();

  const routes = isUserLoggedIn 
  ? <PrivateRoutes /> : <PublicRoutes />;
  return <>{routes}</>;
};

export default Routes;
