import React from 'react';

import { ICONS, LOGO } from 'constants/images';

interface ISkeletonHeader {
  isMinimized: boolean;
  setMenuIsMinimized: React.Dispatch<React.SetStateAction<boolean>>;
}

const SkeletonHeader: React.FC<ISkeletonHeader> = ({
  isMinimized = false,
  setMenuIsMinimized,
}: ISkeletonHeader) => (
  <nav
    className="main-navbar"
    onMouseEnter={() => setMenuIsMinimized((prev) => !prev)}
    onMouseLeave={() => setMenuIsMinimized((prev) => !prev)}
  >
    <a href="/" className="main-navbar__logo-link">
      {isMinimized ? (
        <img
          src={LOGO.SMALL}
          className="navbar-logo-small"
          alt="Jetrospects logo"
        />
      ) : (
        <img src={LOGO.HEADER} className="navbar-logo" alt="Jetrospects logo" />
      )}
    </a>

    <h1 className="main-navbar__page-title">Loading...</h1>

    <div className="user-info">
      <div className="user-info__avatar-container">
        <img
          className="user-info__picture"
          src={ICONS.USER_PLACEHOLDER}
          alt="user"
        />
      </div>
      <div className="user-info__container">
        <p className="user-info__name"> </p>
      </div>
    </div>
  </nav>
);
export default SkeletonHeader;
