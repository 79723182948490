import React, {
  useEffect,
  useState
} from 'react';

import { mutations } from 'api';

const INITIAL = {
  firstName: '',
  lastName: '',
  email: '',
  feedback: ''
};

interface IFeedbackForm
{
  firstName: string;
  lastName: string;
  email: string;
  feedback: string;
}
interface IUseFeedback
{
  handleInputChange: (e: React.FormEvent<HTMLInputElement
    | HTMLTextAreaElement>) => void;
  handleSubmitClicked: () => Promise<void>;
  form: IFeedbackForm;
  setForm: React.Dispatch<React.SetStateAction<IFeedbackForm>>;
  isFormValid: boolean;
  modalIsOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  isLoading: boolean;
}

const useFeedback = (): IUseFeedback =>
{
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [form, setForm] = useState<IFeedbackForm>(INITIAL);
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  useEffect(() =>
  {
    if (form)
    {
      const isRequiredComplete = !!form.firstName && !!form.lastName

      setIsFormValid(isRequiredComplete)
    }

  }, [form])

  const handleInputChange = (e: React.FormEvent<HTMLInputElement
    | HTMLTextAreaElement>): void =>
  {
    const { value, name } = e.currentTarget;

    setForm({ ...form, [name]: value });
  };


  const handleSubmitClicked = async (): Promise<void> =>
  {
    setIsLoading(true)

    const feedbackDetails = {
      user: {
        firstName: form.firstName,
        lastName: form.lastName,
        email: form.email
      },
      feedback: form.feedback,
      createdAt: new Date()
    }

    await mutations.submitFeedback(feedbackDetails);
    setForm(INITIAL)
    setIsLoading(false)
  };


  return {
    handleInputChange,
    handleSubmitClicked,
    setForm,
    form,
    isFormValid,
    modalIsOpen,
    setModalIsOpen,
    isLoading
  };
};

export default useFeedback;


