import React from 'react';
import classNames from 'classnames';
import usePhaseComments from 'components/ProjectPhase/Sidebar/PhaseComments/usePhaseComments';
import { IPhaseComment } from 'models/phaseReview';
import { formatAMPM } from 'helpers/time';
// import { ICONS } from 'constants/images';

interface IPhaseComments {
  commentAnswer?: string;
  phaseComments: IPhaseComment[];
  isTextArea: boolean;
}

const PhaseComments: React.FC<IPhaseComments> = ({
  commentAnswer,
  phaseComments,
  isTextArea,
}) => {
  const {
    comments,
    showAddComment,
    newComment,
    handleAddCommentClick,
    handleAddCommentKeyDown,
    handleCommentInput,
  } = usePhaseComments({
    initialComments: phaseComments,
    commentAnswer,
    isTextArea,
  });

  const typeCommentСlassName = classNames({
    'type-comment': true,
    'no-focus-outline': true,
    visible: showAddComment,
  });

  return (
    <>
          {showAddComment && (
        <div className={typeCommentСlassName}>
          <div
            className="type-comment__opt"
            onClick={handleAddCommentClick}
            onKeyDown={handleAddCommentClick}
            role="button"
            tabIndex={-1}
          >
            <span className="type-comment__vote no-vote">{commentAnswer}</span>
          </div>
          <input
            type="text"
            id="comment-input"
            className="type-comment__input"
            value={newComment}
            onChange={handleCommentInput}
            onKeyDown={handleAddCommentKeyDown}
          />
        </div>
      )}
         <div className="comments-container">
      <div className="acc-list">
        {comments.sort((a, b) => (a.time < b.time) ? 1 : -1).map((comment) => {
          const { email, id, 
            selectedAnswer,
             message, time, 
             commentedAnswer 
            } =
            comment;

          const isSelectedAnswer = Boolean(selectedAnswer);
          const isCommentedAnswer = Boolean(commentedAnswer);

          const commentOptСlassName = classNames({
            'acc-list__comment-opt': true,
            'opt-a': isSelectedAnswer,
            'opt-b': isCommentedAnswer,
          });

          return (
         
            <div key={id} className="comment-outer">
              <div className="acc-list__comment">
                
                {/* <img
                  src={ICONS.USER_PLACEHOLDER}
                  className="acc-list__img"
                  alt="phase reviewer"
                /> */}
       
                <div className="acc-list__info">
                  <p className="acc-list__name">
                  <span className='selected-container'>
                   {(isSelectedAnswer || isCommentedAnswer) && (
                    <>
                  <span className={commentOptСlassName}>
                    {selectedAnswer || commentedAnswer}
                  </span>
                  <span>{email}</span>
                  </>
                )}
                 </span>
                    <span className="hour">
                      {formatAMPM(`${time.seconds}`)}
                    </span>
                  
                  </p>
                  <p className="acc-list__msg">{message}</p>
                </div>
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </>
  );
};

export default PhaseComments;
