import React, { useState } from 'react';

import useAuthorisation from 'hooks/useAuthorisation';

const INITIAL = {
  email: '',
};

interface IForgotPasswordForm
{
  email: string;
}

interface IUseForgotPassword
{
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSubmitClicked: () => Promise<void>;
  requestError: string;
  form: IForgotPasswordForm;
}

const useForgotPassword = (): IUseForgotPassword =>
{
  const [form, setForm] = useState<IForgotPasswordForm>(INITIAL);
  const [requestError, setRequestError] = useState<string>('');
  const {
    sendForgotPasswordEmail,
  } = useAuthorisation();

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void =>
  {
    const { value, name } = e.currentTarget;

    setForm({ ...form, [name]: value });
  };

  const handleSubmitClicked = async (): Promise<void> =>
  {
    const { email } = form;
    try
    {
      await sendForgotPasswordEmail(email);
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  return {
    handleInputChange,
    handleSubmitClicked,
    requestError,
    form
  };
};

export default useForgotPassword;
