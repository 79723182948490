import React, { useEffect, useState } from 'react';
import classNames from 'classnames';

import { IPhaseReviewer as IReviewer } from 'models/addProject';
import { PhaseReviewsTypes } from 'models/phaseReview';
import { getAvatar } from 'api/queries';
import { ICONS } from 'constants/images';

interface IPhaseReviewer {
  reviewer: IReviewer;
  index: number;
  type: PhaseReviewsTypes;
  onRemovePhaseReviewer: (index: number) => void;
}

const PhaseReviewer: React.FC<IPhaseReviewer> = ({
  reviewer,
  index,
  type,
  onRemovePhaseReviewer,
}) => {
  const { email, selectedAnswer } = reviewer;
  const isProjectReview = type === PhaseReviewsTypes.PROJECT_REVIEW;
  const hasAnswer = Boolean(selectedAnswer);
  const [avatarUrl, setAvatarUrl] = useState<string>(ICONS.USER_PLACEHOLDER);

  const handleDeleteClicked = (): void => {
    onRemovePhaseReviewer(index);
  };

  const accListmgClassNames = classNames({
    'acc-list__img': true,
    // 'user-online': isOnline,
  });

  const accListVoteClassNames = classNames({
    'acc-list__vote': true,
    'has-vote': hasAnswer,
    'no-vote': !hasAnswer,
  });

  useEffect(() => {
    getAvatar(email).then((url) => {
      setAvatarUrl(url);
    });
  }, [email]);

  return (
    <li key={email}>
      <div className="acc-list__reviewer no-focus-outline">
        <img
          src={avatarUrl}
          className={accListmgClassNames}
          alt="phase reviewer"
        />
        <p className="acc-list__email">{email}</p>
        {isProjectReview && (
          <div className="acc-list__voteAnswer">
            Voted{' '}
            <span className={accListVoteClassNames} data-attr="A">
              {selectedAnswer || '?'}
            </span>
          </div>
        )}

        {!isProjectReview && (
          <button
            type="button"
            className="remove-option-btn"
            onClick={handleDeleteClicked}
          >
            <i className="fas fa-times" />
          </button>
        )}
      </div>
    </li>
  );
};

export default PhaseReviewer;
