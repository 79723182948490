import React from 'react';

import TemplateListComponent from 'components/Template/TemplateList';

import TemplateProvider from 'context/TemplateProvider';

const TemplatesList: React.FC = () => (
  <TemplateProvider>
    <TemplateListComponent />
  </TemplateProvider>
);

export default TemplatesList;
