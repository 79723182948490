import { TemplateFieldTypes } from "models/template";
import { IPhaseReviewer } from "models/addProject";
import { IPhaseComment } from 'models/phaseReview';

export interface IProjectPhase
{
  name: string;
  clientName: string;
  phasesOrder: string[];
  phase: IPhaseQuestion;
  phaseReviewers: IPhaseReviewer[];
  phaseComments: IPhaseComment[];
  logoSrc?: string;
  ownerVote?: string;
  activePhaseId?: string;
  projectOwner?: string;
}

export interface IVote
{
  answer: string;
  userEmail: string;
  userId: string;
}

export interface IPhaseQuestion
{
  order: any;
  id: string;
  title: string;
  options: IOption[];
  type: TemplateFieldTypes;
  phaseComments: IPhaseComment[];
  ownerVote?: string;
  votes?: IVote[];
  phase?: {
    order?: number
  }
}

export interface IOption
{
  id: string;
  text?: string;
  imageSrc: string;
  fileName?: string;
}

export enum QuestionOptionsDisplay
{
  GRID = 'GRID',
  STACKED = 'STACKED',
}

export interface IModalOptions
{
  isOpen: boolean;
  displayedValue?: string;
}
