import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { IPhaseComment } from 'models/phaseReview';

import { createPhaseComment } from 'api/mutations';

import useValidation from 'hooks/useValidation';
import useAuthorisation from 'hooks/useAuthorisation';

interface IUsePhaseComments {
  comments: IPhaseComment[];
  showAddComment: boolean;
  newComment: string;
  handleAddCommentClick: () => void;
  handleAddCommentKeyDown: (
    event: React.KeyboardEvent<HTMLInputElement>,
  ) => void;
  handleCommentInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

interface IUserPhaseCommentsProps {
  initialComments: IPhaseComment[];
  commentAnswer?: string;
  isTextArea: boolean;
}

interface IParams {
  projectId: string;
  phaseId: string;
}

const usePhaseComments = ({
  initialComments,
  commentAnswer,
  isTextArea,
}: IUserPhaseCommentsProps): IUsePhaseComments => {
  const [comments, setComments] = useState<IPhaseComment[]>(initialComments);
  const [newComment, setNewComment] = useState<string>('');
  const { projectId, phaseId } = useParams<IParams>();
  const { isCommentValidationPassed } = useValidation();
  const { currentUser } = useAuthorisation();

  const showAddComment = Boolean(commentAnswer) || isTextArea;

  const addComment = async (): Promise<void> => {
    const updatedComments = [...comments];
    const validId = comments.length;
    const seconds = new Date().getTime();
    const comment = {
      email: currentUser?.email || '',
      time: { seconds },
      id: `${validId}`,
      commentedAnswer: commentAnswer,
      message: newComment,
    };
    updatedComments.push(comment);
    await createPhaseComment(projectId, phaseId, comment);
    setComments(updatedComments);
  };

  const handleAddCommentClick = (): void => {
    if (isCommentValidationPassed(newComment)) {
      addComment();
      setNewComment('');
    }
  };

  const handleAddCommentKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>,
  ): void => {
    if (event.key === 'Enter') {
      handleAddCommentClick();
    }
  };

  const handleCommentInput = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;
    setNewComment(value);
  };

  return {
    newComment,
    comments,
    showAddComment,
    handleAddCommentClick,
    handleAddCommentKeyDown,
    handleCommentInput,
  };
};

export default usePhaseComments;
