import React from 'react';

interface IButton {
  children: React.ReactNode;
  className?: string;
  styleType?: 'primary' | 'secondary' | 'tertiary' | 'icon' | 'lightGreen';
  type?: 'button' | 'submit' | 'reset';
  onClick: (() => void) | ((e: any) => void);
  isLoading?: boolean;
  isDisabled?: boolean;
  loadingText?: string;
  id?: string;
}

const LoaderWrapper: React.FC<IButton> = ({
  children,
  className = '',
  onClick,
  type = 'button',
  styleType = 'primary',
  isLoading = false,
  isDisabled = false,
  loadingText = 'Loading...',
  id = '',
}) => {
  const styleTypeClassName = `button__${styleType}`;
  return (
    <button
      id={id}
      type={type === 'submit' ? 'submit' : 'button'}
      className={`button ${className} ${styleTypeClassName}`}
      onClick={onClick}
      disabled={isLoading || isDisabled}
    >
      {isLoading ? loadingText : children}
    </button>
  );
};

export default LoaderWrapper;
