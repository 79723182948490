import React, { useState } from 'react';

import useAuthorisation from 'hooks/useAuthorisation';

const INITIAL = {
  email: '',
  password: '',
};

interface ISignupForm
{
  email: string;
  password: string;
}

interface IUseSignup
{
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleSignupClicked: () => Promise<void>;
  handleGoogleSignupClicked: () => Promise<void>;
  handleFacebookSignupClicked: () => Promise<void>;
  handleTwitterSignupClicked: () => Promise<void>;
  handleGithubSignupClicked: () => Promise<void>;
  handleMicrosoftSignupClicked: () => Promise<void>;
  requestError: string;
  form: ISignupForm;
}

const useSignup = (): IUseSignup =>
{
  const [form, setForm] = useState<ISignupForm>(INITIAL);
  const [requestError, setRequestError] = useState<string>('');
  const {
    signInWithGoogle,
    signInWithFacebook,
    signInWithTwitter,
    signInWithGithub,
    signInWithMicrosoft,
    signUpWithEmailAndPassword
  } = useAuthorisation();

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void =>
  {
    const { value, name } = e.currentTarget;

    setForm({ ...form, [name]: value });
  };

  const handleSignupClicked = async (): Promise<void> =>
  {
    const { email, password } = form;
    try
    {
      await signUpWithEmailAndPassword(email, password);
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };


  const handleGoogleSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithGoogle();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleFacebookSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithFacebook();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleTwitterSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithTwitter();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleGithubSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithGithub();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleMicrosoftSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithMicrosoft();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  return {
    handleInputChange,
    handleSignupClicked,
    handleGoogleSignupClicked,
    handleFacebookSignupClicked,
    handleTwitterSignupClicked,
    handleGithubSignupClicked,
    handleMicrosoftSignupClicked,
    requestError,
    form
  };
};

export default useSignup;
