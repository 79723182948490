/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ProjectPhase } from 'models/addProject';
import { ITemplateField } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';
import resizeFile from 'helpers/resizeFile';
import useQuestions from './useQuestions';
import Question from './Question';


type Props = {
  projectQuestions: ITemplateField[]
  setFormQuestions: (phases: ProjectPhase[]) => void
  isDisabled: boolean
}

const Questions: React.FC<Props> = 
({projectQuestions, setFormQuestions, isDisabled}: Props) => {
  const { handleOnDragEnd, questions } = useQuestions({projectQuestions});

  const removeQuestion = (id: string): void => {
    let updatedQuestionList = [...projectQuestions];
      updatedQuestionList = projectQuestions.filter(
        (question) => question.id !== id,
      );
    setFormQuestions(updatedQuestionList)
  }

  const getUpdateQuestionList = (
    questionsList: ITemplateField[],
    id: string,
    propertyName: string,
    propertyValue: string | boolean,
  ): ITemplateField[] =>
    questionsList.map((question) => {
      if (question.id === id) {
        const updatedQuestion = { ...question };
        updatedQuestion[propertyName] = propertyValue;
        updatedQuestion.isEdited = true;
        return updatedQuestion;
      }
      return question;
    });


  const handleQuestionOptionChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
  id:string, optionIndex:number): void => {
    const title = e.target.value

      const updatedQuestionList = projectQuestions.map((question) => {
        if (question.id === id) {
          const updatedQuestion = { ...question };
          const updatedOptions = updatedQuestion.options!.map(
            (option, index) => {
              if (index === optionIndex) {
                return {
                  ...option,
                  text: title,
                };
              }
              return option;
            },
          );
          updatedQuestion.options = updatedOptions;
          return updatedQuestion;
        }
        return question;
      });

      setFormQuestions(updatedQuestionList)
  };

  const handleQuestionChanged = (
    e: React.ChangeEvent<HTMLInputElement>,
  id:string): void => {
    const title = e.target.value
    const updatedQuestionList = getUpdateQuestionList(
      projectQuestions,
      id,
      'title',
      title,
    );
    setFormQuestions(updatedQuestionList)
  };

  const handleAddQuestionOption = (id:string):void => {
    const updatedQuestionList = projectQuestions.map((question) => {
      const isTextChoise = question.type === TemplateFieldTypes.TEXT_CHOISE;
      const isImageChoise = question.type === TemplateFieldTypes.IMAGE_CHOISE;
      if (question.id === id) {
        const updatedQuestion = { ...question };
        const updatedOptions = [
          ...updatedQuestion.options!,
          {
            ...(isTextChoise ? { text: '' } : {}),
            ...(isImageChoise ? { src: undefined } : {}),
          },
        ];
        updatedQuestion.options = updatedOptions!;
        return updatedQuestion;
      }
      return question;
    });

    setFormQuestions(updatedQuestionList)
  }

  const handleRemoveQuestionOption = 
  (id:string, optionIndex:number | undefined):void => {

  const updatedQuestionList = projectQuestions.map((question) => {
    if (question.id === id) {
      const updatedQuestion = { ...question };
      let updatedOptions = [...updatedQuestion.options!];
      if (question.type === TemplateFieldTypes.TEXT_CHOISE) {
        updatedOptions = updatedQuestion.options!.filter(
          (option, index) => index !== optionIndex,
        );
      }
      if (question.type === TemplateFieldTypes.IMAGE_CHOISE 
        && optionIndex !== undefined) {
        const removedOptionItem = updatedQuestion.options![optionIndex];
        removedOptionItem.isRemoved = true;
      }

      updatedQuestion.options = updatedOptions;
      return updatedQuestion;
    }
    return question;
  });

    setFormQuestions(updatedQuestionList)
  }

  const handleEditClicked = ( id: string, isEditing: boolean):void => {
    const updatedQuestionList = getUpdateQuestionList(
      projectQuestions,
      id,
      'isEditing',
      isEditing,
    );
    setFormQuestions(updatedQuestionList)
  }

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    optionIndex: number,
  ): Promise<void> => {
    const target = event.target as HTMLInputElement;
    if (target?.files) {
      const image = target.files[0];

      const uri = await resizeFile(image);

      const FReader = new FileReader();
      FReader.readAsDataURL(uri as Blob);

      FReader.onload = (FREvent) => {
        if (FREvent.target) {
          const imageSrc = FREvent.target.result!
          const file =  image  
        const  questionsList = projectQuestions
        const updatedQuestionList = questionsList.map((question) => {
          if (question.id === id) {
            const updatedQuestion = { ...question };
            const updatedOptions = updatedQuestion.options!.map(
              (option, index) => {
                if (index === optionIndex) {
                  return {
                    fileName: file.name,
                    imageSrc: imageSrc.toString(),
                  };
                }
                return option;
              },
            );
            updatedQuestion.options = updatedOptions;
            return updatedQuestion;
          }
          return question;
        });
  
        setFormQuestions(updatedQuestionList)
        }
      };
    }
  };

  return (
    <DragDropContext onDragEnd={handleOnDragEnd}>
      <Droppable droppableId="characters">
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {questions.map((question, index) => (
              <Draggable
                key={question.id}
                draggableId={question.id}
                index={index}
              >
                {(providedItem) => {
                  if (!question.isRemoved) {
                    return (
                      <div
                        ref={providedItem.innerRef}
                        {...providedItem.draggableProps}
                      >
                        <Question
                        votingHasStarted={isDisabled}
                          handleFileSelect={handleFileSelect}
                          handleQuestionChanged={handleQuestionChanged}
                          handleQuestionOptionChanged=
                          {handleQuestionOptionChanged}
                          handleAddQuestionOption={handleAddQuestionOption}
                          handleRemoveQuestionOption=
                          {handleRemoveQuestionOption}
                          question={question}
                          index={question.displayIndex!}
                          dragHandleProps={providedItem.dragHandleProps}
                          removeQuestion={removeQuestion}
                          handleEditClicked={handleEditClicked}
                        />
                      </div>
                    );
                  }
                  return (
                    <div
                      ref={providedItem.innerRef}
                      {...providedItem.draggableProps}
                      {...providedItem.dragHandleProps}
                    />
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default Questions;
