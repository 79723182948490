import React from 'react';

interface ILoaderWrapper {
  isLoading: boolean;
  children: React.ReactNode;
}

const LoaderWrapper: React.FC<ILoaderWrapper> = ({ children, isLoading }) => (
  <>
    {isLoading ? (
      <div className="loader-wrapper">
        <div className="loader-roller">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
    ) : (
      children
    )}
  </>
);

export default LoaderWrapper;
