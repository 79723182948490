
import { getProducts } from 'api/queries';
import { IProduct } from 'models/products';
import { useEffect, useState } from 'react';

interface IUseProducts
{
  products: IProduct[];
  areProductsLoading: boolean;
}

const useProducts = (): IUseProducts =>
{
  const [products, setAllProducts] = useState<IProduct[]>([])
  const [areProductsLoading, setAreProductsLoading] = useState<boolean>(false)

  useEffect(() =>
  {
    getProducts(setAllProducts, setAreProductsLoading)
  },[]);


  return { products, areProductsLoading};

};

export default useProducts;
