import React, { useEffect } from 'react';

interface IProgressBar {
  id: string;
  dataValue: number;
}

const LOAD_SCRIPT_TIME = 100;

const ProjectProgressBar: React.FC<IProgressBar> = ({ id, dataValue }) => {
  useEffect(() => {
    setTimeout(() => {
      const circle = new (window as any).ProgressBar.Circle(`#${id}`, {
        strokeWidth: 3.3,
        easing: 'easeInOut',
        duration: 3000,
        color: '#f4f4f4',
        trailColor: '#69e2d6',
        trailWidth: 3,
        svgStyle: null,
      });

      // necessary to make the progress bar go anti-clockwise
      const percentage = (10 - dataValue) / 10;
      circle.set(1);
      circle.animate(percentage, () => {
        circle.animate(percentage);
      });
    }, LOAD_SCRIPT_TIME);
  }, [id, dataValue]);

  return <div className="project__progress-bar" id={id} />;
};

export default ProjectProgressBar;
