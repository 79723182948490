import React, { useEffect } from 'react';
import LoaderWrapper from 'components/Common/LoaderWrapper';
import Button from 'components/Common/Button';
import useProfile from 'components/Profile/useProfile';
import useFeedback from '../useFeedback';

enum ProfileFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  feedback = 'feedback',
}

interface IFeedbackForm {
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Feedback: React.FC<IFeedbackForm> = ({ setIsOpen }) => {
  const {
    form,
    handleSubmitClicked,
    handleInputChange,
    setForm,
    isLoading: feedBackIsLoading,
  } = useFeedback();
  const { profile, isLoading } = useProfile();

  useEffect(() => {
    if (profile) {
      const { email, firstName, lastName } = profile;
      setForm((prev) => ({
        ...prev,
        email: email || '',
        firstName: firstName || '',
        lastName: lastName || '',
      }));
    }
  }, [profile, setForm]);

  return (
    <div className="onboarding__center-container">
      <LoaderWrapper isLoading={isLoading || feedBackIsLoading}>
        <h1>We value your feedback</h1>
        <p>
          Please provide us with feedback in order for us to improve your
          experience.
        </p>
        <div className="onboarding__form-container">
          <div className="form-field-container">
            <label htmlFor="feedback">Feedback</label>
            <textarea
              onChange={handleInputChange}
              name={ProfileFields.feedback}
              value={form.feedback}
              rows={10}
            />
          </div>
          <div className="form-actions-container">
            <Button
              isDisabled={!form.feedback.length}
              onClick={() => {
                handleSubmitClicked().then(() => {
                  setIsOpen(false);
                });
              }}
            >
              Submit Feedback
            </Button>
          </div>
        </div>
      </LoaderWrapper>
    </div>
  );
};

export default Feedback;
