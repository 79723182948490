import React, { useState } from 'react';

import useValidation from 'hooks/useValidation';

interface IUsePhaseReviewer {
  reviewerEmail: string;
  showAddReviewer: boolean;
  addReviewerClick: (addPhaseReviewer: () => void) => void;
  handleReviewerEmailInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const usePhaseReviewer = (): IUsePhaseReviewer => {
  const [reviewerEmail, setReviewerEmail] = useState<string>('');
  const [showAddReviewer, setShowAddReviewer] = useState<boolean>(false);
  const { isReviewerValidationPassed } = useValidation();

  const addReviewerClick = async (
    addPhaseReviewer: () => void,
  ): Promise<void> => {
    if (showAddReviewer && isReviewerValidationPassed(reviewerEmail)) {
      addPhaseReviewer();
      setReviewerEmail('');
      setShowAddReviewer(false);
    }
    if (!showAddReviewer) {
      setShowAddReviewer(!showAddReviewer);
    }
  };

  const handleReviewerEmailInput = (
    e: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { value } = e.target;
    setReviewerEmail(value);
  };

  return {
    reviewerEmail,
    showAddReviewer,
    addReviewerClick,
    handleReviewerEmailInput,
  };
};

export default usePhaseReviewer;
