/* eslint-disable react/no-array-index-key */
import React from 'react';

import { ITemplateFieldOption } from 'models/createTemplate';

import useQuestionOptions from 'components/Template/Questions/Question/useQuestionOptions';

interface ITextChoise {
  isQuestionEdit: boolean;
  options?: ITemplateFieldOption[];
  questionId: string;
  editableOptions: boolean;
}

const TextChoise: React.FC<ITextChoise> = ({
  isQuestionEdit,
  options,
  questionId,
  editableOptions,
}) => {
  const { handleRemoveOption, handleAddOption, handleChangeOption } =
    useQuestionOptions(questionId);
  const isEdit = isQuestionEdit && editableOptions;

  if (options) {
    return (
      <form>
        {options.map((option) => {
          const { text, index, letter } = option;

          const handleRemove = (): void => {
            handleRemoveOption(index!);
          };

          const handleChange = (
            e: React.ChangeEvent<HTMLInputElement>,
          ): void => {
            const { value } = e.target;
            handleChangeOption(index!, value);
          };

          if (isEdit) {
            return (
              <div
                className="answers-container 
                  text-type-container no-focus-outline"
                key={index}
              >
                <label className="radio-container container-text">
                  <input className="radio-btn" type="radio" name="radio" />
                  <span className="checkmark">{letter}</span>
                </label>
                <div className="form-field-container">
                  <input
                    type="text"
                    className="opt-answer"
                    placeholder="Type answer..."
                    value={text}
                    onChange={handleChange}
                    maxLength={30}
                  />

                  <span className="opt-answer-error">
                    {text?.length === 30 ? 'Max length of 30 characters' : ''}
                  </span>
                </div>
                <div className="">
                <button
                  type="button"
                  className="delete-answer-btn delete-text-btn"
                  onClick={handleRemove}
                >
                  Remove
                  <i className="fas fa-trash" />
                </button>
                </div>
              </div>
            );
          }
          return (
            <label className="radio-container container-text" key={index}>
              {text}
              <input type="radio" name="radio" disabled />
              <span className="checkmark">{letter}</span>
            </label>
          );
        })}
        {isEdit && (
          <div className="add-answer-container no-focus-outline">
            <button
              className="add-answer-btn add-text-btn"
              type="button"
              onClick={handleAddOption}
            >
              <i className="fas fa-plus" />
            </button>
            <span>Add Answer</span>
          </div>
        )}
      </form>
    );
  }
  return null;
};

export default TextChoise;
