/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import classNames from 'classnames';
import Options from 'components/ProjectPhase/Options';
import useProjectPhase from 'components/ProjectPhase/useProjectPhase';
import Modal from 'components/ProjectPhase/Modal';
import LoaderWrapper from 'components/Common/LoaderWrapper';
import { QuestionOptionsDisplay } from 'models/questions';
import { ICONS } from 'constants/images';
import PageContainer from 'components/Common/PageContainer';
import ContentContainer from 'components/Common/ContentContainer';
import Button from 'components/Common/Button';
import PhaseReviewers from 'components/Common/PhaseReviewers';
import { PhaseReviewsTypes } from 'models/phaseReview';
import { getCurrentUserId } from 'helpers/firebase';
import PhaseComments from './Sidebar/PhaseComments';

const ProjectPhase: React.FC = () => {
  const {
    selectedAnswer,
    isDisplayModeGrid,
    modalOptions,
    commentAnswer,
    isLoading,
    projectPhase,
    phaseReviewers,
    reviewerEmail,
    showAddReviewer,
    phaseComments,
    phaseVotes,
    textarea,
    isSubmitButtonActive,
    isTextarea,
    isLastPhase,
    phasesCount,
    currentPhaseIndex,
    answerSubmitted,
    handleTextareaChange,
    handleAnswerSubmitted,
    handlePreviousPhaseClicked,
    handleAnswerChange,
    handleDisplayModeChange,
    setModalProperties,
    handleCommentClick,
    handleAddReviewerClick,
    handleAddReviewerKeyDown,
    handleReviewerEmailInput,
    areAllPhasesSubmitted,
    isPhaseReviewer
  } = useProjectPhase();

  const { name, clientName, phase } = { ...projectPhase };
  const { title, options: questionOptions } = { ...phase };

  const gridViewButtonСlassName = classNames({
    'grid-view-btn': true,
    selected: isDisplayModeGrid,
  });

  const stackedViewButtonClassName = classNames({
    'stacked-view-btn': true,
    selected: !isDisplayModeGrid,
  });

  const gridImgSrc = isDisplayModeGrid
    ? ICONS.GRID_ENABLED
    : ICONS.GRID_DISABLED;

  const stackedImgSrc = isDisplayModeGrid
    ? ICONS.STACKED_DISABLED
    : ICONS.STACKED_ENABLED;

    const currentUserId = getCurrentUserId();
    const currentUserHasVoted = 
    phaseVotes?.some(vote => vote.userId === currentUserId);

    const totalVotesNeeded =  phaseReviewers?.length || 0
    const currentVoteCount = phaseVotes?.length || 0
    const currentVotesNeeded = totalVotesNeeded - currentVoteCount
    const reviewerAwaitingLabelText = 
    currentVotesNeeded ?  `Awaiting ${currentVotesNeeded} Reviewer 
    vote${currentVotesNeeded === 1 ? '' : 's'}`  : 
 'Awaiting Owner vote confirmation' 
    const ownerAwaitingLabelText =  
    `Project owner decision - ${currentVotesNeeded ? 
      `Awaiting ${currentVotesNeeded} Reviewer 
    vote${currentVotesNeeded === 1 ? '' : 's'}` 
    :'Please confirm the final decision'}`

  return (
    <>
      <PageContainer
        isMinimized
        headerTitle={clientName ? `${clientName} - ${name}` : ''}
        className="phase-review"
        hasTag={clientName ? areAllPhasesSubmitted : false}
        tagLabel='Complete'
      >
        <div className="grid-container-3-1">
        <ContentContainer 
        title={`Phase ${currentPhaseIndex + 1} 
        of ${phasesCount}: ${isPhaseReviewer ? reviewerAwaitingLabelText 
        : ownerAwaitingLabelText}`}>
          <div>
            <div className="logo-selection">
              <div className="logo-selection__header">
                <div className="logo-selection__header-inner">
                  <h2 className="logo-selection__question">{title}</h2>
                  {!isTextarea && (
                    <div
                      className="logo-selection__display-type 
                  no-focus-outline"
                    >
                      <p>VIEW</p>
                      <button
                        className={gridViewButtonСlassName}
                        type="button"
                        id={QuestionOptionsDisplay.GRID}
                        onClick={handleDisplayModeChange}
                      >
                        <img src={gridImgSrc} alt="grid icon" />
                      </button>

                      <button
                        className={stackedViewButtonClassName}
                        type="button"
                        id={QuestionOptionsDisplay.STACKED}
                        onClick={handleDisplayModeChange}
                      >
                        <img src={stackedImgSrc} alt="grid icon" />
                      </button>
                    </div>
                  )}
                </div>

                <p className="logo-selection__description">
                  Select an option you agree with most.
                </p>
              </div>

              <LoaderWrapper isLoading={isLoading}>
                <Options
                  options={questionOptions}
                  votes={phaseVotes}
                  isDisplayModeGrid={isDisplayModeGrid}
                  selectedAnswer={selectedAnswer}
                  commentAnswer={commentAnswer}
                  textarea={textarea}
                  answerSubmitted={answerSubmitted}
                  onTextareaChange={handleTextareaChange}
                  onAnswerChange={handleAnswerChange}
                  onImageClick={setModalProperties}
                  onCommentClick={handleCommentClick}
                />
              </LoaderWrapper>
              <div className="logo-selection__actions-container">
                {projectPhase?.phase?.order === 0 ? <div /> :<Button
                  styleType="tertiary"
                  onClick={handlePreviousPhaseClicked}
                >
                  Previous Phase
                </Button>}
                <Button
                  isDisabled={!isSubmitButtonActive || currentUserHasVoted}
                  onClick={handleAnswerSubmitted}
                >
                  {isPhaseReviewer ? (
                    <>
                      <span className="info">Submit Phase</span>
                      <i className="fas fa-long-arrow-alt-right" />
                    </>
                  ) : (
                    <>
                      <span className="info">
                        {isLastPhase ? 
                        `${ areAllPhasesSubmitted ?'Done':'Submit'}` 
                        : 'Next Phase'}
                      </span>
                      <i className="fas fa-long-arrow-alt-right" />
                    </>
                  )}
                </Button>
              </div>
            </div>
            {questionOptions && (
              <Modal
                modalOptions={modalOptions}
                questionOptions={questionOptions}
                selectedAnswer={selectedAnswer}
                setModalProperties={setModalProperties}
                onAnswerChange={handleAnswerChange}
              />
            )}
          </div>
        </ContentContainer>
        <ContentContainer
            title={`${phaseComments?.length || '0'} Comment${
              phaseComments?.length !== 1 ? 's' : ''
            }`}
          >
            <LoaderWrapper isLoading={isLoading}>
              <PhaseComments
                commentAnswer={commentAnswer}
                phaseComments={phaseComments}
                isTextArea={isTextarea}
              />
            </LoaderWrapper>
          </ContentContainer>
        </div>

          <ContentContainer
            title={`${phaseReviewers?.length || '0'} Reviewer${
              phaseReviewers?.length !== 1 ? 's' : ''
            }`}
          >
            <LoaderWrapper isLoading={isLoading}>
              <PhaseReviewers
                phaseReviewers={phaseReviewers}
                handleAddReviewerClick={handleAddReviewerClick}
                handleAddReviewerKeyDown={handleAddReviewerKeyDown}
                type={PhaseReviewsTypes.PROJECT_REVIEW}
                reviewerEmail={reviewerEmail}
                showAddReviewer={showAddReviewer}
                handleReviewerEmailInput={handleReviewerEmailInput}
                votes={phaseVotes}
                areAllPhasesSubmitted={areAllPhasesSubmitted}
              />
            </LoaderWrapper>
          </ContentContainer>
      </PageContainer>
    </>
  );
};

export default ProjectPhase;
