import React, {
  useEffect,
  useState
} from 'react';

import { getCurrentUserId } from 'helpers/firebase';

import useValidation from 'hooks/useValidation';
import { mutations, queries } from 'api';
import { PLACEHOLDER_URL } from 'constants/common';
import { UserRoles } from 'models/roles';
import { Collections, SubCollections } from 'api/constants';
import { IUpdateProfile } from 'models/updateProfile';
import { getStripePortalLink, getTempAssignedProjectIds } from 'api/queries';
import useAuthorisation from 'hooks/useAuthorisation';
import { createCheckoutSession, createProfile, updateUserPassword } from 'api/mutations';
import resizeFile from 'helpers/resizeFile';
import useProducts from './useProducts';

const INITIAL = {
  firstName: '',
  lastName: '',
  displayName: '',
  password: '',
  confirmPassword: '',
  organization: '',
  avatar: null,
  avatarURL: PLACEHOLDER_URL,
  email: '',
  isProfileComplete: false
};

const INITIAL_ERRORS = {
  password: '',
  confirmPassword: '',
};

interface IProfileForm {
  firstName: string;
  lastName: string;
  displayName: string;
  organization: string;
  avatar?: File | null,
  avatarURL: string,
  password: string;
  confirmPassword: string;
  email: string;
  isProfileComplete: boolean;
}
interface IFormErrors {
  password: string;
  confirmPassword: string;
}
interface IUseProfile {
  profile: IUpdateProfile | undefined;
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleAssignTempProjectIds: () => void;
  handleSubmitClicked: () => Promise<void>;
  handlePasswordUpdate: () => Promise<void>;
  handleAvatarSelect: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  updateUserRole: (role: UserRoles) => Promise<void>;
  form: IProfileForm;
  setForm: React.Dispatch<React.SetStateAction<IProfileForm>>;
  errors: IFormErrors
  isFormValid: boolean;
  isLoading: boolean;
  checkoutSession: any
  createPortalLink: () => void 
  subscriptions: any
}

const useProfile = (): IUseProfile => {
  const [profile, setProfile] = useState<IUpdateProfile | undefined>()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [form, setForm] = useState<IProfileForm>(INITIAL);
  const [errors, setErrors] = useState<IFormErrors>(INITIAL_ERRORS)
  const {
    isUpdateProfileValidationPassed,
    isPasswordValid,
    isConfirmPasswordValid
  } = useValidation();
  const currentUserId = getCurrentUserId();
  const [isFormValid, setIsFormValid] = useState<boolean>(false)
  const { hasPasswordLoginMethod } = useAuthorisation();
  const [currentSessionId, setCurrentSessionId] = useState<string>('')
  const [checkoutSession, setCheckoutSession] = useState()
  const [subscriptions, setSubscriptions] = useState()
  const {products} = useProducts()

  useEffect(() => {
    let isCancelled = false;
    if (!isCancelled && currentUserId) {

    queries.getDocument(Collections.USERS, currentUserId, setProfile)
    queries.getSubscriptions(currentUserId, setSubscriptions)
    }

    return () => {
      isCancelled = true;
    };
  }, [currentUserId]);

  useEffect(() => {
    if (form) {
      const isRequiredComplete = !!form.firstName && !!form.lastName
      const hasErrors = Object.values(errors).every(x => x === '');
      setIsFormValid(isRequiredComplete && hasErrors)
    }

  }, [form, errors, hasPasswordLoginMethod])

  useEffect(() => {
    if (profile) {
      setIsLoading(false)
    } else {
      createProfile(currentUserId)
      setIsLoading(true)
    }
  }, [currentUserId, profile])


  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
    const { value, name } = e.currentTarget;

    if (hasPasswordLoginMethod && name === 'password') {
      const passwordError = isPasswordValid(value)
      const confirmPasswordError =
        isConfirmPasswordValid(value, form.confirmPassword)
      setErrors(prev => ({
        ...prev, password: passwordError,
        confirmPassword: confirmPasswordError
      }))

    }
    if (hasPasswordLoginMethod && name === 'confirmPassword') {
      const confirmPasswordError = isConfirmPasswordValid(form.password, value)
      setErrors(prev => ({ ...prev, confirmPassword: confirmPasswordError }))
    }

    setForm({ ...form, [name]: value });
  };

  const handleAssignTempProjectIds = (): void => {
    if (profile?.email) {
      getTempAssignedProjectIds(profile?.email).then((projectIds) => {
        mutations.updateUserProfile(currentUserId,
          { ...profile, projectIds, isProfileComplete: true });
      })
    }
  };


  const handleAvatarSelect = async (
    event?: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> => {
    if (event) {

      const target = event.target as HTMLInputElement;
      if (target?.files?.[0]) {
        const image = target.files[0];

        const uri = await resizeFile(image);

        const FReader = new FileReader();
        FReader.readAsDataURL(uri as Blob);

        FReader.onload = (FREvent) => {
          if (FREvent.target) {
            setForm(prev => ({
              ...prev,
              avatar: image,
              avatarURL: FREvent.target?.result! as string,
            }));
          }
        };
      }
    } else {
      setForm({
        ...form,
        avatar: null,
        avatarURL: PLACEHOLDER_URL,
      });
    }
  };

  const handleSubmitClicked = async (): Promise<void> =>  {
    if (isUpdateProfileValidationPassed(form)) {
      const avatarURL = form.avatarURL || PLACEHOLDER_URL
      const displayName = `${form.firstName} ${form.lastName}`
      await mutations.updateUserProfile(currentUserId,
        { ...form, displayName, avatarURL });
    }
  };

  const handlePasswordUpdate = async (): Promise<void> => {
    if (form.password) {
      updateUserPassword(form.password)
    }
  };

  const updateUserRole = async (role: UserRoles): Promise<void> => {
    setIsLoading(true)
    
    await mutations.changeUserRole(currentUserId,
      { role }).then(async () => {
        setIsLoading(true)
        const priceId = products[0]?.prices[2]?.id
        if (role === UserRoles.projectOwner && priceId) {
          const session = await createCheckoutSession(priceId)
          if (session) {
    
            setCurrentSessionId(session)
          }
        }
      });
  };

 const createPortalLink = (): void => {
   setIsLoading(true)
    getStripePortalLink()
  }

  useEffect(() => {
    if (!currentSessionId) {
      return;
    }
    // eslint-disable-next-line max-len
    queries.getDocument(`${Collections.USERS}/${currentUserId}/${SubCollections.CHECKOUT_SESSIONS}`,
     currentSessionId, setCheckoutSession)
  }, [currentSessionId, currentUserId]);

  return {
    profile,
    isLoading,
    handleInputChange,
    handleSubmitClicked,
    handleAvatarSelect,
    handlePasswordUpdate,
    setForm,
    updateUserRole,
    form,
    handleAssignTempProjectIds,
    errors,
    isFormValid,
    checkoutSession,
    createPortalLink, 
    subscriptions
  };
};

export default useProfile;


