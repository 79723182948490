import React from 'react';
import LoaderWrapper from 'components/Common/LoaderWrapper';
import Project from 'components/Dashboard/Project';
import useDashboard from 'components/Dashboard/useDashboard';
import useScript from 'hooks/useScript';
import PageContainer from 'components/Common/PageContainer';
import useProfile from 'components/Profile/useProfile';
import ContentContainer from 'components/Common/ContentContainer';

import { PAGE_IMAGES } from 'constants/images';

const Dashboard: React.FC = () => {
  useScript({ url: 'js/progressbar.min.js' });

  const { isLoading, projects } = useDashboard();
  const { profile } = useProfile();

  return (
    <>
      <PageContainer headerTitle="Dashboard" className="dashboard">

        <ContentContainer isFullHeight={profile?.isProfileComplete}>
          <div className="welcome-message">
            <h1>{`Welcome to Jetrospects${
              profile?.firstName ? `, ${profile.firstName}.` : ''
            }`}</h1>
            {projects.length > 0 ? (
              <p>You have the following Projects to review.</p>
            ) : (
              <>
                <p>You currently dont have any Projects to review. </p>
                <p>
                  You will be informed via email as soon as a Project Owner has
                  invited you to review a project.
                </p>
                <div className="empty-img-container">
                  <img
                    className="empty-img"
                    src={PAGE_IMAGES.EMPTY_2}
                    alt="void"
                  />
                </div>
              </>
            )}
          </div>
          <div className="templates-container">
            <LoaderWrapper isLoading={isLoading}>
              <>
                {projects.length > 0 &&
                  projects
                    .filter((p) => p.phasesOrder)
                    .map((project) => (
                      <Project key={project.id} project={project} />
                    ))}
              </>
            </LoaderWrapper>
          </div>
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default Dashboard;
