const FIREBASE_TIMESTAMP_SECOND_LENGTH = 10;

const isToday = (someDate: Date): boolean => {
  const today = new Date();
  return (
    someDate.getDate() === today.getDate() &&
    someDate.getMonth() === today.getMonth() &&
    someDate.getFullYear() === today.getFullYear()
  );
};

export const formatAMPM = (timestamp: string): string => {
  const additional =
    timestamp.length === FIREBASE_TIMESTAMP_SECOND_LENGTH ? '300' : '';
  const date = new Date(Number(`${timestamp}${additional}`));
  const isDateToday = isToday(date);
  const hoursValue = date.getHours();
  const minutesValue = date.getMinutes();
  const ampm = hoursValue >= 12 ? 'PM' : 'AM';
  const hhours = hoursValue % 12;
  const hours = hhours ? hoursValue : 12;
  const minutes = minutesValue < 10 ? `0${minutesValue}` : `${minutesValue}`;
  const time = `${hours}:${minutes} ${ampm}`;
  const result = `${
    !isDateToday ? `${date.toLocaleDateString()} ` : ''
  }${time}`;

  return result;
};
