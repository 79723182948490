import { useEffect } from 'react';

interface IUseScriptProps {
  url: string;
}

const useScript = ({ url }: IUseScriptProps): void => {
  useEffect(() => {
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [url]);
};

export default useScript;
