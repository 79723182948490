import {useEffect, useState } from 'react'
import { DropResult } from 'react-beautiful-dnd';
import { ITemplateField } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';

interface IUseQuestions {
  handleOnDragEnd: (result: DropResult) => void;
  questions: ITemplateField[];
}

interface IUseQuestionsProps {
  projectQuestions: ITemplateField[];
}


const useQuestions = ({projectQuestions}: IUseQuestionsProps): 
IUseQuestions => {
  // const { templateId } = useParams<IParams>();

  const [questions, setQuestions] = useState<ITemplateField[]>([   {
    title: 'Which shape do you resonate most with?',
    options: [
      { text: 'Circle', index: 0, letter: 'A' },
      { text: 'Square', index: 1, letter: 'B' },
      { text: 'Triangle', index: 2, letter: 'C' },
    ],
    isEditing: false,
    type: TemplateFieldTypes.TEXT_CHOISE,
    id: '0',
    order: 0,
  },])


  useEffect(() =>
  {
    if (!projectQuestions)
    {
      return;
    }
    let displayIndex: number = -1;
    const newQuestions = projectQuestions.map((question: ITemplateField) => {
      if (!question.isRemoved) {
        displayIndex += 1;
      }
      return {
        ...question,
        displayIndex,
      };
    });

    setQuestions(newQuestions)
  }, [projectQuestions]);



  const handleOnDragEnd = (result: DropResult): void => {
    const { destination, source } = result;
    if (!destination) return;
  const [reorderedItem] = questions.splice(source.index, 1);
  questions.splice(destination.index, 0, reorderedItem);
  const udpatedQuestionsList = questions.map((question) => ({
    ...question,
    isEdited: true,
  }));

  setQuestions(udpatedQuestionsList)
  };

  return { handleOnDragEnd, questions };
};

export default useQuestions;