/* eslint-disable @typescript-eslint/no-use-before-define */
import {
addDoc,
setDoc,
collection,
doc,
updateDoc,
deleteDoc,
serverTimestamp,
arrayUnion,
getDoc,
} from 'firebase/firestore';
import {
deleteObject,
getDownloadURL,
ref,
uploadBytes,
StorageReference,
} from 'firebase/storage';
import {
EmailAuthProvider,
reauthenticateWithCredential,
sendSignInLinkToEmail, updatePassword
} from 'firebase/auth';
import { db, storage, auth } from 'api/config';
import { Collections, StorageFolders, SubCollections } from 'api/constants';
import { ICreateTemplate, IQuestion } from 'models/createTemplate';
import { IAddProject, IPhaseReviewer, ProjectPhase } from 'models/addProject';
import { TemplateFieldTypes } from 'models/template';
import { IPhaseComment } from 'models/phaseReview';
import { successNotification, errorNotification } from 'helpers/notifications';
import { Errors, Success } from 'constants/notifications';
import { QUERY_PARAMS } from 'constants/routes';
import { getCurrentUserId } from 'helpers/firebase';
import { UserRoles } from 'models/roles';
import { IUpdateProfile } from 'models/updateProfile';
import { IAddFeedback } from 'models/addFeedback';
import getPhaseCompleteMailTemplate from 'components/MailTemplates/PhaseComplete';
import getExistingUserProjectInviteMailTemplate from 'components/MailTemplates/ProjectInviteExisingUser';
import getNewUserProjectInviteMailTemplate from 'components/MailTemplates/ProjectInviteNewUser';
import { IProjectPhase } from 'models/questions';
import { findUserByEmail, getCollectionById } from './queries';


export const publishProject = async (project: IAddProject):
  Promise<void> => {
  try {
    const { id, } = project;
    const projectDoc = doc(db, Collections.PROJECTS, String(id));

    await sendPhaseReviewerInvites(project)

    if (projectDoc) {
      await updateDoc(projectDoc, {
        status: 'published',
      });
    }

    successNotification(Success.PROJECT_PUBLISH_SUCCESS);
  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.PROJECT_PUBLISH_FAIL);
  }
};



export const createProject = async (project: IAddProject):
  Promise<string | undefined> => {
  const currentUserId = getCurrentUserId();
  const projects = collection(db, Collections.PROJECTS);

  const { id, name, description, clientName } = project;

  // 1. Add a new project with a generated id.
  try {
    if (id) {
      const projectDoc = doc(db, Collections.PROJECTS, id);

      if (projectDoc) {
        await updateDoc(projectDoc, {
          projectOwner: currentUserId,
          name,
          description,
          clientName
        });

        successNotification(Success.PROJECT_ADDING_SUCCESS);
      }
      return id
    }

    const projectRef = await addDoc(projects, {});
    const projectId = projectRef.id;

    // 2. Add form data to the project
    if (projectId) {

      const projectDoc = doc(db, Collections.PROJECTS, projectId);

      await setDoc(projectDoc, {
        id: projectId,
        projectOwner: currentUserId,
        name,
        description,
        clientName,
        status: 'draft'
      });

      // 3. Add project id to user
      await linkProjectToOwner(projectId)
      successNotification(Success.PROJECT_ADDING_SUCCESS);
    }
    return projectId


  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.PROJECT_ADDING_FAIL);
    return undefined;
  }
};

export const createProjectPhases = async (project: IAddProject):
  Promise<void> => {
  const { id, phases, selectedTemplateId } = project;
  const phasesOrder: string[] = [];

  if (id && phases && selectedTemplateId) {
    try {
      // 1. Delete existing phases
      const phasesData = await getCollectionById(
        `${Collections.PROJECTS}/${id}/${SubCollections.PHASES}`);

      if (phasesData.length) {

        await Promise.all(
          phasesData.map(async (phase) => {
            await deleteDoc(doc(
              db,
              Collections.PROJECTS,
              id,
              SubCollections.PHASES,
              phase.id!,
            ));

          }),
        );
      }

      // 1. Add phases to the project
      await Promise.all(
        phases.map(async (phase) => {
          const phaseId = await createProjectPhase(id, {
            ...phase,
            comments: [],
          });
          const order = Number(phase.order!);
          phasesOrder[order] = phaseId!;
        }),
      );

      // 2. Update the project document
      const projectDoc = doc(db, Collections.PROJECTS, id);

      await updateDoc(projectDoc, {
        activePhaseId: phasesOrder[0],
        phasesOrder,
        submittedPhases: 0,
        selectedTemplateId
      });
      successNotification(Success.PROJECT_PHASES_ADDING_SUCCESS);
    } catch (error) {
      const e = error as Error;
      errorNotification(e.message);
      errorNotification(Errors.PROJECT_PHASES_ADDING_FAIL);
    }
  }
};

export const createPhaseReviewers = async (project: IAddProject):
  Promise<void> => {
  const { id, phaseReviewers, } = project;

  if (id && phaseReviewers) {
    try {
      // 1. Add phase reviewers to the project
      await Promise.all(
        phaseReviewers.map(async (phaseReviewer) => {
          await createPhaseReviewer(id, phaseReviewer);
        }),
      );

      successNotification(Success.REVIEWER_IS_ADDED);
    } catch (error) {
      const e = error as Error;

      errorNotification(e.message);
      errorNotification(Errors.REVIEWER_ADDING_FAIL);
    }
  }
};

export const sendPhaseReviewerInvites = async (project: IAddProject):
  Promise<void> => {
  const { id, phaseReviewers, } = project;

  if (id && phaseReviewers) {
    try {
      // 1. Add phase reviewers to the project
      await Promise.all(
        phaseReviewers.map(async (phaseReviewer) => {
          await sendReviewerEmail(project, phaseReviewer);
        }),
      );

      successNotification(Success.REVIEWER_IS_ADDED);
    } catch (error) {
      const e = error as Error;

      errorNotification(e.message);
      errorNotification(Errors.REVIEWER_ADDING_FAIL);
    }
  }
};

export const createProjectLogo = async (project: IAddProject):
  Promise<void> => {
  const { id, logo } = project;
  let logoSrc = '';

  if (id && logo) {
    try {
      // 1. Add logo to the project      
      if (logo) {
        logoSrc = await uploadProjectImage(logo, id);
      }

      const projectDoc = doc(db, Collections.PROJECTS, id);

      await updateDoc(projectDoc, {
        logoSrc,
      });

      successNotification(Success.PROJECT_LOGO_ADDING_SUCCESS);
    } catch (error) {
      const e = error as Error;

      errorNotification(e.message);
      errorNotification(Errors.PROJECT_LOGO_ADDING_FAIL);
    }
  }
};

export const createProjectPhase = async (
  projectId: string,
  phase: ProjectPhase,
): Promise<string | undefined> => {
  const phases = collection(
    db,
    Collections.PROJECTS,
    projectId,
    SubCollections.PHASES,
  );

  // Add a new phase to the project with a generated id.
  const phaseRef = await addDoc(phases, {});
  const phaseId = phaseRef.id;

  if (phaseId) {
    const phaseDoc = doc(
      db,
      Collections.PROJECTS,
      projectId,
      SubCollections.PHASES,
      phaseId,
    );
    await Promise.all(
      phase.comments!.map(async (comment) => {
        await createPhaseComment(projectId, phaseId, comment);
      }),
    );

    await setDoc(phaseDoc, {
      options: phase.options,
      order: phase.order,
      title: phase.title,
      type: phase.type,
      id: phaseId,
    });
    return phaseId;
  }
  return undefined;
};

export const sendReviewerEmail = async (
  project: IAddProject,
  phaseReviewer: IPhaseReviewer,
): Promise<string | undefined> => {

  const { id: projectId } = project;


  const existingUsers = await findUserByEmail(phaseReviewer.email)
  const user = existingUsers?.find(element => element !== undefined);

  const { email } = phaseReviewer;

  const ownerEmail = auth?.currentUser?.email || ''

  if (email) {
    if (user?.id) {
      const userRef = doc(db, Collections.USERS, user.id);
      await updateDoc(userRef, {
        projectIds: arrayUnion(projectId)
      });

      // Send invite email
      const mailRef = collection(db, Collections.MAIL);
      await addDoc(mailRef, {
        // from: 'Jetrospects <noreply@jetrospects.com>',
        to: email,
        message: {
          subject: 'You have been invited to review a project on Jetrospects',
          html: getExistingUserProjectInviteMailTemplate(ownerEmail, project)
        }
      });
    } else {
      const tempUsersRef = collection(db, Collections.TEMP_USERS);
      await addDoc(tempUsersRef, {
        id: email,
        email,
        projectIds: arrayUnion(projectId)
      });
      // await sendSignInLink(phaseReviewer.email!, projectId);

      // Send invite email
      const mailRef = collection(db, Collections.MAIL);
      await addDoc(mailRef, {
        to: email,
        message: {
          // from: 'Jetrospects <noreply@jetrospects.com>',
          subject: 'You have been invited to review a project on Jetrospects',
          html: getNewUserProjectInviteMailTemplate(ownerEmail, project)
          // text: 'Please click on the sign in link to join'
        }
      });
    }


  }

  return undefined;
};

export const createPhaseReviewer = async (
  projectId: string,
  phaseReviewer: IPhaseReviewer,
): Promise<string | undefined> => {
  const phaseReviewers = collection(
    db,
    Collections.PROJECTS,
    projectId,
    SubCollections.PHASE_REVIEWERS,
  );

  const existingUsers = await findUserByEmail(phaseReviewer.email)
  const user = existingUsers?.find(element => element !== undefined);

  const phaseReviewerRef = user?.id ?
    doc(db, Collections.PROJECTS,
      projectId,
      SubCollections.PHASE_REVIEWERS, user?.id) :
    await addDoc(phaseReviewers, {});

  const phaseReviewerId = phaseReviewerRef.id;

  const { email } = phaseReviewer;

  if (email) {

    if (phaseReviewerId) {
      const phaseDoc = doc(
        db,
        Collections.PROJECTS,
        projectId,
        SubCollections.PHASE_REVIEWERS,
        phaseReviewerId,
      );
      await setDoc(phaseDoc, {
        email: phaseReviewer.email,
        id: phaseReviewerId,
      });
      return phaseReviewerId;
    }

  }

  return undefined;
};

export const createTemplate = async (
  template: ICreateTemplate,
): Promise<void> => {
  try {
    const { title, questions } = template;
    const currentUserId = getCurrentUserId();

    const templates = collection(db, Collections.TEMPLATES);

    const templateRef = await addDoc(templates, {});

    const templateId = templateRef.id;

    // 2. Add form data to the template
    if (templateId) {

      const templateDoc = doc(db, Collections.TEMPLATES, templateId);

      await setDoc(templateDoc, {
        id: templateId,
        name: title,
        templateOwner: currentUserId
      });
    }

    if (templateId) {
      await Promise.all(
        questions.map(
          async ({ title: questionTitle, type, options }, order) => {
            await createTemplatePhase(templateId, {
              title: questionTitle,
              type,
              options,
              order,
              comments: [],
            });
          },
        ),
      );
    }
    successNotification(Success.TEMPLATE_ADDING_SUCCESS);
  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.TEMPLATE_ADDING_FAIL);
  }
};

export const submitFeedback = async (
  feedbackDetails: IAddFeedback,
): Promise<void> => {
  try {
    const currentUserId = getCurrentUserId();
    const feedbackCollection = collection(db, Collections.FEEDBACK);

    await addDoc(feedbackCollection, {
      user: {
        firstName: feedbackDetails?.user?.firstName || '',
        lastName: feedbackDetails?.user?.lastName || '',
        userId: currentUserId,
        email: feedbackDetails?.user?.email || '',
      },
      feedback: feedbackDetails?.feedback || '',
      createdAt: feedbackDetails?.createdAt || new Date()
    });

    successNotification(Success.FEEDBACK_ADDING_SUCCESS);
  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.FEEDBACK_ADDING_FAIL);
  }
};

export const createTemplatePhase = async (
  templateId: string,
  question: IQuestion,
): Promise<void> => {
  const { type, options } = question;
  if (
    type === TemplateFieldTypes.TEXT_CHOISE ||
    type === TemplateFieldTypes.YES_NO_DECISION ||
    type === TemplateFieldTypes.TEXT_AREA
  ) {
    const phases = collection(
      db,
      Collections.TEMPLATES,
      templateId,
      SubCollections.PHASES,
    );

    await addDoc(phases, question);
  }
  if (type === TemplateFieldTypes.IMAGE_CHOISE) {
    const updatedQuestion = { ...question };
    const updatedOptions = [...options!];

    await Promise.all(
      options!.map(async (option, index) => {
        const { file, isAdded } = option;
        if (file && isAdded) {
          const url = await uploadTemplateImage(file, templateId);
          updatedOptions.splice(index, 1, {
            imageSrc: url,
            fileName: file.name,
          });
        }
      }),
    );
    updatedQuestion.options = updatedOptions.filter(
      (option) => !option.isRemoved && option.imageSrc,
    );

    const phases = collection(
      db,
      Collections.TEMPLATES,
      templateId,
      SubCollections.PHASES,
    );

    await addDoc(phases, updatedQuestion);
  }
};

const uploadImage = async (
  storageRef: StorageReference,
  file: File,
): Promise<string> => {
  const uploadTaskSnapshot = await uploadBytes(storageRef, file);
  const downloadURL = await getDownloadURL(uploadTaskSnapshot.ref);

  return downloadURL;
};

export const uploadTemplateImage = async (
  file: File,
  templateId: string,
): Promise<string> => {
  const storageRef = ref(
    storage,
    `${StorageFolders.TEMPLATE_IMAGES}/${templateId}/${file.name}`,
  );
  return uploadImage(storageRef, file);
};

export const uploadProjectImage = async (
  file: File,
  projectId: string,
): Promise<string> => {
  const storageRef = ref(
    storage,
    `${StorageFolders.PROJECT_IMAGE}/${projectId}/${file.name}`,
  );
  return uploadImage(storageRef, file);
};

export const linkProjectToOwner = async (
  projectId: string,
): Promise<void> => {
  try {
    const currentUserId = getCurrentUserId();
    const userRef = doc(db, Collections.USERS, currentUserId);
    await updateDoc(userRef, {
      projectIds: arrayUnion(projectId)
    });

  }
  catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.PROFILE_UPDATING_FAIL);
  }

};

export const updateUserProfile = async (
  userId: string,
  updatedProfile: IUpdateProfile,
): Promise<void> => {
  try {
    const {
      firstName,
      lastName,
      displayName,
      email,
      organization,
      isProfileComplete,
      avatarURL,
      password,
      projectIds
    } =
      updatedProfile;

    const userRef = doc(db, Collections.USERS, userId);

    await updateDoc(userRef, {
      firstName,
      lastName,
      displayName,
      avatarURL,
      email,
      organization,
      isProfileComplete,
      projectIds: projectIds || []
    });

    if (auth?.currentUser?.email && password) {
      const credential = EmailAuthProvider
        .credential(auth.currentUser.email, password);

      reauthenticateWithCredential(auth.currentUser, credential).then(() => {
        if (auth.currentUser)
          // User re-authenticated.
          updatePassword(auth.currentUser, password)
      }).catch((error) => {
        // An error ocurred
        console.log('ERROR', error)
        // ...
      });
    }


    successNotification(Success.PROFILE_UPDATING_SUCCESS);
  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.PROFILE_UPDATING_FAIL);
  }
};

export const updateUserPassword = async (password: string): Promise<void> => {
  if (auth.currentUser) {
    try {
      await updatePassword(auth.currentUser, password)

      successNotification(Success.PROFILE_UPDATING_SUCCESS);
    } catch (error) {
      const e = error as Error;

      errorNotification(e.message);
      errorNotification(Errors.PROFILE_UPDATING_FAIL);
    }
  }

}

// This function will write to temp users collection,
// the cloud function will then update the claims and delete the temp doc
export const changeUserRole = async (
  userId: string,
  updatedProfile: Pick<IUpdateProfile, "role">,
): Promise<void> => {
  try {
    const {
      role } =
      updatedProfile;

    // if (userId) {
      await updateDoc(doc(db, Collections.USERS, userId), {
        role
      });
    // } else {
    //   const tempUserRef = doc(db, Collections.TEMP_USERS, userId);

    //   const docSnap = await getDoc(tempUserRef);
    //   if (!docSnap.exists()) {
    //     await setDoc(tempUserRef, {
    //       role,
    //     });
    //   }
    // }

    successNotification(Success.PROFILE_UPDATING_SUCCESS);
  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.PROFILE_UPDATING_FAIL);
  }
};

export const createCheckoutSession = 
async (priceId: string): Promise<string> => {
  const currentUserId = getCurrentUserId();

  const sessions = collection(
    db,
    Collections.USERS,
    currentUserId,
    SubCollections.CHECKOUT_SESSIONS,
  );

  const checkoutObject = {
    price: priceId,
    success_url: window.location.origin,
    cancel_url: window.location.origin,
  }

  return addDoc(sessions, checkoutObject).then(snap => snap.id)

}

export const updateTemplate = async (
  templateId: string,
  updatedTemplate: ICreateTemplate,
): Promise<void> => {
  try {
    const { title, questions } = updatedTemplate;

    const templateRef = doc(db, Collections.TEMPLATES, templateId);

    await updateDoc(templateRef, {
      name: title,
    });

    if (templateRef.id) {
      if (questions.length) {
        await Promise.all(
          questions.map(async (question) => {
            const { isEdited, isAdded, isRemoved, ...questionData } = question;
            if (isEdited && !isRemoved && !isAdded) {
              await updateTemplatePhase(templateId, questionData);
            }
            if (isRemoved) {
              await deleteTemplatePhase(templateId, questionData);
            }
            if (isAdded) {
              await createTemplatePhase(templateId, questionData);
            }
          }),
        );
      }
    }

    successNotification(Success.TEMPLATE_UPDATING_SUCCESS);
  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.TEMPLATE_UPDATING_FAIL);
  }
};

export const updateTemplatePhase = async (
  templateId: string,
  question: IQuestion,
): Promise<void> => {
  const { id: phaseId, type, options } = question;

  if (
    type === TemplateFieldTypes.TEXT_CHOISE ||
    type === TemplateFieldTypes.YES_NO_DECISION ||
    type === TemplateFieldTypes.TEXT_AREA
  ) {
    const phaseRef = doc(
      db,
      Collections.TEMPLATES,
      templateId,
      SubCollections.PHASES,
      phaseId!,
    );

    await updateDoc(phaseRef, question);
  }

  if (type === TemplateFieldTypes.IMAGE_CHOISE) {
    const updatedQuestion = { ...question };
    const updatedOptions = [...options!];

    await Promise.all(
      options!.map(async (option, index) => {
        const { file, isRemoved, isAdded, fileName, imageSrc } = option;
        if (isAdded && !isRemoved && file) {
          const url = await uploadTemplateImage(file, templateId);
          updatedOptions.splice(index, 1, {
            imageSrc: url,
            fileName: file.name,
          });
        }
        if (isRemoved && fileName) {
          await deleteImage(templateId, fileName);
        }
        if (file && fileName && imageSrc) {
          await deleteImage(templateId, fileName);
          const url = await uploadTemplateImage(file, templateId);
          updatedOptions.splice(index, 1, {
            imageSrc: url,
            fileName: file.name,
          });
        }
      }),
    );

    updatedQuestion.options = updatedOptions
      .filter((option) => !option.isRemoved)
      .map((option) => ({
        ...(option.imageSrc ? { imageSrc: option.imageSrc } : {}),
        ...(option.fileName ? { fileName: option.fileName } : {}),
      }));

    const phaseRef = doc(
      db,
      Collections.TEMPLATES,
      templateId,
      SubCollections.PHASES,
      phaseId!,
    );

    await updateDoc(phaseRef, updatedQuestion);
  }
};

export const createProfile = async (currentUserId: string): Promise<void> => {
  if (currentUserId) {
    const userRef = doc(db, Collections.USERS, currentUserId);

    const docSnap = await getDoc(userRef);
    if (!docSnap.exists()) {
      await setDoc(userRef, {
        uid: currentUserId,
      });
    }
  }
}

export const deleteTemplatePhase = async (
  templateId: string,
  question: IQuestion,
): Promise<void> => {
  if (question.type === TemplateFieldTypes.IMAGE_CHOISE) {
    await Promise.all(
      question.options!.map(async (option) => {
        if (option.fileName) {
          await deleteImage(templateId, option.fileName);
        }
      }),
    );
  }

  await deleteDoc(
    doc(
      db,
      Collections.TEMPLATES,
      templateId,
      SubCollections.PHASES,
      question.id!,
    ),
  );
};

export const deleteImage = async (
  templateId: string,
  fileName: string,
): Promise<void> => {
  const desertRef = ref(
    storage,
    `${StorageFolders.TEMPLATE_IMAGES}/${templateId}/${fileName}`,
  );

  try {
    await deleteObject(desertRef);
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e);
  }
};

export const createPhaseComment = async (
  projectId: string,
  phaseId: string,
  phaseComment: IPhaseComment,
): Promise<string | void> => {
  const phaseComments = collection(
    db,
    Collections.PROJECTS,
    projectId,
    SubCollections.PHASES,
    phaseId,
    SubCollections.PHASE_COMMENTS,
  );

  const phaseCommentRef = await addDoc(phaseComments, {});
  const phaseCommentId = phaseCommentRef.id;

  if (phaseCommentId) {
    const phaseCommentDoc = doc(
      db,
      Collections.PROJECTS,
      projectId,
      SubCollections.PHASES,
      phaseId,
      SubCollections.PHASE_COMMENTS,
      phaseCommentId,
    );
    await setDoc(phaseCommentDoc, {
      email: phaseComment.email,
      message: phaseComment.message,
      time: serverTimestamp(),
      ...(phaseComment.commentedAnswer
        ? { commentedAnswer: phaseComment.commentedAnswer }
        : {}),
      ...(phaseComment.selectedAnswer
        ? { selectedAnswer: phaseComment.selectedAnswer }
        : {}),
      id: phaseCommentId,
    });
    return phaseCommentId;
  }
  return undefined;
};

export const sumbitPhaseAnswer = async (
  projectId: string,
  phaseId: string,
  answer: string,
  nextPhaseId: string,
  submittedPhases: number,
  projectPhase: IProjectPhase,
  phaseCount: number
): Promise<void> => {
  const projectRef = doc(db, Collections.PROJECTS, projectId);

  await updateDoc(projectRef, {
    activePhaseId: nextPhaseId || null,
    submittedPhases,
  });

  const phaseRef = doc(
    db,
    Collections.PROJECTS,
    projectId,
    SubCollections.PHASES,
    phaseId,
  );

  await updateDoc(phaseRef, {
    ownerVote: answer,
  });

  // Get all of the reviewer emails
  // eslint-disable-next-line max-len
  const reviewers = await getCollectionById(`${Collections.PROJECTS}/${projectId}/${SubCollections.PHASE_REVIEWERS}`);
  if (reviewers) {
    const allEmails = reviewers.map(reviewer => reviewer.email) || []
    const { email } = auth.currentUser!;
    if (email) {
      allEmails.push(email);
    }


    // Send invite email
    const mailRef = collection(db, Collections.MAIL);
    await addDoc(mailRef, {
      // from: 'Jetrospects <noreply@jetrospects.com>',
      to: allEmails,
      message: {
        subject: 'A phase has been completed on Jetrospects',
        html: getPhaseCompleteMailTemplate(
          email, projectPhase, submittedPhases, phaseCount)
      }
    });
  }
};

export const sumbitPhaseReviwerAnswer = async (
  projectId: string,
  phaseId: string,
  answer: string,
): Promise<void> => {
  const currentUserId = getCurrentUserId();
  const { email } = auth.currentUser!;
  const phaseRef = doc(
    db,
    Collections.PROJECTS,
    projectId,
    SubCollections.PHASES,
    phaseId,
  );

  await updateDoc(phaseRef, {
    votes: arrayUnion({ userId: currentUserId, answer, userEmail: email }),
  });
};

export const sendSignInLink = async (
  email: string,
  projectId: string,
): Promise<void> => {
  const { protocol, host } = window.location;
  const actionCodeSettings = {
    // URL you want to redirect back to. The domain (www.example.com) for this
    // URL must be in the authorized domains list in the Firebase Console.
    // eslint-disable-next-line max-len
    url: `${protocol}//${host}/log-in?${QUERY_PARAMS.PROJECT_ID}=${projectId}&${QUERY_PARAMS.EMAIL}=${email}`,
    handleCodeInApp: true,
  };
  try {
    await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    successNotification(Success.REVIEWER_IS_ADDED);
  } catch (error) {
    const e = error as Error;

    errorNotification(e.message);
    errorNotification(Errors.REVIEWER_ADDING_FAIL);
  }
};

export const addProjectToUser = async (projectId: string): Promise<void> => {
  const currentUserId = getCurrentUserId();
  const currentUserRef = doc(db, Collections.USERS, currentUserId);
  const currentUserSnap = await getDoc(currentUserRef);

  if (currentUserSnap.exists()) {
    await updateDoc(currentUserRef, {
      projectIds: arrayUnion(projectId),
    });
  } else {
    await setDoc(currentUserRef, {
      projectIds: [projectId],
      role: UserRoles.phaseReviewer,
    });
  }
};
