import { DropResult } from 'react-beautiful-dnd';

import { CREATE_TEMPLETE_ACTIONS } from 'context/modules/createTemplate/actions';
import useCreateTemplateContext from 'context/modules/createTemplate/useCreateTemplateContext';
import { ITemplateField } from 'models/createTemplate';

interface IUseQuestions {
  handleOnDragEnd: (result: DropResult) => void;
  questions: ITemplateField[];
}

interface IUseQuestionsProps {
  questionsList: ITemplateField[];
}

const useQuestions = ({ questionsList }: IUseQuestionsProps): IUseQuestions => {
  const { dispatch } = useCreateTemplateContext();

  let displayIndex: number = -1;
  const questions = questionsList.map((question: ITemplateField) => {
    if (!question.isRemoved) {
      displayIndex += 1;
    }
    return {
      ...question,
      displayIndex,
    };
  });

  const handleOnDragEnd = (result: DropResult): void => {
    const { destination, source } = result;
    if (!destination) return;

    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.SET_QUESTIONS_ORDER,
      payload: { source, destination },
    });
  };

  return { handleOnDragEnd, questions };
};

export default useQuestions;