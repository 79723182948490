// import { TEMP_IMAGES } from 'constants/images';
import { TemplateFieldTypes } from 'models/template';

export const initialState = {
  questionsList: [
    {
      title: 'Which shape do you resonate most with?',
      options: [
        { text: 'Circle', index: 0, letter: 'A' },
        { text: 'Square', index: 1, letter: 'B' },
        { text: 'Triangle', index: 2, letter: 'C' },
      ],
      isEditing: false,
      type: TemplateFieldTypes.TEXT_CHOISE,
      id: '0',
      order: 0,
    },
    // {
    //   text: 'Pick the style “7” you like best.',
    //   options: [
    //     { src: TEMP_IMAGES.IMAGE_CHOISE_1, id: '10' },
    //     { src: TEMP_IMAGES.IMAGE_CHOISE_2, id: '11' },
    //     { src: TEMP_IMAGES.IMAGE_CHOISE_3, id: '12' },
    //     { src: TEMP_IMAGES.IMAGE_CHOISE_4, id: '13' },
    //   ],
    //   isEditing: false,
    //   type: TemplateFieldTypes.IMAGE_CHOISE,
    //   id: '1',
    // },
  ],
};