/* eslint-disable react/no-array-index-key */
import React from 'react';

import { ITemplateFieldOption } from 'models/createTemplate';

import useQuestionOptions from 'components/Template/Questions/Question/useQuestionOptions';

import { PLACEHOLDER_URL } from 'constants/common';
import UploadImage from 'components/Common/UploadImage';

interface IImageChoise {
  isQuestionEdit: boolean;
  options?: ITemplateFieldOption[];
  questionId: string;
}

const ImageChoise: React.FC<IImageChoise> = ({
  options,
  questionId,
  isQuestionEdit,
}) => {
  const { handleRemoveOption, handleAddOption, handleFileSelect } =
    useQuestionOptions(questionId);

  if (options) {
    return (
      <>
        <form className="image-form">
          {options.map((option) => {
            const { imageSrc, letter, index } = option;

            const handleRemove = (): void => {
              handleRemoveOption(index!);
            };

            const handleFileChange = (
              event: React.ChangeEvent<HTMLInputElement>,
            ): void => {
              handleFileSelect(event, index!);
            };

            const src = imageSrc || PLACEHOLDER_URL;

            if (isQuestionEdit) {
              return (
                <UploadImage
                  index={index!}
                  letter={letter!}
                  logoSrc={src || ''}
                  handleFileChange={handleFileChange}
                  handleRemove={handleRemove}
                  alt={option.text!}
                />
              );
            }
            return (
              <label className="radio-container container-img" key={index}>
                <img src={src as string} alt="option" />
                <input type="radio" name="radio" disabled />
                <span className="checkmark">{letter}</span>
              </label>
            );
          })}
        </form>
        {isQuestionEdit && (
          <div className="add-answer-container no-focus-outline">
            <button
              className="add-answer-btn add-img-btn"
              type="button"
              onClick={handleAddOption}
            >
              <i className="fas fa-plus" />
            </button>
            <span>Add Image</span>
          </div>
        )}
      </>
    );
  }
  return null;
};

export default ImageChoise;