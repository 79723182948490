/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { useHistory } from 'react-router-dom'
import React, { useEffect, useState } from 'react'; 
import LoaderWrapper from 'components/Common/LoaderWrapper';
import useAuthorisation from 'hooks/useAuthorisation';
import PageContainer from 'components/Common/PageContainer';
import ContentContainer from 'components/Common/ContentContainer';
import useProfile from 'components/Profile/useProfile';
import { UserRoles } from 'models/roles';
import EmailVerification from './EmailVerification';
import ProfileSetup from './ProfileSetup';
import RoleSetup from './RoleSetup';
import OnboardingComplete from './OnboardingComplete';

type OnboardingSteps =
  | 'loading'
  | 'emailVerification'
  | 'profileSetup'
  | 'roleSetup'
  | 'complete';

const Onboarding: React.FC = () => {
  const [onboardingStep, setOnboardingStep] =
    useState<OnboardingSteps>('loading'); 
  const {
    isLoading,
    refreshAuthState,
    refreshAuthRoleState,
    isUserEmailVerified,
    userRole,
    // subscriptionStatus
  } = useAuthorisation();
  const { profile, isLoading: profileIsLoading, subscriptions } = useProfile();
  const history = useHistory();

  useEffect(() => {
    if (!isUserEmailVerified) {
      refreshAuthState()
    }
  }, [isUserEmailVerified, refreshAuthState]);

  useEffect(() => {
    const subscriptionIsActive =  subscriptions?.some((subscription: { status: string; role: UserRoles; }) => subscription.status === 'active' && subscription.role === UserRoles.projectOwner);

    if ((onboardingStep === 'roleSetup' || onboardingStep === 'loading') && subscriptionIsActive) {
      refreshAuthRoleState()
    }
    else if (onboardingStep === 'roleSetup' && !userRole && !!profile?.role) {
      refreshAuthRoleState()
    }
    else if (onboardingStep === 'roleSetup' && userRole === UserRoles.projectOwner && profile?.role === UserRoles.phaseReviewer && !subscriptionIsActive) {
      refreshAuthRoleState()
    }
    else if (onboardingStep === 'roleSetup' && userRole === UserRoles.phaseReviewer && profile?.role === UserRoles.projectOwner && subscriptionIsActive) {
      refreshAuthRoleState()
    }
  }, [onboardingStep, profile?.isProfileComplete, profile?.role, refreshAuthRoleState, subscriptions, userRole])


  useEffect(() => {
    if (isLoading || profileIsLoading) {
      setOnboardingStep('loading');
    }
    if (!isUserEmailVerified) {
      setOnboardingStep('emailVerification');
    }
    if (isUserEmailVerified && !profile?.email && !profile?.firstName) {
      setOnboardingStep('profileSetup');
    }
    if (isUserEmailVerified && profile?.email && profile?.firstName && !profile?.role) {
      setOnboardingStep('roleSetup');
    }
    if (isUserEmailVerified && profile?.email && profile?.firstName && profile?.role !== userRole) {
      setOnboardingStep('roleSetup');
    }
    if (
      isUserEmailVerified &&
      !profile?.isProfileComplete &&
      !!userRole &&
      profile?.role === userRole &&
      profile?.email
    ) {
      setOnboardingStep('complete');
    }
    if (profile?.isProfileComplete && onboardingStep === 'loading' && userRole === UserRoles.projectOwner && profile?.role === UserRoles.projectOwner){
      setOnboardingStep('complete');
      history.push('/profile')
    }

  }, [isLoading, profileIsLoading, isUserEmailVerified, profile, userRole, onboardingStep, history]);


  return (
    <>
      <PageContainer className="onboarding" hideMenu hideHeader>
        <div className="onboarding__inner">
          <ContentContainer>
            <LoaderWrapper isLoading={isLoading || profileIsLoading || onboardingStep === 'loading'}>
              {onboardingStep === 'emailVerification' && <EmailVerification />}
              {onboardingStep === 'profileSetup' && <ProfileSetup />}
              {onboardingStep === 'roleSetup' && <RoleSetup />}
              {onboardingStep === 'complete' && <OnboardingComplete />}
            </LoaderWrapper>
          </ContentContainer>
        </div>
      </PageContainer>
    </>
  );
};
export default Onboarding;
