import React, { useCallback, useEffect, useState } from 'react';

import useAuthorisation from 'hooks/useAuthorisation';
import useQuery from 'hooks/useQuery';

import { errorNotification } from 'helpers/notifications';
import { QUERY_PARAMS } from 'constants/routes';
import { addProjectToUser } from 'api/mutations';


const INITIAL = {
  email: '',
  password: '',
};

interface ILoginForm
{
  email: string;
  password: string;
}

interface IUseLogin
{
  handleInputChange: (e: React.FormEvent<HTMLInputElement>) => void;
  handleLoginClicked: () => Promise<void>;
  handleGoogleSignupClicked: () => Promise<void>;
  handleFacebookSignupClicked: () => Promise<void>;
  handleTwitterSignupClicked: () => Promise<void>;
  handleGithubSignupClicked: () => Promise<void>;
  handleMicrosoftSignupClicked: () => Promise<void>;
  requestError: string;
  form: ILoginForm;
}

const useLogin = (): IUseLogin =>
{
  const [form, setForm] = useState<ILoginForm>(INITIAL);
  const [requestError, setRequestError] = useState<string>('');
  const {
    signIn,
    signInWithLinkFromEmail,
    signInWithGoogle,
    signInWithFacebook,
    signInWithMicrosoft,
    signInWithTwitter,
    signInWithGithub } = useAuthorisation();
  const query = useQuery();

  const signInWithLinkFromEmailCallback = useCallback(
    async (queryEmail: string, queryProjectId: string) =>
    {
      try
      {
        await signInWithLinkFromEmail(queryEmail)
        await addProjectToUser(queryProjectId)

      } catch (error)
      {
        if (error instanceof Error)
        {
          const errorMessage = error.message;
          errorNotification(errorMessage);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const queryEmail = query.get(QUERY_PARAMS.EMAIL);
  const queryProjectId = query.get(QUERY_PARAMS.PROJECT_ID);

  useEffect(() =>
  {
    const signInWithLink = async (): Promise<void> =>
    {
      if (queryEmail && queryProjectId)
      {
        await signInWithLinkFromEmailCallback(queryEmail, queryProjectId);
      }
    };

    signInWithLink();
  }, [queryEmail, signInWithLinkFromEmailCallback, queryProjectId]);

  const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void =>
  {
    const { value, name } = e.currentTarget;

    setForm({ ...form, [name]: value });
  };

  const handleLoginClicked = async (): Promise<void> =>
  {
    const { email, password } = form;
    try
    {
      await signIn(email, password);
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };
  const handleGoogleSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithGoogle();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleFacebookSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithFacebook();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleTwitterSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithTwitter();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleGithubSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithGithub();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  const handleMicrosoftSignupClicked = async (): Promise<void> =>
  {
    try
    {
      await signInWithMicrosoft();
    } catch (error)
    {
      if (error instanceof Error)
      {
        const errorMessage = error.message;
        setRequestError(errorMessage);
      }
    }
  };

  return {
    handleInputChange,
    handleLoginClicked,
    handleGoogleSignupClicked,
    handleFacebookSignupClicked,
    handleTwitterSignupClicked,
    handleGithubSignupClicked,
    handleMicrosoftSignupClicked,
    requestError,
    form
  };
};

export default useLogin;
