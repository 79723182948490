import classNames from 'classnames';
import { TEMPLATES } from 'constants/routes';
import React from 'react';
import { useHistory } from 'react-router-dom';

interface ITemplate {
  title: string;
  id: string;
  key: string;
  isActive: boolean;
  onClick: (id: string) => void;
}

const Template: React.FC<ITemplate> = ({
  title,
  id,
  isActive,
  onClick,
  key
}) => {
  const history = useHistory();

  const handleEditClick = (): void => {
    history.push(`${TEMPLATES}/${id}`);
  };

  const handleButtonClick = (): void => {
    onClick(id);
  };

  const buttonClassName = classNames({
    'circular-btn': true,
    'circular-btn--active': isActive,
    'circular-btn--black': !isActive,
  });

  return (
    <div className="no-focus-outline" key={key}>
      <button
        type="button"
        className={buttonClassName}
        onClick={handleButtonClick}
      >
        <i
          className="circular-btn__icon fas fa-cog"
          onClick={handleEditClick}
          onKeyDown={handleEditClick}
          role="button"
          aria-label="Edit"
          tabIndex={-1}
        />
        <div>
          <p className="circular-btn__name">{title}</p>
        </div>
      </button>
    </div>
  );
};

export default Template;
