import React from 'react';

import PageContainer from 'components/Common/PageContainer';
import TemplateForm from './TemplateForm';

const CreateTemplateComponent: React.FC = () => (
  <>
    <PageContainer headerTitle="Templates" className="template-page">
      <TemplateForm />
    </PageContainer>
  </>
);

export default CreateTemplateComponent;