import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyBophl1I8w8L3F_QfS8BSVu1Icz94YAmk4',
  authDomain: 'alpha-app.jetrospects.com',
  // authDomain: 'jetrospects-alpha-215b4.firebaseapp.com',
  projectId: 'jetrospects-alpha-215b4',
  storageBucket: 'jetrospects-alpha-215b4.appspot.com',
  messagingSenderId: '374768396498',
  appId: '1:374768396498:web:7597aacc05f8ccf3c9e50b',
  measurementId: 'G-FFEZYPR585',
};
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
// const firebaseConfig = {
//   apiKey: 'AIzaSyAl4nztYRDvstiyz477Pd6eot82B-pgtus',
//   authDomain: 'jetrospects-local.firebaseapp.com',
//   projectId: 'jetrospects-local',
//   storageBucket: 'jetrospects-local.appspot.com',
//   messagingSenderId: '939298379255',
//   appId: '1:939298379255:web:a27ff9d4c1af1be83b0af2',
// };

const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth(firebaseApp);

export const db = getFirestore();
export const storage = getStorage();
export const functions = getFunctions(firebaseApp, "us-east4");

// TODO: uncomment for local dev
// connectFirestoreEmulator(db, 'localhost', 8080);
// connectStorageEmulator(storage, "localhost", 9199);

export default firebaseApp;
