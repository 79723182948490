import { TEMPLATES } from 'constants/routes';
import useAuthorisation from 'hooks/useAuthorisation';
import { UserRoles } from 'models/roles';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import Header from '../Header';
import SkeletonHeader from '../Header/SkeletonHeader';
import FeedbackModal from '../Feedback';
import useFeedback from '../Feedback/useFeedback';

interface IPageContainer {
  children: React.ReactNode;
  className?: string;
  hideMenu?: boolean;
  hideHeader?: boolean;
  isSkeleton?: boolean;
  isMinimized?: boolean;
  headerTitle?: string;
  hasTag?:boolean;
  tagLabel?:string
}

const PageContainer: React.FC<IPageContainer> = ({
  children,
  className,
  hideMenu = false,
  isSkeleton = false,
  isMinimized = false,
  hideHeader = false,
  hasTag = false,
  headerTitle = '',
  tagLabel = '',
}) => {
  const [menuIsMinimized, setMenuIsMinimized] = useState<boolean>(isMinimized);
  const { userRole, isLoading } = useAuthorisation();
  const { modalIsOpen, setModalIsOpen } = useFeedback();
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  useEffect(() => {
    const isMobile = window.innerWidth <= 620; 
      setMenuIsMinimized(isMobile ? false : isMinimized);
  }, [isMinimized, setMenuIsMinimized]);

  return (
    <>
      {!hideHeader && (
        <>
          {isLoading ? (
            <SkeletonHeader
              isMinimized={menuIsMinimized}
              setMenuIsMinimized={setMenuIsMinimized}
            />
          ) : (
            <Header
              title={headerTitle}
              isMinimized={menuIsMinimized}
              setMenuIsMinimized={setMenuIsMinimized}
              hasTag={hasTag}
              tagLabel={tagLabel}
              setIsNavExpanded={setIsNavExpanded}
            />
          )}
        </>
      )}
      <main
        className={`${
          !hideHeader &&
          `${
            !hideMenu && !menuIsMinimized
              ? 'logged-in-container'
              : 'minimized-container'
          }`
        }    main-container main-dashboard-lp light-bg overflow ${
          className || ''
        }`}
      >
        {!hideMenu && (
          <aside
            className={!isNavExpanded ? "side-navigation mobile-expanded" 
            : "side-navigation"}
          >
            {!isSkeleton && (
              <nav className="sidebar__navigation">
                <NavLink to="/home">
                  <i className="fa fa-th-large nav-icon" />
                  {!menuIsMinimized && <span>Dashboard</span>}
                </NavLink>
                {userRole === UserRoles.projectOwner && (
                  <>
                    <NavLink to="/projects">
                      <i className="fa fa-tasks nav-icon" />
                      {!menuIsMinimized && <span>Projects</span>}
                    </NavLink>
                    <NavLink to={TEMPLATES}>
                      <i className="fa fa-file-invoice nav-icon" />
                      {!menuIsMinimized && <span>Templates</span>}
                    </NavLink>
                  </>
                )}
                <NavLink to="/profile">
                  <i className="fa fa-user nav-icon" />
                  {!menuIsMinimized && <span>Profile</span>}
                </NavLink>

                <button
                  type="button"
                  className={
                    !menuIsMinimized ? 'feedback-btn' : 'feedback-btn minimized'
                  }
                  onClick={() => {
                    setIsNavExpanded(false)
                    setModalIsOpen(true)}}
                >
                  {!menuIsMinimized ? 'Give Feedback' : 'F'}
                </button>
              </nav>
            )}
          </aside>
        )}
        <div className="page-container">{children}</div>
      </main>
      <FeedbackModal isOpen={modalIsOpen} setIsOpen={setModalIsOpen} />
    </>
  );
};

export default PageContainer;
