import { ITemplatePhaseApi } from 'models/createTemplate';
import { TemplateFieldTypes } from 'models/template';
import { DraggableLocation } from 'react-beautiful-dnd';

export enum CREATE_TEMPLETE_ACTIONS {
  SET_QUESTION_IS_EDITING = 'SET_QUESTION_IS_EDITING',
  SET_QUESTION_TEXT = 'SET_QUESTION_TEXT',
  REMOVE_QUESTION = 'REMOVE_QUESTION',
  REMOVE_QUESTION_OPTION = 'REMOVE_QUESTION_OPTION',
  ADD_QUESTION_OPTION = 'ADD_QUESTION_OPTION',
  SET_QUESTION_OPTION_TEXT = 'SET_QUESTION_OPTION_TEXT',
  ADD_QUESTION = 'ADD_QUESTION',
  SET_QUESTIONS_ORDER = 'SET_QUESTIONS_ORDER',
  SET_QUESTION_OPTION_IMAGE = 'SET_QUESTION_OPTION_IMAGE',
  SET_QUESTION_INITIAL = 'SET_QUESTION_INITIAL',
}

export interface IAddQuestionPayload {
  type: TemplateFieldTypes;
}

export interface ISetQuestionIsEditingPayload {
  id: string;
  isEditing: boolean;
}

export interface ISetQuestionTextPayload {
  id: string;
  title: string;
}

export interface IRemoveQuestionPayload {
  id: string;
}

export interface IRemoveQuestionOptionPayload {
  id: string;
  optionIndex: number;
}

export interface ISetQuestionOptionTextPayload extends ISetQuestionTextPayload {
  optionIndex: number;
}

export interface ISetQuestionsOrderPayload {
  source: DraggableLocation;
  destination: DraggableLocation;
}

export interface ISetQuestionOptionImagePayload {
  id: string;
  optionIndex: number;
  imageSrc: string | ArrayBuffer;
  file: File;
}

export interface ISetQuestionInitialPayload {
  phases: ITemplatePhaseApi[];
}

export interface IAddQuestion {
  type: CREATE_TEMPLETE_ACTIONS.ADD_QUESTION;
  payload: IAddQuestionPayload;
}

export interface ISetQuestionIsEditing {
  type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_IS_EDITING;
  payload: ISetQuestionIsEditingPayload;
}

export interface ISetQuestionText {
  type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_TEXT;
  payload: ISetQuestionTextPayload;
}

export interface IRemoveQuestion {
  type: CREATE_TEMPLETE_ACTIONS.REMOVE_QUESTION;
  payload: IRemoveQuestionPayload;
}

export interface IRemoveQuestionOption {
  type: CREATE_TEMPLETE_ACTIONS.REMOVE_QUESTION_OPTION;
  payload: IRemoveQuestionOptionPayload;
}

export interface IAddQuestionOption {
  type: CREATE_TEMPLETE_ACTIONS.ADD_QUESTION_OPTION;
  payload: IRemoveQuestionPayload;
}

export interface ISetQuestionOptionText {
  type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_OPTION_TEXT;
  payload: ISetQuestionOptionTextPayload;
}

export interface ISetQuestionsOrder {
  type: CREATE_TEMPLETE_ACTIONS.SET_QUESTIONS_ORDER;
  payload: ISetQuestionsOrderPayload;
}

export interface ISetQuestionOptionImage {
  type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_OPTION_IMAGE;
  payload: ISetQuestionOptionImagePayload;
}

export interface ISetQuestionInitial {
  type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_INITIAL;
  payload: ISetQuestionInitialPayload;
}

export type TCreateTemplateActions =
  | IAddQuestion
  | ISetQuestionIsEditing
  | ISetQuestionText
  | IRemoveQuestion
  | IRemoveQuestionOption
  | IAddQuestionOption
  | ISetQuestionOptionText
  | ISetQuestionsOrder
  | ISetQuestionOptionImage
  | ISetQuestionInitial;