import React from 'react';
import { useHistory } from 'react-router-dom';

import Button from 'components/Common/Button';
import { DASHBOARD } from 'constants/routes';
import useProfile from 'components/Profile/useProfile';

const OnboardingComplete: React.FC = () => {
  const history = useHistory();
  const { handleAssignTempProjectIds } = useProfile();

  return (
    <div className="onboarding__center-container">
      <i className="fa far fa-paper-plane onboarding__icon" />
      <h1>Setup Complete</h1>
      <p>You can now start using Jetrospects.</p>
      <div className="onboarding__actions-container">

        <Button
          onClick={() => {
            handleAssignTempProjectIds();
            history.push(DASHBOARD);
          }}
        >
          View your dashboard
        </Button>
      </div>
    </div>
  );
};

export default OnboardingComplete;
