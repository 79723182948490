/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect, useState } from 'react';
import UploadImage from 'components/Common/UploadImage';
import useProfile from 'components/Profile/useProfile';
import Button from 'components/Common/Button';
import useAuthorisation from 'hooks/useAuthorisation';
import { UserRoles } from 'models/roles';
import ContentContainer from 'components/Common/ContentContainer';
import LoaderWrapper from 'components/Common/LoaderWrapper';
import useProducts from '../useProducts';

const checkSectionStatus = (
  selectedString: string,
  sectionId: string,
): string => {
  let status = '';
  if (selectedString === sectionId) {
    status = 'active';
  }
  return status;
};

enum ProfileFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  password = 'password',
  organization = 'organization',
  confirmPassword = 'confirmPassword',
}

const ProfileForm: React.FC = () => {
  const {
    handleAvatarSelect,
    handleSubmitClicked,
    handleInputChange,
    setForm,
    form,
    profile,
    updateUserRole,
    errors,
    checkoutSession,
    handlePasswordUpdate,
    createPortalLink,
    isLoading,
  } = useProfile();
  const { hasPasswordLoginMethod, userRole } = useAuthorisation();
  const [selectedSection, setSelectedSection] = useState('personalDetails');
  const isMobile = window.innerWidth <= 800;

  const { products, areProductsLoading } = useProducts();

  useEffect(() => {
    if (checkoutSession && checkoutSession.url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(checkoutSession.url);
    }
  }, [checkoutSession]);

  const PROFILE_FORM_SECTIONS = [
    {
      id: 'personalDetails',
      status: '',
      title: 'Personal Details',
      description: 'Your personal and organization profile details',
      onClick: () => setSelectedSection('personalDetails'),
      component: (
        <ContentContainer isHidden={selectedSection !== 'personalDetails'}>
          <h3>Setup Personal Details</h3>
          <div className="form-field-container">
            <label>First Name</label>
            <input
              type="text"
              className="profile__input"
              placeholder="First Name"
              onChange={handleInputChange}
              name={ProfileFields.firstName}
              value={form.firstName}
            />
          </div>
          <div className="form-field-container">
            <label>Last Name</label>
            <input
              type="text"
              className="profile__input"
              placeholder="Last Name"
              onChange={handleInputChange}
              name={ProfileFields.lastName}
              value={form.lastName}
            />
          </div>
          <div className="form-field-container">
            <label>Organization</label>
            <input
              type="text"
              className="profile__input"
              placeholder="Organization"
              onChange={handleInputChange}
              name={ProfileFields.organization}
              value={form.organization}
            />
          </div>
          <div className="form-field-container">
            <label>Avatar</label>
            <UploadImage
              index={1}
              logoSrc={form?.avatarURL}
              handleFileChange={handleAvatarSelect}
              handleRemove={() => handleAvatarSelect(undefined)}
              alt="user-avatar"
            />
          </div>
          <Button
            onClick={() => {
              handleSubmitClicked();
            }}
          >
            Save
          </Button>
        </ContentContainer>
      ),
    },
    {
      id: 'password',
      status: '',
      title: 'Your Password',
      description: 'Update you account password',
      onClick: () => setSelectedSection('password'),
      isHidden: !hasPasswordLoginMethod,
      component: (
        <ContentContainer isHidden={selectedSection !== 'password'}>
          <h3>Manage Your Password</h3>
          <div className="form-field-container">
            <label htmlFor="password">Password</label>
            <input
              placeholder="********"
              type="password"
              onChange={handleInputChange}
              name={ProfileFields.password}
              value={form.password}
            />
            <span className="form-field-error">{errors.password}</span>
          </div>
          <div className="form-field-container">
            <label htmlFor="confirmPassword">Confirm your password</label>
            <input
              placeholder="********"
              type="password"
              onChange={handleInputChange}
              name={ProfileFields.confirmPassword}
              value={form.confirmPassword}
            />
            <span className="form-field-error">{errors.confirmPassword}</span>
          </div>

          <Button
            isDisabled={!!errors.password}
            onClick={() => {
              handlePasswordUpdate();
              setSelectedSection('phaseReviewers');
            }}
          >
            Update Password
          </Button>
        </ContentContainer>
      ),
    },
    {
      id: 'userRole',
      status: '',
      title: 'Account Settings',
      description: 'Manage your account',
      onClick: () => setSelectedSection('userRole'),
      component: (
        <ContentContainer isHidden={selectedSection !== 'userRole'}>
          <h3>Update Account Type</h3>
          <div className="account-type-container">
            {userRole === UserRoles.projectOwner ? (
              <>
              <p>Here you can manage your account.</p>
              <p>Current Role: Project Owner</p>
              <p>Capabilities:</p>

              <ul>
                  <li>Create Project Templates and Questions</li>
                  <li>Create Projects and Invite Reviewers</li>
                  <li>Manage Project Phases</li>
                  <li>Review Projects</li>
                </ul>
              </>
            ) : (
              <>
              <p>Current Role: Reviewer</p>
                <p>
                  Here you can change your account type to 
                  Project Owner  and add project creation
                  capabilities.
                </p>
          
                <ul>
                  <li>Create Project Templates and Questions</li>
                  <li>Create Projects and Invite Reviewers</li>
                  <li>Manage Project Phases</li>
                  <li>Review Projects</li>
                </ul>
                <LoaderWrapper isLoading={areProductsLoading || isLoading}>
                  {!!products &&
                    products.map((product) => {
                      const price = product?.prices[2]?.unit_amount / 100 || 0;
                      return (
                        <div key={product.name}>
                          {!!price && <p>${price} per month</p>}
                          <Button
                            isDisabled={isLoading}
                            onClick={() =>
                              updateUserRole(UserRoles.projectOwner)
                            }
                          >
                            Upgrade to {product.name}
                          </Button>
                        </div>
                      );
                    })}
                  {!!checkoutSession?.error?.message && (
                    <p>
                      {checkoutSession?.error?.message ||
                        'An error has occurred'}
                    </p>
                  )}
                </LoaderWrapper>
              </>
            )}
          
          </div>

            {userRole === UserRoles.projectOwner && (
              <Button styleType="secondary" 
              isLoading={isLoading}
              onClick={() => createPortalLink()}>
                Subscription Settings
              </Button>
            )}

        </ContentContainer>
      ),
    },
  ];

  useEffect(() => {
    if (profile) {
      const {
        firstName,
        lastName,
        organization,
        avatarURL,
        isProfileComplete,
        email,
      } = profile;
      setForm((prev) => ({
        ...prev,
        firstName,
        lastName,
        organization,
        avatarURL,
        isProfileComplete,
        email,
      }));
    }
  }, [profile, setForm]);

  return (
    <ContentContainer isFullHeight className="create-project-form-container">
      <div className="create-project-selector-container">
        {isMobile ? (
          <div className="form-field-container">
            <label>Profile Settings</label>
            <select
              className="options"
              value={selectedSection}
              onChange={(e) => setSelectedSection(e.target.value)}
            >
              {PROFILE_FORM_SECTIONS.map((section) =>
                !section?.isHidden ? (
                  <option key={section.id} 
                  value={section.id}>{section.title}</option>
                ) : null,
              )}
            </select>
          </div>
        ) : (
          <div className="create-project-selections">
            {PROFILE_FORM_SECTIONS.map((section) =>
              !section?.isHidden ? (
                <ContentContainer
                  className={checkSectionStatus(selectedSection, section.id)}
                  onClick={section.onClick}
                  key={section.id}
                >
                  <h4>{section.title}</h4>
                  <span>{section.description}</span>
                </ContentContainer>
              ) : null,
            )}
          </div>
        )}
      </div>
      <form className="create-project-form">
        {PROFILE_FORM_SECTIONS.map((section) => section.component)}
      </form>
    </ContentContainer>
  );
};

export default ProfileForm;
