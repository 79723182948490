/* eslint-disable no-console */
import React, {
  KeyboardEvent,
  MouseEvent,
  useState,
  useCallback,
  useEffect,
} from 'react';
import { useHistory, useParams } from 'react-router-dom';
import usePhaseReviewer from 'components/Common/PhaseReviewers/usePhaseReviewer';
import { IPhaseComment } from 'models/phaseReview';
import
{
  IModalOptions,
  IProjectPhase,
  IVote,
  QuestionOptionsDisplay,
} from 'models/questions';
import { IPhaseReviewer } from 'models/addProject';
import { queries } from 'api';
import
{
  createPhaseReviewer,
  sumbitPhaseAnswer,
  sumbitPhaseReviwerAnswer,
} from 'api/mutations';
import useFetchInitialData from 'hooks/useFetchInitialData';
import { Errors, Success } from 'constants/notifications';
import { DASHBOARD, PROJECTS } from 'constants/routes';
import { TemplateFieldTypes } from 'models/template';
import { UserRoles } from 'models/roles';
import useAuthorisation from 'hooks/useAuthorisation';
import { errorNotification, successNotification } from 'helpers/notifications';
import { getCurrentUserId } from 'helpers/firebase';

interface IUseProjectPhase
{
  isDisplayModeGrid: boolean;
  selectedAnswer?: string;
  modalOptions: IModalOptions;
  commentAnswer?: string;
  isLoading: boolean;
  projectPhase: IProjectPhase;
  phaseReviewers?: IPhaseReviewer[];
  reviewerEmail: string;
  showAddReviewer: boolean;
  phaseComments: IPhaseComment[];
  textarea: string;
  isSubmitButtonActive: boolean;
  isTextarea: boolean;
  isLastPhase: boolean;
  logoSrc?: string;
  answerSubmitted: boolean;
  areAllPhasesSubmitted: boolean;
  currentPhaseIndex: number;
  phaseVotes?: IVote[];
  handleTextareaChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  handleReviewerEmailInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleAnswerChange: (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLDivElement>,
  ) => void;
  handleDisplayModeChange: (event: MouseEvent<HTMLElement>) => void;
  setModalProperties: (isOpen: boolean, displayedValue?: string) => void;
  handleCommentClick: (answer: string) => void;
  handleAddReviewerKeyDown: () => void;
  handleAddReviewerClick: () => void;
  handleAnswerSubmitted: () => void;
  handlePreviousPhaseClicked: () => void;
  isPhaseReviewer: boolean;
  phasesCount: number;
}

interface IParams
{
  projectId: string;
  phaseId: string;
}

const useProjectPhase = (): IUseProjectPhase =>
{
  let areAllPhasesSubmitted = false
  const [selectedAnswer, setSelectedAnswer] = useState<string>();
  const [modalOptions, setModalOptions] = useState<IModalOptions>({
    isOpen: false,
  });
  const [displayMode, setDisplayMode] = useState<QuestionOptionsDisplay>(
    QuestionOptionsDisplay.GRID,
  );
  const [commentAnswer, setCommentAnswer] = useState<string>();
  const [textarea, setTextarea] = useState<string>('');
  const { projectId, phaseId } = useParams<IParams>();
  const [phaseReviewers, setPhaseReviewers] = useState<IPhaseReviewer[]>();
  const [phasesCount, setPhasesCount] = useState<number>(0)
  const {
    reviewerEmail,
    showAddReviewer,
    addReviewerClick,
    handleReviewerEmailInput,
  } = usePhaseReviewer();
  const { userRole, currentUser } = useAuthorisation();
  const history = useHistory();

  const currentUserId = getCurrentUserId();

  const fetchProject = useCallback(async () =>
  {
    const projectData = await queries.getProjectPhase(projectId, phaseId);
    return projectData;
  }, [projectId, phaseId]);

  const { isLoading, result } = useFetchInitialData({
    request: fetchProject,
    error: Errors.PROJECT_FETCHING_FAIL,
  });

  const projectPhase = result as IProjectPhase;
  const {
    phasesOrder,
    phaseReviewers: phaseReviewersInitial,
    phaseComments,
    phase,
    logoSrc,
    activePhaseId,
    projectOwner
  } = {
    ...projectPhase,
  };

  useEffect(() => {
    if (phasesOrder) {
      setPhasesCount(phasesOrder.length)
    }
  }, [phasesOrder])
  

  const isPhaseReviewer = userRole === UserRoles.phaseReviewer 
  || (userRole === UserRoles.projectOwner && projectOwner !== currentUserId )
 ;

  const { type, ownerVote, votes: phaseVotes } = { ...phase };
  const isTextarea = type === TemplateFieldTypes.TEXT_AREA;
  const userVote = phaseVotes?.find(
    (vote) => vote?.userEmail === currentUser!.email,
  );
  const userVoteAnswer = userVote?.answer || '';
  const answerSubmitted = Boolean(ownerVote) || Boolean(userVote);

   areAllPhasesSubmitted = !activePhaseId;

  useEffect(() =>
  {
    if (answerSubmitted)
    {
      if (isTextarea)
      {
        setTextarea(isPhaseReviewer ? userVoteAnswer : ownerVote!);
      } else
      {
        setSelectedAnswer(isPhaseReviewer ? userVoteAnswer : ownerVote);
      }
    }
  }, [
    answerSubmitted,
    ownerVote,
    isTextarea,
    phaseId,
    projectId,
    isPhaseReviewer,
    userVoteAnswer,
  ]);

  useEffect(() =>
  {
    setPhaseReviewers(phaseReviewersInitial);
  }, [phaseReviewersInitial]);

  const resetSelectedAnswer = (): void =>
  {
    setSelectedAnswer(undefined);
    setCommentAnswer(undefined);
  };

  useEffect(() =>
  {
    if (!answerSubmitted)
    {
      resetSelectedAnswer();
    }
  }, [phaseId, answerSubmitted]);

  const isDisplayModeGrid = displayMode === QuestionOptionsDisplay.GRID;

  const handleDisplayModeChange = (event: MouseEvent<HTMLElement>): void =>
  {
    const { id } = event.currentTarget;
    setDisplayMode(id as QuestionOptionsDisplay);
  };

  const handleAnswerChange = (
    event: MouseEvent<HTMLElement> | KeyboardEvent<HTMLDivElement>,
  ): void =>
  {
    if (!answerSubmitted)
    {
      const currentElement = event.currentTarget;
      const answer = currentElement.getAttribute('data-attr');
      if (answer !== selectedAnswer)
      {
        setSelectedAnswer(answer as string);
      } else
      {
        resetSelectedAnswer();
      }
    }
  };

  const handleTextareaChange = (
    event: React.FormEvent<HTMLTextAreaElement>,
  ): void =>
  {
    const { value } = event.currentTarget;
    setTextarea(value);
  };

  const handleCommentClick = (answer: string): void =>
  {
    if (commentAnswer !== answer )
    {
      setCommentAnswer(answer);
    } else
    {
      setCommentAnswer('');
    }
  };

  const setModalProperties = (
    isOpen: boolean,
    displayedValue?: string,
  ): void =>
  {
    setModalOptions({ isOpen, displayedValue });
  };

  const addPhaseReviewer = async (): Promise<void> =>
  {
    const newReviewer = {
      id: phaseReviewers?.length ? `${phaseReviewers.length - 1}` : '0',
      email: reviewerEmail,
    };
    await createPhaseReviewer(projectId, newReviewer);
    if (phaseReviewers)
    {
      setPhaseReviewers([...phaseReviewers, newReviewer!]);
    }
  };

  const handleAddReviewerClick = (): void =>
  {
    addReviewerClick(addPhaseReviewer);
  };

  const handleAddReviewerKeyDown = (): void =>
  {
      addReviewerClick(addPhaseReviewer);
  };

  const isSubmitButtonActiveForOwner =
   phaseVotes?.length === phaseReviewers?.length 
  && ( textarea?.length > 0 || Boolean(selectedAnswer))


  const isSubmitButtonActive = !isPhaseReviewer ? 
  isSubmitButtonActiveForOwner
  : textarea?.length > 0 || Boolean(selectedAnswer);
  const currentPhaseIndex =
    phasesOrder?.findIndex((currentPhase) => currentPhase === phaseId) || 0;
  const isLastPhase = phasesOrder
    ? currentPhaseIndex === phasesOrder.length - 1
    : false;

  const handleAnswerSubmitted = async (): Promise<void> =>
  {
    const nextPhaseIndex = currentPhaseIndex + 1;
    const nextPhaseId = phasesOrder[nextPhaseIndex];
    const nextPhaseLink = isLastPhase
      ? DASHBOARD
      : `${PROJECTS}/${projectId}/phase/${nextPhaseId}`;
    if (isSubmitButtonActive)
    {
      if (isPhaseReviewer)
      {
        if (!answerSubmitted)
        {
          try
          {
            await sumbitPhaseReviwerAnswer(
              projectId,
              phaseId,
              selectedAnswer || textarea,
            );
            successNotification(Success.PHASE_VOTE_ADDED);
            history.push(DASHBOARD);
          } catch (error)
          {
            errorNotification(Errors.PHASE_VOTE_ADDING_FAIL);
          }
        } else
        {
          history.push(nextPhaseLink);
        }
      } else
      {
        if (!answerSubmitted)
        {
          const phaseCount = phasesOrder.length
          
          await sumbitPhaseAnswer(
            projectId,
            phaseId,
            selectedAnswer || textarea,
            nextPhaseId,
            nextPhaseIndex,
            projectPhase,
            phaseCount
          );
          successNotification(Success.PHASE_VOTE_ADDED);
        }

        history.push(nextPhaseLink);
      }
    }
  };

  const handlePreviousPhaseClicked = async (): Promise<void> =>
  {
    const prevPhaseIndex = currentPhaseIndex - 1;
    const prevPhaseId = phasesOrder[prevPhaseIndex];
    const prevPhaseLink =`${PROJECTS}/${projectId}/phase/${prevPhaseId}`;
    history.push(prevPhaseLink);
  };

  return {
    isDisplayModeGrid,
    selectedAnswer,
    modalOptions,
    commentAnswer,
    isLoading,
    projectPhase,
    phaseReviewers,
    reviewerEmail,
    showAddReviewer,
    phaseComments,
    textarea,
    isTextarea,
    isLastPhase,
    isSubmitButtonActive,
    logoSrc,
    answerSubmitted,
    phaseVotes,
    currentPhaseIndex,
    areAllPhasesSubmitted,
    handleTextareaChange,
    handleAnswerSubmitted,
    handleReviewerEmailInput,
    handleAddReviewerClick,
    handleAddReviewerKeyDown,
    handleDisplayModeChange,
    handleAnswerChange,
    setModalProperties,
    handleCommentClick,
    handlePreviousPhaseClicked,
    isPhaseReviewer,
    phasesCount
  };
};

export default useProjectPhase;
