/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import React, { useEffect } from 'react'; // { useEffect } // { useEffect }

import LoaderWrapper from 'components/Common/LoaderWrapper';
import useAuthorisation from 'hooks/useAuthorisation';
import Button from 'components/Common/Button';
import useProfile from 'components/Profile/useProfile';

enum ProfileFields {
  firstName = 'firstName',
  lastName = 'lastName',
  email = 'email',
  organization = 'organization',
}

const ProfileSetup: React.FC = () => {
  const { isLoading, currentUser } = useAuthorisation();
  const {
    form,
    handleSubmitClicked,
    handleInputChange,
    setForm,
    isFormValid,
  } = useProfile();

  useEffect(() => {
    if (currentUser) {
      const { email, photoURL } = currentUser;
      setForm((prev) => ({
        ...prev,
        email: email || '',
        avatarURL: photoURL || '',
      }));
    }
  }, [currentUser, setForm]);

  return (
    <div className="onboarding__center-container">
      <LoaderWrapper isLoading={isLoading}>
        <h1>Welcome! First things first...</h1>
        <p>You can always change them later.</p>
        <div className="onboarding__form-container">
          <div className="form-fields-inline-container">
            <div className="form-field-container">
              <label htmlFor="firstName">First Name</label>
              <input
                placeholder="Steve"
                type="text"
                onChange={handleInputChange}
                name={ProfileFields.firstName}
                value={form.firstName}
              />
            </div>
            <div className="form-field-container">
              <label htmlFor="lastName">Last Name</label>
              <input
                placeholder="Jobs"
                type="text"
                onChange={handleInputChange}
                name={ProfileFields.lastName}
                value={form.lastName}
              />
            </div>
          </div>
          <div className="form-field-container">
            <label htmlFor="email">Email</label>
            <input
              placeholder="example@domain.com"
              type="email"
              onChange={handleInputChange}
              name={ProfileFields.email}
              value={form.email}
            />
          </div>
          <div className="form-field-container">
            <label htmlFor="organization">Organization</label>
            <input
              placeholder="Apple"
              type="text"
              onChange={handleInputChange}
              name={ProfileFields.organization}
              value={form.organization}
            />
          </div>
          <div className="form-actions-container">
            <Button isDisabled={!isFormValid} onClick={handleSubmitClicked}>
              Create your profile
            </Button>
          </div>
        </div>

        {/* <div className="onboarding__actions-container">
          
          <Button styleType="tertiary" onClick={() => history.push('/')}>
            Back to home
          </Button>
        </div> */}
      </LoaderWrapper>
    </div>
  );
};

export default ProfileSetup;
