/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import classNames from 'classnames';

import useQuestion from 'components/Template/Questions/Question/useQuestion';
import TextChoise from 'components/Template/Questions/Question/TextChoise';
import ImageChoise from 'components/Template/Questions/Question/ImageChoise';

import { ITemplateField } from 'models/createTemplate';

interface IQuestion {
  question: ITemplateField;
  index: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
}

const Question: React.FC<IQuestion> = ({
  question,
  index,
  dragHandleProps,
}) => {
  const {
    isQuestionEdit,
    isTextChoiseQuestion,
    isImageChoiseQuestion,
    isYesNoDecision,
    isTextArea,
    label,
    questionText,
    questionOptions,
    handleEditClicked,
    handleQuestionChanged,
    handleRemoveQuestion,
  } = useQuestion(question);

  const iconClassName = classNames({
    fas: true,
    'fa-check': isTextChoiseQuestion,
    'fa-image': isImageChoiseQuestion,
    'fa-adjust': isYesNoDecision,
    'fa-pen-square': isTextArea,
  });

  return (
    <div
      id="template-options"
      className="template-options"
    >
      <div className="template-options__panel accordion">
        <input
          type="checkbox"
          name="panel"
          id={`template-question-${index}`}
          className="template-options__toggle-accordion"
          defaultChecked
        />
        <label
          htmlFor={`template-question-${index}`}
          className="accordion__label template-options__label
          no-focus-outline"
        >
          <p className="template-options__number">{index + 1}</p>
          {isQuestionEdit ? (
            <input
              type="text"
              className="template-options__input"
              placeholder="Type your question..."
              value={questionText}
              onChange={handleQuestionChanged}
            />
          ) : (
            <p className="template-options__question">{questionText}</p>
          )}
          <i
            className="template-options__chevron-right fas
          fa-chevron-right"
          />
          <button
            type="button"
            className="edit-btn template-options__question-btn"
            onClick={handleEditClicked}
          >
            Edit
            <i className="fas fa-cog" />
          </button>
          <button
            type="button"
            className="delete-btn template-options__question-btn"
            onClick={handleRemoveQuestion}
          >
            Delete
            <i className="fas fa-trash" />
          </button>
          <span className="drag-panel-btn" {...dragHandleProps}>
            <i className="fas fa-bars" />
          </span>
          <span className="chevron-down-icon">
            <i className="fas fa-chevron-down" />
          </span>
        </label>
        <div
          className="accordion__content template-options__accordion
        template-questions-spacing text-option"
        >
          <div className="template-options__type text-center">
            <i className={iconClassName} />
            <p>{label}</p>
          </div>
          <div className="template-options__list">
            {(isTextChoiseQuestion || isYesNoDecision) && (
              <TextChoise
                isQuestionEdit={isQuestionEdit}
                options={questionOptions}
                questionId={question.id}
                editableOptions={isTextChoiseQuestion}
              />
            )}
            {isImageChoiseQuestion && (
              <ImageChoise
                isQuestionEdit={isQuestionEdit}
                options={questionOptions}
                questionId={question.id}
              />
            )}
          </div>
          <span className="drag-panel-btn" {...dragHandleProps}>
            <i className="fas fa-bars" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Question;