/* eslint-disable @typescript-eslint/no-use-before-define */
// /* eslint-disable no-return-await */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
import { db, functions } from 'api/config';
import { Collections, SubCollections } from 'api/constants';
import { ICONS } from 'constants/images';

import {
  collection,
  doc,
  DocumentData,
  getDoc,
  getDocs,
  orderBy,
  query,
  QueryConstraint,
  where,
  onSnapshot
} from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { getCurrentUserId } from 'helpers/firebase';
import { errorNotification } from 'helpers/notifications';
import { IAddProject, IPhaseReviewer } from 'models/addProject';

import { ITemplatePhaseApi } from 'models/createTemplate';
import { IPhaseComment } from 'models/phaseReview';
// import { IProduct } from 'models/products';
import { IPhaseQuestion, IProjectPhase } from 'models/questions';
import { IUser } from 'models/roles';

// Listen to a document
export const getDocument = (
  docCollection: string, id: string, setStateFunc: any): any => {
  const unsubscribe = onSnapshot(
    doc(db, docCollection, id),
    (snapshot) => {
      const data = snapshot.data()
      setStateFunc(data)
    },
    (error) => {
      if (error instanceof Error) {
        errorNotification(error.message);
      }
    });

    return () => unsubscribe();
}

export const getAssignedProjects =
  (projectIds: string[], setStateFunc: any) => {
    const q = query(collection(db, "projects"), where("id", "in", projectIds));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const projects: IAddProject[] = [];
      snapshot.forEach(async (proj) => {
        const project = proj.data()
        projects.push(project as IAddProject)

      });
      setStateFunc(projects)
    });

    if (!projectIds) {
      unsubscribe()
    }
  }

export const getSubscriptions =
  (currentUserId: string, setStateFunc: any) => {
    const q = query(
      collection(db, `users/${currentUserId}/subscriptions`),
      where("status", "==", "active"));

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const subscriptions: any = [];
      snapshot.forEach(async (sub) => {
        const subscription = sub.data()
        subscriptions.push(subscription)

      });
      setStateFunc(subscriptions)
    });

     return () => unsubscribe()
  }


export const getStripePortalLink = () => {
  const getUrl =
    httpsCallable(functions, 'ext-firestore-stripe-payments-createPortalLink');
  getUrl({
    returnUrl: window.location.origin,
  }).then((result: any) => {
    // Read result of the Cloud Function.
    const { url } = result.data;
    window.location.assign(url);
  })
}

export const getTempAssignedProjectIds = async (email: string) => {
  let projectIds: string[] = []
  const users = await getCollectionById(Collections.TEMP_USERS, [
    where('email', '==', email),
  ]);

  if (users) {
    users.forEach(user => {
      if (user.projectIds)
        projectIds = projectIds.concat(user.projectIds)
    })
  }

  return projectIds
}

// Check if a user already exists
export const findUserByEmail = async (email: string | undefined)
  : Promise<IUser[]> => {
  const users = await getCollectionById(Collections.USERS, [
    where('email', '==', email),
  ]);
  return users as IUser[];
};



export const getProjectById = async (id: string) => {
  const docRef = doc(db, `${Collections.PROJECTS}/${id}`);

  try {
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      return docSnap.data();
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.log(error);
  }

  return null;
};

interface ITemplate {
  name: string;
  phases: ITemplatePhaseApi[];
  id?: string;
  uid?: string;
}

export const getTemplateById = async (
  templateId: string,
): Promise<ITemplate | null> => {
  const docRef = doc(
    db,
    // eslint-disable-next-line max-len
    `${Collections.TEMPLATES}/${templateId}`,
  );

  try {
    const docSnap = await getDoc(docRef);
    const phases = await getTemplatePhases(templateId);
    if (docSnap.exists()) {
      return { ...(docSnap.data() as ITemplate), phases };
    }
  } catch (error) {
    if (error instanceof Error) {
      errorNotification(error.message);
    }
  }
  return null;
};

export const getCollectionById = async (
  path: string,
  queryConstraints?: QueryConstraint[],
) => {
  const collectionRef = collection(db, path);
  let queryWithConstraints;
  if (queryConstraints) {
    queryWithConstraints = query(collectionRef, ...queryConstraints);
  }

  const querySnapshot = await getDocs(queryWithConstraints || collectionRef);
  const collectionArray: Array<DocumentData> = [];

  if (querySnapshot) {
    querySnapshot.forEach(async (d) => {
      collectionArray.push({ ...d.data(), id: d.id });
    });
  }

  return collectionArray;
};

export const getProjects = async () => {
  const currentUserId = getCurrentUserId();
  const currentUserPath = `${Collections.USERS}/${currentUserId}`;
  const currentUserRef = doc(db, currentUserPath);
  const currentUserSnap = await getDoc(currentUserRef);
  if (currentUserSnap.exists()) {
    const { projectIds } = currentUserSnap.data() as IUser;
    return projectIds?.length > 0 ? getCollectionById(Collections.PROJECTS, [
      where('id', 'in', projectIds),
    ]) : [];
  }
  return []
};

export const getProducts = async (setStateFunc: any, setLoadingFunc: any) => {
  setLoadingFunc(true)
  const q = query(collection(db, Collections.PRODUCTS)
  , where("active", "==", true));

  const unsubscribe = onSnapshot(q, (snapshot) => {
    snapshot.forEach(async (prod) => {
      const product = prod.data()
       if (prod.id) {
        const productPrices = await getCollectionById(
          `${Collections.PRODUCTS}/${prod.id}/prices`
        );
        setStateFunc(
        [ { ...product, prices: productPrices }])
      }
      setLoadingFunc(false)
    });

  });

  return () => unsubscribe();
}

export const getTemplates = (setStateFunc: any) => {
  const currentUserId = getCurrentUserId();
  const q = query(collection(db, "templates"),
    where("templateOwner", "==", currentUserId));

  const templates: DocumentData[] = []

  onSnapshot(q, (snapshot) => {
    snapshot.docs.forEach((document) => {
      templates.push(document.data())
      setStateFunc(templates)
    })
  });

}



export const getTemplatePhases = (templateId: string) =>
  getCollectionById(
    `${Collections.TEMPLATES}/${templateId}/${SubCollections.PHASES}`,
  ) as Promise<ITemplatePhaseApi[]>;

export const getProjectPhases = (projectId: string) =>
  getCollectionById(`
    ${Collections.PROJECTS}/${projectId}/${SubCollections.PHASES}
  `) as Promise<ITemplatePhaseApi[]>;

export const getProjectPhase = async (
  projectId: string,
  phaseId: string,
): Promise<IProjectPhase | null> => {
  // eslint-disable-next-line max-len
  const projectPath = `${Collections.PROJECTS}/${projectId}`;
  // eslint-disable-next-line max-len
  const projectPhasePath = `${projectPath}/${SubCollections.PHASES}/${phaseId}`;

  try {
    const projectPhaseRef = doc(db, projectPhasePath);
    const projectPhaseSnap = await getDoc(projectPhaseRef);
    const projectRef = doc(db, projectPath);
    const projectSnap = await getDoc(projectRef);
    const projectPhaseReviewers = await getCollectionById(
      `${Collections.PROJECTS}/${projectId}/${SubCollections.PHASE_REVIEWERS}`,
    );
    const projectPhaseComments = await getCollectionById(
      // eslint-disable-next-line max-len
      `${projectPath}/${SubCollections.PHASES}/${phaseId}/${SubCollections.PHASE_COMMENTS}`,
      [orderBy('time')],
    );

    if (projectPhaseSnap.exists() && projectSnap.exists()) {
      const { name, clientName, phasesOrder, logoSrc,
        activePhaseId, projectOwner } = projectSnap.data();

      return {
        name,
        clientName,
        phaseReviewers: projectPhaseReviewers as IPhaseReviewer[],
        phaseComments: projectPhaseComments as IPhaseComment[],
        phasesOrder,
        logoSrc,
        projectOwner,
        activePhaseId,
        phase: projectPhaseSnap.data() as IPhaseQuestion,
      };
    }
  } catch (error) {
    if (error instanceof Error) {
      errorNotification(error.message);
    }
  }
  return null;
};

export const searchProject = async (searchText: string) => {
  const projects = await getCollectionById(Collections.PROJECTS, [
    where('name', '>=', searchText),
  ]);
  return projects;
};


export const getAvatar = async (email: string | undefined) => {
  let avatar = ICONS.USER_PLACEHOLDER

  if (email) {
    const allUsers = await getCollectionById(Collections.USERS, [
      where('email', '==', email),
    ])

    if (allUsers[0]?.avatarURL) {
      avatar = allUsers[0].avatarURL
    }
  }

  return avatar
};


export const getCurrentUser = async () => {
  const currentUserId = getCurrentUserId();
  const currentUserPath = `${Collections.USERS}/${currentUserId}`;
  const currentUserRef = doc(db, currentUserPath);
  const currentUserSnap = await getDoc(currentUserRef);
  if (currentUserSnap.exists()) {
    return currentUserSnap.data() as IUser;
  }
  return null;
}
