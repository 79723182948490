import React, { MouseEvent, useCallback, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {  TEMPLATES } from 'constants/routes';
import { Errors } from 'constants/notifications';
import { TemplateFieldTypes } from 'models/template';
import { ICreateTemplate, ITemplate } from 'models/createTemplate';
import { mutations, queries } from 'api';
import useCreateTemplateContext from 'context/modules/createTemplate/useCreateTemplateContext';
import { CREATE_TEMPLETE_ACTIONS } from 'context/modules/createTemplate/actions';
import useFetchInitialData from 'hooks/useFetchInitialData';
import useValidation from 'hooks/useValidation';

interface IUseCreateTemplate
{
  showAddQuestionButton: boolean;
  templateName: string;
  mainButtonText: string;
  labelText: string;
  isLoading: boolean;
  createTemplateLoading: boolean;
  toggleAddQuestion: () => void;
  handleEditTemplateClick: () => void;
  handleAddQuestion: (event: MouseEvent<HTMLElement>) => void;
  handleTemplateNameChange: 
  (event: React.FormEvent<HTMLTextAreaElement>) => void;
}

interface IParams
{
  templateId: string;
}

const useTemplate = (): IUseCreateTemplate =>
{
  const { dispatch, questionsList } = useCreateTemplateContext();
  const [showAddQuestionButton, setShowAddQuestionButton] =
    useState<boolean>(true);
  const [templateName, setTemplateName] = useState<string>('');
  const { templateId } = useParams<IParams>();
  const [createTemplateLoading, setCreateTemplateLoading] = 
  useState<boolean>(false)
  

  const fetchTemplate = useCallback(async () =>
  {
    const templateData = await queries.getTemplateById(templateId);
    return templateData;
  }, [templateId]);

  const { isLoading, result } = useFetchInitialData({
    request: templateId ? fetchTemplate : undefined,
    error: Errors.TEMPLATE_FETCHING_FAIL,
  });

  const { isTemplateValidationPassed } = useValidation();

  const {
    name: initialName,
    phases,
  } = { ...(result as ITemplate) };

  useEffect(() =>
  {
    if (initialName )
    {
      setTemplateName(initialName);
     
      if (phases)
      {
        dispatch({
          type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_INITIAL,
          payload: { phases },
        });
      }
    }
  }, [dispatch, initialName, phases]);

  const history = useHistory();



  const toggleAddQuestion = (): void =>
  {
    setShowAddQuestionButton(prev => !prev);
  };

  const handleAddQuestion = (event: MouseEvent<HTMLElement>): void =>
  {
    const { id: type } = event.currentTarget;

    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.ADD_QUESTION,
      payload: { type: type as TemplateFieldTypes },
    });
    setShowAddQuestionButton(true);
  };

  const handleEditTemplateClick = async (): Promise<void> =>
  {
    if (isTemplateValidationPassed(templateName, questionsList))
    {
      setCreateTemplateLoading(true)
      const templateData: ICreateTemplate = {
        title: templateName,
        questions: questionsList.map((question, order) =>
        {
          const { title, type, isEdited, isAdded, isRemoved, id, options } =
            question;

          return {
            title,
            type,
            options: options?.map((option) => ({
              ...(type === TemplateFieldTypes.TEXT_CHOISE ||
                type === TemplateFieldTypes.YES_NO_DECISION
                ? { text: option.text }
                : {}),
              ...(type === TemplateFieldTypes.IMAGE_CHOISE
                ? {
                  file: option.file,
                  imageSrc: option.imageSrc,
                  fileName: option.fileName,
                  isAdded: option.isAdded,
                  isRemoved: option.isRemoved,
                }
                : {}),
            })),
            isEdited,
            isAdded,
            isRemoved,
            order,
            ...(isAdded ? {} : { id }),
          };
        }),
      };

      if (templateId)
      {
        await mutations.updateTemplate(templateId, templateData);
      } else
      {
        await mutations.createTemplate(templateData);
      }
      setCreateTemplateLoading(false)
      history.push(TEMPLATES);
    }
  };

  const handleTemplateNameChange = (
    event: React.FormEvent<HTMLTextAreaElement>,
  ): void =>
  {
    const { value } = event.currentTarget;
    setTemplateName(value);
  };



  const mainButtonText = templateId ? 'Save' : 'Create';
  const labelText = templateId ? 'Edit template' : 'Create a template';

  return {
    mainButtonText,
    showAddQuestionButton,
    templateName,
    labelText,
    isLoading: templateId ? isLoading : false,
    toggleAddQuestion,
    handleAddQuestion,
    handleEditTemplateClick,
    handleTemplateNameChange,
    createTemplateLoading
  };
};

export default useTemplate;