import { getProjects } from 'api/queries';
import { IAddProject } from 'models/addProject';
import useFetchInitialData from 'hooks/useFetchInitialData';
import { Errors } from 'constants/notifications';

interface IUseDashboard
{
  isLoading: boolean;
  projects: IAddProject[];
}

const useDashboard = (): IUseDashboard =>
{
  const { isLoading, result } = useFetchInitialData({
    request: getProjects,
    error: Errors.PROJECTS_FETCHING_FAIL,
  });

  const allUserProjects = (result as IAddProject[]) || [];
  const projects = allUserProjects
  .filter(project => project?.status === 'published')
  return { isLoading, projects };
};

export default useDashboard;
