/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { DraggableProvidedDragHandleProps } from 'react-beautiful-dnd';
import classNames from 'classnames';
import { ITemplateField, ITemplateFieldOption } from 'models/createTemplate';
import useQuestion from './useQuestion';
import TextChoise from './TextChoise';
import ImageChoise from './ImageChoise';

interface IQuestion {
  question: ITemplateField;
  index: number;
  dragHandleProps?: DraggableProvidedDragHandleProps;
  removeQuestion:  (id:string) => void;
  handleAddQuestionOption:  (id:string) => void;
  handleRemoveQuestionOption:  (id:string, index: number | undefined) => void;
  handleQuestionChanged:
  (e: React.ChangeEvent<HTMLInputElement>, id:string) => void;
  handleQuestionOptionChanged: (
    e: React.ChangeEvent<HTMLInputElement>,
  id:string, optionIndex:number) => void 
  handleEditClicked: ( id: string, isEditing: boolean)=> void
  handleFileSelect :(
    event: React.ChangeEvent<HTMLInputElement>,
    id: string,
    optionIndex: number,
  )=> void 
  votingHasStarted: boolean
}

const Question: React.FC<IQuestion> = ({
  question,
  index,
  dragHandleProps,
  removeQuestion,
  handleQuestionChanged,
  handleQuestionOptionChanged,
  handleAddQuestionOption,
  handleRemoveQuestionOption,
  handleEditClicked,
  handleFileSelect,
  votingHasStarted
}) => {
  const {
    isQuestionEdit,
    isTextChoiseQuestion,
    isImageChoiseQuestion,
    isYesNoDecision,
    isTextArea,
    label,
    questionText,
    questionOptions,
  } = useQuestion(question);

  const iconClassName = classNames({
    fas: true,
    'fa-check': isTextChoiseQuestion,
    'fa-image': isImageChoiseQuestion,
    'fa-adjust': isYesNoDecision,
    'fa-pen-square': isTextArea,
  });

  return (
    <div
      id="template-options"
      className="template-options"
    >
      <div className="template-options__panel accordion">
        <input
          type="checkbox"
          name="panel"
          id={`template-question-${index}`}
          className="template-options__toggle-accordion"
          defaultChecked
        />
        <label
          htmlFor={`template-question-${index}`}
          className="accordion__label template-options__label
          no-focus-outline"
        >
          <p className="template-options__number">{index + 1}</p>
          {isQuestionEdit ? (
            <input
              type="text"
              className="template-options__input"
              placeholder="Type your question..."
              value={questionText}
              onChange={(e) => handleQuestionChanged(e, question.id)}
            />
          ) : (
            <p className="template-options__question">{questionText}</p>
          )}

       {  !votingHasStarted && <><button
            type="button"
            className="edit-btn template-options__question-btn"
            onClick={() => handleEditClicked(question.id, !isQuestionEdit)}
          >
            {/* Edit */}
            <i className="fas fa-cog" />
          </button>
          <button
            type="button"
            className="delete-btn template-options__question-btn"
            onClick={() => removeQuestion(question.id)}
          >
            {/* Delete */}
            <i className="fas fa-trash" />
          </button>
          <span className="drag-panel-btn" {...dragHandleProps}>
          {/* Reorder */}
            <i className="fas fa-arrows-alt-v" />
          </span> </>}
                    <i
            className="template-options__chevron-right fas
          fa-chevron-right"
          />
          <span className="chevron-down-icon">
            <i className="fas fa-chevron-down" />
          </span>
        </label>
        <div
          className="accordion__content template-options__accordion
        template-questions-spacing text-option"
        >
          <div className="template-options__type text-center">
            <i className={iconClassName} />
            <p>{label}</p>
          </div>
          <div className="template-options__list">
            {(isTextChoiseQuestion || isYesNoDecision) && (
              <TextChoise
                handleQuestionOptionChanged={handleQuestionOptionChanged}
                isQuestionEdit={isQuestionEdit}
                options={questionOptions as ITemplateFieldOption[]}
                questionId={question.id}
                editableOptions={isTextChoiseQuestion}
                handleAddQuestionOption={handleAddQuestionOption}
                handleRemoveQuestionOption={handleRemoveQuestionOption}
              />
            )}
            {isImageChoiseQuestion && (
              <ImageChoise
              handleFileSelect={handleFileSelect}
                handleAddQuestionOption={handleAddQuestionOption}
                handleRemoveQuestionOption={handleRemoveQuestionOption}
                isQuestionEdit={isQuestionEdit}
                options={questionOptions}
                questionId={question.id}
              />
            )}
          </div>
          <span className="drag-panel-btn" {...dragHandleProps}>
            <i className="fas fa-bars" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default Question;
