import React, { FC } from 'react';
import { ToastContainer } from 'react-toastify';

const Toast: FC = () => (
  <ToastContainer
    position="top-center"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    pauseOnFocusLoss
    draggable
    pauseOnHover
  />
);

export default Toast;
