import React from 'react';
import PageContainer from 'components/Common/PageContainer';
import ContentContainer from 'components/Common/ContentContainer';


const Reviewers: React.FC = () => (
    <PageContainer className="reviewers">
<ContentContainer isFullHeight>
Reviewers
</ContentContainer>
     
    </PageContainer>
  );

export default Reviewers;
