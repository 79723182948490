export enum Errors
{
  USER_ADDING_FAIL = 'Something went wrong while creating user',
  PROJECT_ADDING_FAIL = 'Something went wrong while creating project',
  PROJECT_PHASES_ADDING_FAIL = 'Something went wrong while creating phases',
  PROJECT_LOGO_ADDING_FAIL = 'Something went wrong while creating logo',
  FEEDBACK_ADDING_FAIL = 'Something went wrong while submitting your feedback',
  TEMPLATE_ADDING_FAIL = 'Something went wrong while creating template',
  TEMPLATE_UPDATING_FAIL = 'Something went wrong while updating template',
  PROFILE_UPDATING_FAIL = 'Something went wrong while updating profile',
  TEMPLATE_FETCHING_FAIL = 'Something went wrong while fetching template',
  TEMPLATES_FETCHING_FAIL = 'Something went wrong while fetching templates',
  PROJECTS_FETCHING_FAIL = 'Something went wrong while fetching projects',
  PRODUCTS_FETCHING_FAIL = 'Something went wrong while fetching products',
  PROFILE_FETCHING_FAIL = 'Something went wrong while fetching profile',
  PROJECT_FETCHING_FAIL = 'Something went wrong while fetching project',
  FIELD_IS_EMPTY = 'field is empty',
  IS_EMPTY = 'is empty',
  IMAGE_OPTION_IS_EMPTY = 'is not uploaded',
  EMAIL_IS_NOT_VALID = 'is not valid email address',
  REVIEWER_ADDING_FAIL = 'Something went wrong while adding reviewer',
  PHASE_VOTE_ADDING_FAIL = 'Something went wrong while adding your vote',
  PROJECT_PUBLISH_FAIL = 'Something went wrong when publishing your project',
}

export enum Success
{
  USER_ADDING_SUCCESS = 'User created',
  PROJECT_ADDING_SUCCESS = 'Project created',
  PROJECT_PHASES_ADDING_SUCCESS = 'Phases created',
  PROJECT_LOGO_ADDING_SUCCESS = 'Logo created',
  TEMPLATE_ADDING_SUCCESS = 'Template created',
  FEEDBACK_ADDING_SUCCESS = 'Feedback received',
  TEMPLATE_UPDATING_SUCCESS = 'Template updated',
  PROFILE_UPDATING_SUCCESS = 'Profile updated',
  REVIEWER_IS_ADDED = 'Reviewer is added',
  PHASE_VOTE_ADDED = 'Your phase vote is added',
  PROJECT_PUBLISH_SUCCESS = 'Your project has been published',
}
