import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import PageContainer from 'components/Common/PageContainer';
import ContentContainer from 'components/Common/ContentContainer';
import { TEMP_IMAGES } from 'constants/images';
import { CREATE_PROJECT, EDIT_PROJECT } from 'constants/routes';
import { IAddProject } from 'models/addProject';
import Button from 'components/Common/Button';
import useProjects from './useProjects';
import ProjectReviewers from './ProjectReviewers';

const ProjectsListComponent: React.FC = () => {
  const {
    projects,
  } = useProjects();

  const [filteredProjects, setFilteredProjects] = useState<
    IAddProject[] | undefined
  >(projects);
  const [filterValue, setFilterValue] = useState<string>('');
  const [statusFilter, setStatusFilter] = useState('all')

  const history = useHistory();

  useEffect(() => {
    if (projects) {
      const filtered = projects.filter((project) =>
        project.name.toLowerCase().includes(filterValue.toLowerCase()),
      ).filter(p => p.status === statusFilter || statusFilter === 'all');
      setFilteredProjects(filtered);
    } else {
      setFilteredProjects(projects);
    }
  }, [filterValue, projects,statusFilter]);

  return (
    <>
      <PageContainer headerTitle="Projects" className="projects-list">
        <ContentContainer>
          <div className="actions-container">
            <div className="filters-container">
              <div className="form-field-container">
                <input
                  name="search"
                  placeholder="Search"
                  type="text"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
              </div>

              <div className="form-field-container">
                <select className="options"
                value={statusFilter}
                onChange={(e) => setStatusFilter(e.target.value)}>
                  <option value="all">All Statuses</option>
                  <option value="draft">Draft</option>
                  <option value="published">Published</option>
                </select>
              </div>
            </div>
            <div className="actions">
              <Button onClick={() => history.push(CREATE_PROJECT)}>
                Create Project
              </Button>
            </div>
          </div>
        </ContentContainer>
        <ContentContainer>
          {filteredProjects ? (
            <div className="project-grid">
              {filteredProjects.map((project) => 
               (
                  <Link
                    to={`${EDIT_PROJECT}/${project.id}`}
                    key={project.id}
                    className="project-card"
                  >
                    <div className="project-image-container">
                      <img
                        className="project-image"
                        src={project?.logoSrc || TEMP_IMAGES.TEMPLATE}
                        alt="sth"
                      />
                    </div>
                    <div className="project-information">
                      <h3 className="project-name">
                        {project?.name || 'Untitled Project'}
                      </h3>
                  
                      <p className="project-client-name">
                        {project?.clientName || ''}
                      </p>
                      <p className="project-description">
                        {project?.description || ''}
                      </p>
                      <ProjectReviewers 
                      reviewers ={project?.phaseReviewers || []} />
                    </div>
                    <span 
                    style={{ backgroundColor: project.status === 'published' 
                    ? '#222E64' : '#6C6C6C'}} 
                    className="project-status">
                      {project.status}
                    </span>
                  </Link>
                )
              )}
            </div>
          ) : (
            <h3>You do not have any projects.</h3>
          )}
        </ContentContainer>
      </PageContainer>
    </>
  );
};

export default ProjectsListComponent;
