import { TemplateFieldTypes } from 'models/template';
import { IPhaseComment } from 'models/phaseReview';

export interface ITemplateField {
  title: string;
  options?: ITemplateFieldOption[];
  isEditing: boolean;
  type: TemplateFieldTypes;
  id: string;
  isEdited?: boolean;
  isRemoved?: boolean;
  isAdded?: boolean;
  order: number;
  displayIndex?: number;
  [key: string]: string | ITemplateFieldOption[] | boolean | undefined | number;
}

export interface ITemplateFieldOption {
  text?: string;
  imageSrc?: string;
  file?: File;
  fileName?: string;
  isRemoved?: boolean;
  isAdded?: boolean;
  letter?: string;
  index?: number;
}

export interface ICreateTemplate {
  title: string;
  questions: IQuestion[];
}

export interface IQuestion {
  id?: string;
  title: string;
  type: string;
  isEdited?: boolean;
  isRemoved?: boolean;
  isAdded?: boolean;
  options?: IQuestionOption[];
  order: number;
  comments?: IPhaseComment[];
}

export interface IQuestionOption {
  text?: string;
  file?: File;
  imageSrc?: string;
  fileName?: string;
  isRemoved?: boolean;
  isAdded?: boolean;
}

export interface TTemplatePhase extends IQuestion {
  id: string;
}

export interface ITemplatePhaseApi {
  options?: ITemplateFieldOption[];
  type: TemplateFieldTypes;
  id: string;
  title: string;
  order: number;
  comments: IPhaseComment[];
}

export interface ITemplate {
  starsNumber: number;
  peopleNumber: string;
  name: string;
  id: string;
  phases?: ITemplatePhaseApi[];
}

export const INITIAL_OPTIONS_YES_NO = [
  { text: 'Yes', index: 0, letter: 'A' },
  { text: 'No', index: 1, letter: 'B' },
];