import React from 'react';
import classNames from 'classnames';
import { IOption } from 'models/questions';
import { ANSWER_LETTERS } from 'constants/common';
import ProgressBarBackground from 'components/Common/ProgressBar/ProgressBarBackground';

interface IImage {
  option: IOption;
  index: number;
  isDisplayModeGrid: boolean;
  selectedAnswer?: string;
  commentAnswer?: string;
  answerSubmitted: boolean;
  onAnswerChange: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onImageClick: (isOpen: boolean, displayedValue?: string) => void;
  onCommentClick: (answer: string) => void;
  votePercentage: number;
}

const Image: React.FC<IImage> = ({
  option,
  index,
  isDisplayModeGrid,
  selectedAnswer,
  commentAnswer,
  answerSubmitted,
  onAnswerChange,
  onImageClick,
  onCommentClick,
  votePercentage,
}) => {
  const letter = ANSWER_LETTERS[index];
  const isCurrentAnswer = selectedAnswer === letter;
  const isCurrentComment = commentAnswer === letter;
  const { imageSrc, text } = option;

  const wrapperСlassName = classNames({
    'logo-selection__col': true,
    col: true,
    'logo-selection__col-2': isDisplayModeGrid,
    'logo-selection__col-1': !isDisplayModeGrid,
    selected: isCurrentAnswer,
    'image-col': !!imageSrc,
  });

  const logoOptionsBtnClassName = classNames({
    'logo-options__btn': true,
    selected: isCurrentAnswer,
  });

  const logoOptionsIconClassName = classNames({
    fas: true,
    'fa-comment': true,
    'fa-flip-horizontal': true,
    'logo-options__comment': true,
    enabled: isCurrentComment,
  });

  const handleCommentClick = (): void => {
    onCommentClick(letter);
  };

  return (
    <div
      className={wrapperСlassName}
      data-id={index}
      data-attr={letter}
      onClick={onAnswerChange}
      aria-hidden="true"
    >
      <ProgressBarBackground
        isImageSrc={!!imageSrc}
        id="bar-test"
        dataValue={Number(votePercentage)}
      >
        <>
          <div className="logo-options">
            {imageSrc ? (
              <img
                src={imageSrc}
                className="logo-selection__logo"
                alt="logo"
                onClick={() => onImageClick(true, letter)}
                role="presentation"
              />
            ) : null}
            <button
              type="button"
              className={logoOptionsBtnClassName}
              data-attr={letter}
              disabled={answerSubmitted}
              onClick={onAnswerChange}
            >
              {letter}
            </button>
            {text ? <span className="logo-options__text">{text}
             <span className="logo-options__perc">
               ({votePercentage}%)</span></span> : null}
            <i
              className={logoOptionsIconClassName}
              onClick={handleCommentClick}
              onKeyDown={handleCommentClick}
              role="button"
              tabIndex={-1}
              aria-label="comment"
            />
          </div>
        </>
      </ProgressBarBackground>
    </div>
  );
};

export default Image;
