import React from 'react';
import useAuthorisation from 'hooks/useAuthorisation';
import { ICONS, LOGO } from 'constants/images';
import useProfile from 'components/Profile/useProfile';
import { Link } from 'react-router-dom';

interface IHeader {
  title?: string;
  isMinimized?: boolean;
  setMenuIsMinimized: React.Dispatch<React.SetStateAction<boolean>>;
  hasTag?:boolean;
  tagLabel?:string
  setIsNavExpanded: React.Dispatch<React.SetStateAction<boolean>>
}

const Header: React.FC<IHeader> = ({
  title,
  isMinimized = true,
  setMenuIsMinimized,
  hasTag = false,
  tagLabel = '',
  setIsNavExpanded
}) => {
  
  const { signOut } = useAuthorisation();
  const { profile } = useProfile();

  return (
    <nav className={`${isMinimized && 'minimized'} main-navbar`}>
      <Link to="/home" className="main-navbar__logo-link">
        {isMinimized ? (
          <img
            src={LOGO.SMALL}
            className="navbar-logo-small"
            alt="Jetrospects logo"
          />
        ) : (
          <img
            src={LOGO.HEADER}
            className="navbar-logo"
            alt="Jetrospects logo"
          />
        )}
      </Link>

      <button
        type="button"
        className="main-navbar__menu-icon-container"
        onClick={() => setMenuIsMinimized((prev) => !prev)}
      >
        {isMinimized ? (
          <i className="fa fa-caret-square-right menu-icon" />
        ) : (
          <>
            <i className="fa fa-caret-square-left menu-icon" />
            <span>Collapse</span>
          </>
        )}
      </button>

      <h1 className="main-navbar__page-title">
        {title || ''} 
          {hasTag ? <span className="page-title-tag">
            {tagLabel}</span>: null}</h1>

      <div className="user-info">
        <div className="user-info__avatar-container">
          <img
            className="user-info__picture"
            src={profile?.avatarURL || ICONS.USER_PLACEHOLDER}
            alt="user"
          />
        </div>
        <div className="user-info__container">
          <p className="user-info__name">
            {profile?.displayName || profile?.email || ''}
          </p>
          <div
            className="user-info__logout"
            onClick={signOut}
            onKeyDown={signOut}
            tabIndex={-1}
            role="button"
          >
            Logout
          </div>
        </div>
      </div>
      <div className='mobile-menu'>
      <i className="fa fa-bars burger-icon" 
      role='button'
      onClick={() => setIsNavExpanded(prev => !prev)} 
      onKeyDown={() => setIsNavExpanded(prev => !prev)}
      tabIndex={-1}
      aria-label="Menu" 
      />
      </div>
    </nav>
  );
};
export default Header;
