import React from 'react';
import classNames from 'classnames';

import { IModalOptions, IOption } from 'models/questions';

import Slick from 'components/ProjectPhase/Modal/Slick';

interface IModal {
  modalOptions: IModalOptions;
  questionOptions: IOption[];
  selectedAnswer?: string;
  setModalProperties: (isOpen: boolean, displayedValue?: string) => void;
  onAnswerChange: (
    event:
      | React.MouseEvent<HTMLElement, MouseEvent>
      | React.KeyboardEvent<HTMLDivElement>,
  ) => void;
}

const Modal: React.FC<IModal> = ({
  modalOptions,
  questionOptions,
  selectedAnswer,
  setModalProperties,
  onAnswerChange,
}) => {
  const modalOverlayClassNames = classNames({
    'modal-overlay': true,
    active: modalOptions.isOpen,
  });

  return (
    <>
      <div className={modalOverlayClassNames}>
        <span
          className="close-modal"
          onClick={() => setModalProperties(false)}
          onKeyDown={() => setModalProperties(false)}
          role="button"
          tabIndex={-1}
        >
          &times;
        </span>
      </div>
      <div id="logoModal" className="modal">
        <div className="modal__slider no-focus-outline">
          <Slick
            questionOptions={questionOptions}
            onAnswerChange={onAnswerChange}
            selectedAnswer={selectedAnswer}
            displayedValue={modalOptions.displayedValue}
          />
        </div>
      </div>
    </>
  );
};

export default Modal;
