import React from 'react';

interface IContentContainer {
  children: React.ReactNode;
  className?: string;
  title?: string;
  onClick?: () => void;
  isHidden?: boolean;
  isFullHeight?: boolean;
  isDisabled?: boolean;
}

const ContentContainer: React.FC<IContentContainer> = ({
  children,
  className,
  title,
  onClick,
  isHidden = false,
  isFullHeight = false,
  isDisabled = false,
}) =>
  onClick && !isDisabled ? (
    <div
      className={`${isHidden ? 'hidden' : 'shown'} ${
        isFullHeight ? 'full-height' : 'auto-height'
      }  content-container hoverable`}
      onClick={onClick}
      onKeyDown={onClick}
      role="button"
      tabIndex={-1}
    >
      {!!title && (
        <div className="content-container__title-container">
          <h1>{title}</h1>
        </div>
      )}
      <div className={`content-container__inner ${className || ''}`}>
        {children}
      </div>
    </div>
  ) : (
    <div
      className={`${isHidden ? 'hidden' : 'shown'} ${
        isFullHeight ? 'full-height' : 'auto-height'
      } content-container`}
    >
      {!!title && (
        <div className="content-container__title-container">
          <h1>{title}</h1>
        </div>
      )}
      <div className={`content-container__inner ${className || ''}`}>
        {children}
      </div>
    </div>
  );

export default ContentContainer;
