import { useEffect, useState } from 'react';

import { Errors } from 'constants/notifications';
import { DocumentData } from 'firebase/firestore';
import { errorNotification } from 'helpers/notifications';

interface IUseFetch
{
  isLoading: boolean;
  result: DocumentData | DocumentData[] | null;
}

interface IUseFetchProps
{
  request?: () => Promise<DocumentData | null>;
  error: Errors;
}

const useFetchInitialData = ({
  request,
  error,
}: IUseFetchProps): IUseFetch =>
{
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [result, setResult] = useState<DocumentData | DocumentData[] | null>(
    null,
  );

  useEffect(() =>
  {
    const fetch = async (): Promise<void> =>
    {
      if (request)
      {
        setIsLoading(true);
        const apiResult = await request();
        if (!apiResult)
        {
          errorNotification(error);
        }
        setResult(apiResult);
        setIsLoading(false);
      }
    };

    fetch();
  }, [request, error]);

  return { isLoading, result };
};

export default useFetchInitialData;
