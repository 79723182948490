export const ANSWER_LETTERS = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H',
  'I',
  'J',
  'L',
];


export const PLACEHOLDER_URL = 'https://via.placeholder.com/150';
