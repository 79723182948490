import detailsIconSrc from 'assets/images/detail_icon.png';
import phaseIconSrc from 'assets/images/Phase_ICON.png';
import userIconSrc from 'assets/images/User_ICON.png';
import userPlaceholderSrc from 'assets/images/user-img-placeholder.png';
import downArrowSrc from 'assets/images/Open_Arrow.png';
import templateIconSrc from 'assets/images/Template_Icon.png';
import templateIconWhiteSrc from 'assets/images/template_Icon_white.png';
import gridEnabledIconSrc from 'assets/images/Grid_View_Enabled.png';
import gridDisabledIconSrc from 'assets/images/Grid_View_Disabled.png';
import stackedDisabledIconSrc from 'assets/images/Stacked_View_Disabled.png';
import stackedEnabledIconSrc from 'assets/images/Stacked_View_Enabled.png';
import commentsIconSrc from 'assets/images/Comments_ICON.png';

import logoWhiteSrc from 'assets/images/Logo_White.png';
import headerLogoSrc from 'assets/images/jetrospects-logo-header.png';
import smallLogoSrc from 'assets/images/jetrospects-logo-small.png';

import landingPageBackgroundSrc from 'assets/images/landing-page-bg.jpg';

import profilePictureSrc from 'assets/images/user-pic.png';
import user1ImgSrc from 'assets/images/user-img-1.png';
import user2ImgSrc from 'assets/images/user-img-2.png';
import user3ImgSrc from 'assets/images//user-img-3.png';
import user4ImgSrc from 'assets/images//user-img-4.png';
import templateImgSrc from 'assets/images/2k_eris_fictional.png';
import createTemplate1Src from 'assets/images/create-template-img-placeholder.jpg';
import createTemplate2Src from 'assets/images/create-template-img-placeholder-2.jpg';
import createTemplate3Src from 'assets/images/create-template-img-placeholder-3.jpg';
import createTemplate4Src from 'assets/images/create-template-img-placeholder-4.jpg';
import templatePreviewImage1Src from 'assets/images/template-img-1-large.jpg';
import templatePreviewImage2Src from 'assets/images/template-img-2-large.jpg';
import templatePreviewImage3Src from 'assets/images/template-img-3-large.jpg';
import templatePreviewImage4Src from 'assets/images/template-img-4-large.jpg';
import projectImage1Src from 'assets/images/project-img-1.png';
import projectImage2Src from 'assets/images/project-img-2.png';
import projectImage3Src from 'assets/images/project-img-3.png';
import projectImage4Src from 'assets/images/project-img-4.png';
import projectImage5Src from 'assets/images/project-img-5.png';
import projectImage6Src from 'assets/images/project-img-6.png';
import projectImage7Src from 'assets/images/project-img-7.png';
import projectImage8Src from 'assets/images/project-img-8.png';
import projectImage9Src from 'assets/images/project-img-9.png';

import emptySrc from 'assets/images/void.svg';
import emptySrc2 from 'assets/images/empty.svg';


export const ICONS = {
  DETAILS: detailsIconSrc,
  PHASE: phaseIconSrc,
  USER: userIconSrc,
  DOWN_ARROW: downArrowSrc,
  USER_PLACEHOLDER: userPlaceholderSrc,
  TEMPLATE: templateIconSrc,
  TEMPLATE_WHITE: templateIconWhiteSrc,
  GRID_ENABLED: gridEnabledIconSrc,
  GRID_DISABLED: gridDisabledIconSrc,
  STACKED_ENABLED: stackedEnabledIconSrc,
  STACKED_DISABLED: stackedDisabledIconSrc,
  COMMENTS: commentsIconSrc,
};

export const LOGO = {
  WHITE_BIG: logoWhiteSrc,
  HEADER: headerLogoSrc,
  SMALL: smallLogoSrc
};

export const BACKGROUNDGS = {
  LANDING_PAGE: landingPageBackgroundSrc,
};

export const TEMP_IMAGES = {
  PROFILE: profilePictureSrc,
  USER1: user1ImgSrc,
  USER2: user2ImgSrc,
  USER3: user3ImgSrc,
  USER4: user4ImgSrc,
  TEMPLATE: templateImgSrc,
  IMAGE_CHOISE_1: createTemplate1Src,
  IMAGE_CHOISE_2: createTemplate2Src,
  IMAGE_CHOISE_3: createTemplate3Src,
  IMAGE_CHOISE_4: createTemplate4Src,
  TEMPLATE_IMAGE_1: templatePreviewImage1Src,
  TEMPLATE_IMAGE_2: templatePreviewImage2Src,
  TEMPLATE_IMAGE_3: templatePreviewImage3Src,
  TEMPLATE_IMAGE_4: templatePreviewImage4Src,
  PROJECT_IMAGE_1: projectImage1Src,
  PROJECT_IMAGE_2: projectImage2Src,
  PROJECT_IMAGE_3: projectImage3Src,
  PROJECT_IMAGE_4: projectImage4Src,
  PROJECT_IMAGE_5: projectImage5Src,
  PROJECT_IMAGE_6: projectImage6Src,
  PROJECT_IMAGE_7: projectImage7Src,
  PROJECT_IMAGE_8: projectImage8Src,
  PROJECT_IMAGE_9: projectImage9Src,
};

export const PAGE_IMAGES = {
  EMPTY: emptySrc,
  EMPTY_2: emptySrc2
}
