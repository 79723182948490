import { CREATE_TEMPLETE_ACTIONS } from 'context/modules/createTemplate/actions';
import useCreateTemplateContext from 'context/modules/createTemplate/useCreateTemplateContext';
import resizeFile from 'helpers/resizeFile';
import React from 'react';

interface IUseQuestionOptions {
  handleRemoveOption: (index: number) => void;
  handleAddOption: () => void;
  handleChangeOption: (index: number, title: string) => void;
  handleFileSelect: (
    event: React.ChangeEvent<HTMLInputElement>,
    index: number,
  ) => void;
}

const useQuestionOptions = (questionId: string): IUseQuestionOptions => {
  const { dispatch } = useCreateTemplateContext();

  const handleRemoveOption = (optionIndex: number): void => {
    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.REMOVE_QUESTION_OPTION,
      payload: { id: questionId, optionIndex },
    });
  };

  const handleAddOption = (): void => {
    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.ADD_QUESTION_OPTION,
      payload: { id: questionId },
    });
  };

  const handleChangeOption = (optionIndex: number, title: string): void => {
    dispatch({
      type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_OPTION_TEXT,
      payload: { id: questionId, optionIndex, title },
    });
  };

  const handleFileSelect = async (
    event: React.ChangeEvent<HTMLInputElement>,
    optionIndex: number,
  ): Promise<void> => {
    const target = event.target as HTMLInputElement;
    if (target?.files) {
      const image = target.files[0];

      const uri = await resizeFile(image);

      const FReader = new FileReader();
      FReader.readAsDataURL(uri as Blob);

      FReader.onload = (FREvent) => {
        if (FREvent.target) {
          dispatch({
            type: CREATE_TEMPLETE_ACTIONS.SET_QUESTION_OPTION_IMAGE,
            payload: {
              id: questionId,
              optionIndex,
              file: image,
              imageSrc: FREvent.target.result!,
            },
          });
        }
      };
    }
  };

  return {
    handleRemoveOption,
    handleAddOption,
    handleChangeOption,
    handleFileSelect,
  };
};

export default useQuestionOptions;