import React, { useEffect, useState } from 'react';
import useProfile from 'components/Profile/useProfile';
import { UserRoles } from 'models/roles';
import Button from 'components/Common/Button';
import LoaderWrapper from 'components/Common/LoaderWrapper';
import useProducts from 'components/Profile/useProducts';

const RoleSetup: React.FC = () => {
  const [role, setRole] = useState<UserRoles>(UserRoles.phaseReviewer);
  const { updateUserRole, checkoutSession, isLoading } = useProfile();
  const { products, areProductsLoading } = useProducts();
  const [ownerPrice, setOwnerPrice] = useState<number>(0)

  useEffect(() => {
    if (checkoutSession && checkoutSession.url) {
      // We have a Stripe Checkout URL, let's redirect.
      window.location.assign(checkoutSession.url);
    }
  }, [checkoutSession])  

  useEffect(() => {
      const price = products[0]?.prices[2]?.unit_amount / 100 || 0;
      if (price !== 0 ) {
        setOwnerPrice(price)
      }
  }, [ products])

  
  return (
    <div className="onboarding__center-container">
      <h1>How are you planning to use Jetrospects?</h1>
      <p>We will streamline your setup experience.</p>

      <LoaderWrapper isLoading={isLoading || areProductsLoading}>
      <div className="onboarding__roles-container">
        <div
          role="button"
          onClick={() => setRole(UserRoles.phaseReviewer)}
          onKeyDown={() => setRole(UserRoles.phaseReviewer)}
          tabIndex={-1}
          className={`role-option ${
            role === UserRoles.phaseReviewer ? 'selected' : ''
          }`}
        >
          <div>
          <div className="card-header">
          <h4 className="main-label">Reviewer</h4>
          <p className="price-label">Free</p>
          </div>
          <ul>
            <li><i className="fas fa-check" />Review Projects</li>
          </ul>
          </div>
        </div>
        <div
          role="button"
          onClick={() => setRole(UserRoles.projectOwner)}
          onKeyDown={() => setRole(UserRoles.projectOwner)}
          tabIndex={-1}
          className={`role-option ${
            role === UserRoles.projectOwner ? 'selected' : ''
          }`}
        >
          <div>
            <div className="card-header">
          <h4 className="main-label">Owner</h4>
          <p className="price-label">${ownerPrice} per month</p>
          </div>
          <ul>
            <li><i className="fas fa-check" />Create Project Templates</li>
            <li><i className="fas fa-check" />Create Projects</li>
            <li><i className="fas fa-check" />Invite Reviewers</li>
            <li><i className="fas fa-check" />Review Projects</li>
          </ul>
          </div>
        </div>
      </div>
      </LoaderWrapper>

      <div className="onboarding__actions-container">
        <Button
          isDisabled={
            (role !== UserRoles.phaseReviewer && 
              role !== UserRoles.projectOwner) || isLoading
          }
          onClick={() => updateUserRole(role)}
        >
          Save Account Type
        </Button>
      </div>
    </div>
  );
};
export default RoleSetup;
