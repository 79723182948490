import React, { useEffect, useState } from 'react';

interface IProgressBar {
  id: string;
  dataValue: number;
  isImageSrc?: boolean;
  children: React.ReactNode;
}

const LOAD_SCRIPT_TIME = 100;

const ProgressBarBackground: React.FC<IProgressBar> = ({
  id,
  dataValue,
  children,
  isImageSrc = false,
}) => {
  const [barWidthPercentage, setBarWidthPercentage] = useState<number>(0);

  useEffect(() => {
    setTimeout(() => {
      // const circle = new (window as any).ProgressBar.Circle(`#${id}`, {
      //   strokeWidth: 3.3,
      //   easing: 'easeInOut',
      //   duration: 3000,
      //   color: '#f4f4f4',
      //   trailColor: '#69e2d6',
      //   trailWidth: 3,
      //   svgStyle: null,
      // });
      // // necessary to make the progress bar go anti-clockwise

      setBarWidthPercentage(dataValue);
      // circle.set(1);
      // circle.animate(percentage, () => {
      //   circle.animate(percentage);
      // });
    }, LOAD_SCRIPT_TIME);
  }, [id, dataValue]);

  return (
    <div
      className="phase__progress-bar-outer"
      style={{
        height: '100%',
        width: '100%',
        backgroundColor: '#f4f4f4',
        marginBottom: '1rem',
        position: 'relative',
      }}
    >
      <div
        className="phase__progress-bar-inner"
        id={id}
        style={{
          width: `${barWidthPercentage}%`,
          height: '100%',
          backgroundColor: '#222e640d',
        }}
      >
        <div
          className="children-container"
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            padding: isImageSrc ? '0' : '0 2rem',
          }}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default ProgressBarBackground;
