
import React from 'react';
// import { useHistory } from 'react-router-dom';

import useAuthorisation from 'hooks/useAuthorisation';
import Button from 'components/Common/Button';

const EmailVerification: React.FC = () => {
  // const history = useHistory();
  const { currentUser, sendVerificationEmail } = useAuthorisation();

  return (
    <div className="onboarding__center-container">
      <i className="fa far fa-paper-plane onboarding__icon" />
      <h1>Verify your email address</h1>
      {!!currentUser && (
        <p>
          You&apos;ve entered <strong>{currentUser?.email || ''}</strong> as the
          email address for your account.
        </p>
      )}
      <p>Follow the instruction in the email to verify your account.</p>
      <div className="onboarding__actions-container">
        <Button onClick={sendVerificationEmail}>Resend email</Button>
        {/* <Button styleType="tertiary" onClick={() => history.push('/')}>
          Back to home
        </Button> */}
      </div>
    </div>
  );
};

export default EmailVerification;
