export enum PhaseReviewsTypes {
  PROJECT_REVIEW = 'PROJECT_REVIEW',
  ADD_TEMPLATE = 'ADD_TEMPLATE',
}

interface ITime {
  seconds: number;
}

export interface IPhaseComment {
  email: string;
  time: ITime;
  id: string;
  selectedAnswer?: string;
  commentedAnswer?: string;
  message: string;
}