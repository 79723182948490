/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { Link, useHistory } from 'react-router-dom';

import useLogin from 'components/Login/useLogin';
import useScrollDown from 'hooks/useScrollDown';

import { ICONS, LOGO } from 'constants/images';
import { SIGN_UP, FORGOT_PASSWORD } from 'constants/routes';
import Button from 'components/Common/Button';

enum LoginFields {
  email = 'email',
  password = 'password',
}

const Login: React.FC = () => {
  const [showLoginForm, setShowLoginForm] = useState<boolean>(false);
  const {
    handleInputChange,
    form,
    handleLoginClicked,
    requestError,
    handleGoogleSignupClicked,
    handleFacebookSignupClicked,
    handleTwitterSignupClicked,
    handleGithubSignupClicked,
    handleMicrosoftSignupClicked,
  } = useLogin();
  useScrollDown(() => setShowLoginForm(true));
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const history = useHistory();

  const scrollDownClass = classNames({
    'scroll-down': true,
    hidden: showLoginForm,
  });
  const signInClass = classNames({
    'sign-in': true,
    visible: showLoginForm,
  });

  useEffect(() => {
    const mobile = window.innerWidth <= 770;

    if (mobile) {
      setIsMobile(mobile);
      setShowLoginForm(true);
    }
  }, []);

  return (
    <div className="navigation">
      <div className="navigation__main-panel">
        {!isMobile && (
          <a className="half-circle-btn half-circle-btn-left" href="#">
            Learn
          </a>
        )}
        <div className="sign-in-wrapper">
          <p className="upper-logo-text">Welcome to</p>
          <img
            className="main-logo"
            src={LOGO.WHITE_BIG}
            alt="Jectrospects logo"
          />
          <div className={signInClass}>
            <input
              type="text"
              className="sign-in__input"
              placeholder="Email"
              onChange={handleInputChange}
              name={LoginFields.email}
              value={form.email}
            />
            <input
              type="password"
              className="sign-in__input"
              placeholder="Password"
              onChange={handleInputChange}
              name={LoginFields.password}
              value={form.password}
            />
            <div
              className="sign-in__button sign-in--email"
              onClick={handleLoginClicked}
              onKeyDown={handleLoginClicked}
              role="button"
              tabIndex={-1}
            >
              <i className="fa fa-envelope sign-in__icon" />
              Sign in
            </div>
            {requestError.length > 0 && (
              <p className="sign-in__error">{requestError}</p>
            )}
            <Link to={FORGOT_PASSWORD} className="sign-in--forgot-label">
              Forgot your password?
            </Link>
            <p className="sign-in--social-label">Sign in using social media</p>
            <div className="sign-up__socials-container">
              <button
                type="button"
                onClick={handleGoogleSignupClicked}
                className="sign-up__button sign-up--google"
              >
                <i className="fab fa-google sign-up__icon" />
              </button>
              <button
                type="button"
                onClick={handleFacebookSignupClicked}
                className="sign-up__button sign-up--facebook"
              >
                <i className="fab fa-facebook-square sign-up__icon" />
              </button>
              <button
                type="button"
                onClick={handleTwitterSignupClicked}
                className="sign-up__button sign-up--twitter"
              >
                <i className="fab fa-twitter sign-up__icon" />
              </button>
              <button
                type="button"
                onClick={handleGithubSignupClicked}
                className="sign-up__button sign-up--github"
              >
                <i className="fab fa-github sign-up__icon" />
              </button>
              <button
                type="button"
                onClick={handleMicrosoftSignupClicked}
                className="sign-up__button sign-up--microsoft"
              >
                <i className="fab fa-microsoft sign-up__icon" />
              </button>
            </div>
          </div>
          {!isMobile && (
            <div className={scrollDownClass}>
              <img
                className="down-arrow"
                src={ICONS.DOWN_ARROW}
                alt="arrow pointing down"
              />
              <p className="lading-page-text">Scroll to login</p>
            </div>
          )}
        </div>
        {!isMobile && (
          <Link to={SIGN_UP} className="half-circle-btn half-circle-btn-right">
            Register
          </Link>
        )}
        {isMobile && (
          <div className="bottom-btns">
            <Button
              styleType="lightGreen"
              onClick={() => history.push(SIGN_UP)}
            >
              Register
            </Button>
            <Button styleType="lightGreen" onClick={() => history.push('#')}>
              Learn
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Login;
