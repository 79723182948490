import React, { useEffect, useState, MouseEvent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { mutations, queries } from 'api';
import { getTemplatePhases } from 'api/queries';
import { IAddProject, IPhaseReviewer, ProjectPhase } from 'models/addProject';
import { DASHBOARD } from 'constants/routes';
import { PLACEHOLDER_URL } from 'constants/common';
import useValidation from 'hooks/useValidation';
import usePhaseReviewer from 'components/Common/PhaseReviewers/usePhaseReviewer';
import { Collections } from 'api/constants';
import { INITIAL_OPTIONS_YES_NO, ITemplateField, TTemplatePhase } from 'models/createTemplate';
import { IProjectPhase } from 'models/questions';
import { TemplateFieldTypes } from 'models/template';
import resizeFile from 'helpers/resizeFile';

interface IUseAddProject
{
  form: IAddProject;
  reviewerEmail: string;
  showAddReviewer: boolean;
  votingHasStarted: boolean;
  handleAddReviewerClick: () => void;
  handlePublishProject: () => void;
  handleAddReviewerKeyDown: () => void;
  handleReviewerEmailInput: (e: React.ChangeEvent<HTMLInputElement>) => void;
  handleRemovePhaseReviewer: (index: number) => void;
  handleFormChange: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  handleAddProjectInformationClick: () => void;
  addProjectQuestion: (event: MouseEvent<HTMLElement>) => void;
  handleAddProjectPhasesClick: () => void;
  handleAddPhaseReviewersClick: () => void;
  handleAddProjectLogoClick: () => void;
  handleAddProjectClick: () => void;
  handlePhaseItemRemove: (index: number) => void;
  handleLogoSelect: (event?: React.ChangeEvent<HTMLInputElement>) => void;
  setProjectPhases: (templateId: string) => Promise<void>;
  isLoading: boolean
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>
  setFormQuestions: (phases: ProjectPhase[]) => void
}

const INITIAL_VALUES: IAddProject = {
  name: '',
  description: '',
  clientName: '',
  phases: [],
  id: undefined,
  phaseReviewers: [],
  activePhaseId: null,
  selectedTemplateId: '',
  logo: null,
  logoSrc: PLACEHOLDER_URL,
  status: 'draft'
};

interface IParams
{
  id: string;
}

const useAddProject = (): IUseAddProject =>
{
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [votingHasStarted, setVotingHasStarted] = useState<boolean>(false)
  const [currentProject, setCurrentProject] =
    useState<IAddProject | undefined>(undefined)
  const [currentProjectPhase, setCurrentProjectPhase] =
    useState<IProjectPhase[] | undefined>(undefined)
  const [currentProjectReviewers, setCurrentProjectReviewers] =
    useState<IPhaseReviewer[] | undefined>(undefined)
  // TODO make undefined the initial again
  const [projectId, setProjectId] =
    useState<string | undefined>()
  const [form, setForm] = useState<IAddProject>(INITIAL_VALUES);
  const history = useHistory();
  const { id } = useParams<IParams>();

  const { isAddProjectValidationPassed } = useValidation();
  const {
    reviewerEmail,
    showAddReviewer,
    addReviewerClick,
    handleReviewerEmailInput,
  } = usePhaseReviewer();

  useEffect(() =>
  {
    if (id)
    {
      setProjectId(id)
    }
  }, [id])


  useEffect(() =>
  {
    if (currentProject)
    {
      const reference = `${Collections.PROJECTS}/${currentProject.id}/phases`;
      queries.getCollectionById(reference)
        .then(phases => setCurrentProjectPhase(phases as IProjectPhase[]))

      const referenceReview =
        `${Collections.PROJECTS}/${currentProject.id}/phaseReviewers`;
      queries.getCollectionById(referenceReview)
        .then(reviewers => setCurrentProjectReviewers(
          reviewers as IPhaseReviewer[]))

      setForm((prev) => ({
        ...prev,
        ...currentProject,
      }));

      setVotingHasStarted(!!currentProject.submittedPhases)

    }
  }, [currentProject])

  useEffect(() =>
  {
    if (!projectId)
    {
      return;
    }
    queries.getDocument(Collections.PROJECTS, projectId, setCurrentProject)

  }, [projectId]);

  useEffect(() =>
  {
    if (currentProjectPhase)
    {
      setForm((prev) => ({
        ...prev,
        phases: currentProjectPhase as unknown as TTemplatePhase[]
      }));
    }

  }, [currentProjectPhase]);

  useEffect(() =>
  {
    if (currentProjectReviewers)
    {
      setForm((prev) => ({
        ...prev,
        phaseReviewers: currentProjectReviewers as IPhaseReviewer[]
      }));
    }

  }, [currentProjectReviewers]);


  // eslint-disable-next-line max-len
  // Create the project and add the basic project information (name, clientName, description)
  const handleAddProjectInformationClick = async (): Promise<void> =>
  {
    if (isAddProjectValidationPassed(form, 'projectInformation'))
    {
      setIsLoading(true)
      const createdId = await mutations.createProject(form);
      if (createdId)
      {
        setProjectId(createdId)
      }
      setIsLoading(false)
    }
  };

  const handleAddProjectPhasesClick = async (): Promise<void> =>
  {
    if (isAddProjectValidationPassed(form, 'projectPhases'))
    {
      setIsLoading(true)
      await mutations.createProjectPhases(form);
      setIsLoading(false)
    }
  };

  const handleAddPhaseReviewersClick = async (): Promise<void> =>
  {
    setIsLoading(true)
    await mutations.createPhaseReviewers(form);
    setIsLoading(false)
  };

  const handleAddProjectLogoClick = async (): Promise<void> =>
  {

    setIsLoading(true)
    await mutations.createProjectLogo(form);
    setIsLoading(false)

  };

  const handlePhaseItemRemove = (index: number): void =>
  {
    const updatedList = [...form.phases];
    updatedList.splice(index, 1);
    setForm({ ...form, phases: updatedList });
  };

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ): void =>
  {
    const { value, name } = event.currentTarget;

    setForm({ ...form, [name]: value });
  };

  const setFormQuestions = (
    phases: ProjectPhase[]
  ): void =>
  {
    setForm({ ...form, phases });
  };

  const addProjectQuestion = (event: MouseEvent<HTMLElement>): void =>
  {
    const { id : typeId} = event.currentTarget

    const type  = typeId  as TemplateFieldTypes;
    const { phases } = form;
    const isYesNoDecision = type === TemplateFieldTypes.YES_NO_DECISION;
    const initialOptions = isYesNoDecision ? INITIAL_OPTIONS_YES_NO : [];
    const newTextQuestion: ITemplateField = {
      title: '',
      isEditing: true,
      isAdded: true,
      type,
      options: initialOptions,
      id: phases.length.toString(),
      order: phases.length - 1,
    };
    const updatedQuestionList = [...phases, newTextQuestion];

    setForm({ ...form, phases: updatedQuestionList });
  };

  const setProjectPhases = async (templateId: string): Promise<void> =>
  {
    // Fetch template phases by id
    try
    {
      const templatePhases = await getTemplatePhases(templateId);

      if (templatePhases)
      {
        setForm({
          ...form,
          phases: templatePhases as TTemplatePhase[],
          selectedTemplateId: templateId,
        });
      }
    } catch (error)
    {
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  };

  const handleRemovePhaseReviewer = (index: number): void =>
  {
    const { phaseReviewers } = form;
    const updatedReviewers = [...phaseReviewers];
    updatedReviewers.splice(index, 1);
    setForm({ ...form, phaseReviewers: updatedReviewers });
  };

  const addPhaseReviewer = (): void =>
  {
    const { phaseReviewers } = form;
    const updatedReviewers = [...phaseReviewers];
    updatedReviewers.push({
      email: reviewerEmail,
      id: phaseReviewers.length ? `${phaseReviewers.length - 1}` : `0`,
    });
    setForm({ ...form, phaseReviewers: updatedReviewers });
  };

  const handleAddReviewerClick = (): void =>
  {
    addReviewerClick(addPhaseReviewer);
  };

  const handlePublishProject = async (): Promise<void> =>
  {
    if (form.id && form.status !== 'published') {
      await mutations.publishProject(form);
      
    }
    
  };

  const handleAddReviewerKeyDown = (): void =>
  {
      handleAddReviewerClick();
  };

  const handleAddProjectClick = async (): Promise<void> =>
  {
    if (isAddProjectValidationPassed(form))
    {
      try
      {
        setIsLoading(true)
        await mutations.createProject(form);
        history.push(DASHBOARD);
        setIsLoading(false)
      } catch {
        setIsLoading(false)
      }
    }
  };

  const handleLogoSelect = async (
    event?: React.ChangeEvent<HTMLInputElement>,
  ): Promise<void> =>
  {
    if (event)
    {
      const target = event.target as HTMLInputElement;
      if (target?.files?.[0])
      {
        const image = target.files[0];

        const uri = await resizeFile(image);

        const FReader = new FileReader();
        FReader.readAsDataURL(uri as Blob);

        FReader.onload = (FREvent) =>
        {
          if (FREvent.target)
          {
            setForm({
              ...form,
              logo: image,
              logoSrc: FREvent.target.result! as string,
            });
          }
        };
      }
    } else
    {
      setForm({
        ...form,
        logo: null,
        logoSrc: PLACEHOLDER_URL,
      });
    }
  };

  return {
    form,
    reviewerEmail,
    showAddReviewer,
    handleLogoSelect,
    handleAddReviewerClick,
    handleAddProjectInformationClick,
    handleAddProjectPhasesClick,
    handleAddProjectLogoClick,
    handleAddReviewerKeyDown,
    handleAddPhaseReviewersClick,
    handleReviewerEmailInput,
    handleRemovePhaseReviewer,
    addProjectQuestion,
    handleFormChange,
    handleAddProjectClick,
    handlePhaseItemRemove,
    setProjectPhases,
    setIsLoading,
    setFormQuestions,
    isLoading,
    votingHasStarted,
    handlePublishProject
  };
};

export default useAddProject;
