import { IPhaseReviewer } from 'models/addProject';
import React from 'react'
import ProjectReviewer from './ProjectReviewer';

    
type Props = {
    reviewers: IPhaseReviewer[];
}

 const ProjectReviewers: React.FC<Props> = ({reviewers}: Props) => (
    <div className="reviewers-images">
        {reviewers.map((reviewer) => <ProjectReviewer email={reviewer.email}
 />)}

  </div>
  )
export default ProjectReviewers