import React from 'react';

import { BrowserRouter as Router } from 'react-router-dom';
import Main from 'components/Main';

const App: React.FC = () => (
  <Router>
    <Main />
  </Router>
);

export default App;
