import React from 'react';

import PageContainer from 'components/Common/PageContainer';
import ProfileForm from './ProfileForm';

const Profile: React.FC = () => (
    <PageContainer className="profile">
       <ProfileForm     />
    </PageContainer>
  );

export default Profile;
