import
{
  useEffect,
  useState
} from 'react';

import { getCurrentUserId } from 'helpers/firebase';

import { queries } from 'api';

import { Collections } from 'api/constants';
import { IUpdateProfile } from 'models/updateProfile';
import { IAddProject } from 'models/addProject';


interface IUseProjects
{
  projects: IAddProject[] | undefined;
}

const useProjects = (): IUseProjects =>
{
  const [profile, setProfile] = useState<IUpdateProfile | undefined>()
  const [projects, setProjects] = useState<IAddProject[] | undefined>()

  const currentUserId = getCurrentUserId();

  useEffect(() =>
  {
    if (!currentUserId)
    {
      return;
    }

    queries.getDocument(Collections.USERS, currentUserId, setProfile)

  }, [currentUserId]);


  useEffect(() =>
  {
    if (!profile?.projectIds?.length)
    {
      return;
    }
    queries.getAssignedProjects(profile.projectIds, setProjects)
  }, [profile]);


  return {
    projects,
  };
};

export default useProjects;


