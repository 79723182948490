import { useHistory } from 'react-router-dom';

import { TEMPLATES } from 'constants/routes';
// import { Errors } from 'constants/notifications';

import { queries } from 'api';

// import useFetchInitialData from 'hooks/useFetchInitialData';
import { useEffect, useState } from 'react';

interface IUseTemplates
{
  templates: ITemplate[];
  // isLoading: boolean;
  redirectToCreateTemplatePage: () => void;
}

export interface ITemplate
{
  name: string;
  id: string;
}

const useTemplates = (): IUseTemplates =>
{
  const [templates, setTemplates] = useState<ITemplate[]>([])
  const history = useHistory();
  // const { isLoading, result } = useFetchInitialData({
  //   request: queries.getTemplates,
  //   error: Errors.TEMPLATES_FETCHING_FAIL,
  // });

  useEffect(() =>
  {
    queries.getTemplates(setTemplates)
  }, []);


  const redirectToCreateTemplatePage = (): void =>
  {
    history.push(TEMPLATES);
  };


  return { templates, redirectToCreateTemplatePage };
};

export default useTemplates;
