import React from 'react';
import { ITemplateFieldOption } from 'models/createTemplate';

interface ITextChoise {
  isQuestionEdit: boolean;
  options: ITemplateFieldOption[];
  questionId: string;
  editableOptions: boolean;
  handleQuestionOptionChanged: (
    e: React.ChangeEvent<HTMLInputElement>,
  id:string, optionIndex:number) => void 
  handleAddQuestionOption: (id:string) => void
  handleRemoveQuestionOption:  (id:string, index: number | undefined) => void;
}

const TextChoise: React.FC<ITextChoise> = (props: ITextChoise) => { 

  const {
    isQuestionEdit,
    options,
    questionId,
    editableOptions,
    handleQuestionOptionChanged,
    handleAddQuestionOption,
    handleRemoveQuestionOption
  } = props

  const isEdit = isQuestionEdit && editableOptions;

  if (options) {
    return (
      <form>
        {options.map((option) => {
          const { text, index, letter } = option;

          const handleRemove = (): void => {
            handleRemoveQuestionOption(questionId, index!);
          };

          const handleChange = (
            e: React.ChangeEvent<HTMLInputElement>,
          ): void => {
            handleQuestionOptionChanged(e, questionId, index!);
          };

          if (isEdit) {
            return (
              <div
              className="answers-container 
                text-type-container no-focus-outline"
              key={index}
            >
              <label className="radio-container container-text">
                <input className="radio-btn" type="radio" name="radio" />
                <span className="checkmark">{letter}</span>
              </label>
              <div className="form-field-container">
                <input
                  type="text"
                  className="opt-answer"
                  placeholder="Type answer..."
                  value={text}
                  onChange={handleChange}
                  maxLength={30}
                  style={{width: '30rem'}}
                />

                <span className="opt-answer-error">
                  {text?.length === 30 ? 'Max length of 30 characters' : ''}
                </span>
              </div>
              <div className="">
              <button
                type="button"
                className="delete-answer-btn delete-text-btn"
                onClick={handleRemove}
              >
                Remove
                <i className="fas fa-trash" />
              </button>
              </div>
            </div>
            );
          }
          return (
            <label className="radio-container container-text" key={index}>
              {text}
              <input type="radio" name="radio" disabled />
              <span className="checkmark">{letter}</span>
            </label>
          );
        })}
        {isEdit && (
          <div className="add-answer-container no-focus-outline">
            <button
              className="add-answer-btn add-text-btn"
              type="button"
              onClick={() => handleAddQuestionOption(questionId)}
            >
              <i className="fas fa-plus" />
            </button>
            <span>Add Answer</span>
          </div>
        )}
      </form>
    );
  }
  return null;
};

export default TextChoise;
