import { useCallback, useEffect } from 'react';

const EVENT_TYPE_FIREFOX = 'DOMMouseScroll';
const EVENT_TYPE_OTHER_BROWSERS = 'mousewheel';

const useScrollDown = (callback: () => void): void => {
  const wheel = useCallback(
    (e: Event): void => {
      const isMouseWheel = e.type === EVENT_TYPE_OTHER_BROWSERS;
      const { deltaY, detail } = e as WheelEvent;
      const parameter = isMouseWheel ? deltaY : detail;
      if (parameter > 0) {
        callback();
        document.removeEventListener(EVENT_TYPE_OTHER_BROWSERS, wheel);
        document.removeEventListener(EVENT_TYPE_FIREFOX, wheel);
      }
    },
    [callback],
  );

  useEffect(() => {
    document.addEventListener(EVENT_TYPE_OTHER_BROWSERS, wheel);
    document.addEventListener(EVENT_TYPE_FIREFOX, wheel);
  }, [wheel]);
};

export default useScrollDown;
