import React from 'react';

interface IUploadImage {
  handleRemove: () => void;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  letter?: string;
  index: number;
  logoSrc: string;
  alt: string;
}

const UploadImage: React.FC<IUploadImage> = ({
  handleRemove,
  handleFileChange,
  letter,
  index,
  logoSrc,
  alt,
}) => {
  const withCheckmark = Boolean(letter);

  return (
    <div
      className="answers-container 
        images-type-container no-focus-outline"
    >
      <div className="inline">
        {withCheckmark && (
          <label className="radio-container container-img">
            <input type="radio" name="radio" disabled />
            <span className="checkmark">{letter}</span>
          </label>
        )}
        <div className="upload-img">
          <img className="upload-img__preview" src={logoSrc} alt={alt} />
          <input
            className="upload-img__input"
            type="file"
            id={`img-${index}`}
            maxLength={1}
            onChange={handleFileChange}
          />
          <label htmlFor={`img-${index}`}>Upload</label>
        </div>
      </div>
      <button
        // styleType="secondary"
        type="button"
        className="delete-answer-btn delete-img-btn"
        onClick={handleRemove}
      >
        Remove
        <i className="fas fa-trash" />
      </button>
    </div>
  );
};

export default UploadImage;
