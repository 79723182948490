import React from 'react';

import Option from 'components/ProjectPhase/Options/Option';

import { IOption, IVote } from 'models/questions';
import { ANSWER_LETTERS } from 'constants/common';

interface IImages {
  options: IOption[];
  isDisplayModeGrid: boolean;
  selectedAnswer?: string;
  commentAnswer?: string;
  textarea: string;
  answerSubmitted: boolean;
  onTextareaChange: (event: React.FormEvent<HTMLTextAreaElement>) => void;
  onAnswerChange: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  onImageClick: (isOpen: boolean, displayedValue?: string) => void;
  onCommentClick: (answer: string) => void;
  votes?: IVote[];
}

const Options: React.FC<IImages> = ({
  options,
  isDisplayModeGrid,
  selectedAnswer,
  commentAnswer,
  textarea,
  answerSubmitted,
  onTextareaChange,
  onAnswerChange,
  onImageClick,
  onCommentClick,
  votes,
}) => {
  const isTextarea = options.length === 0;
  const totalVoteCount = votes?.length || 0;
  const answerOptions =
    options?.map((option, index) => ({
      letter: ANSWER_LETTERS[index],
      text: option.text,
    })) || [];

  const optionVotePercentages = answerOptions?.map((option) => {
    const count = votes?.filter((v) => v.answer === option.letter).length || 0;
    return {
      percentage: (count / totalVoteCount) * 100,
      text: option.text,
    };
  });

  return (
    <div className="logo-selection ">
      <div className="logo-selection__options">
        <div
          className={
            isDisplayModeGrid ? 'grid-container-3' : 'grid-container-1'
          }
        >
          {!isTextarea &&
            options.map((option, index) => (
              <Option
                votePercentage={
                  optionVotePercentages.find((o) => o.text === option.text)
                    ?.percentage || 0
                }
                option={option}
                index={index}
                key={`${option.fileName || option.text}`}
                isDisplayModeGrid={isDisplayModeGrid}
                selectedAnswer={selectedAnswer}
                commentAnswer={commentAnswer}
                answerSubmitted={answerSubmitted}
                onAnswerChange={onAnswerChange}
                onImageClick={onImageClick}
                onCommentClick={onCommentClick}
              />
            ))}
          {isTextarea && (
            <textarea
              disabled={answerSubmitted}
              className="textarea"
              placeholder="Write your text..."
              value={textarea}
              onChange={onTextareaChange}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Options;
