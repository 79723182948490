import React from 'react';

import TemplateProvider from 'context/TemplateProvider';
import CreateTemplateComponent from 'components/Template/CreateTemplate';

const CreateTemplate: React.FC = () => (
  <TemplateProvider>
    <CreateTemplateComponent />
  </TemplateProvider>
);

export default CreateTemplate;
