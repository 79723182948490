export const LOGIN = '/log-in';
export const SIGN_UP = '/sign-up';
export const DASHBOARD = '/home';
export const PROJECTS = '/projects';
export const EDIT_PROJECT = '/edit-project';
export const CREATE_PROJECT = '/create-project';
export const CREATE_TEMPLATE = '/create-template';
export const TEMPLATES = '/templates';
export const TEMPLATE = '/template';
export const PROJECT = '/project';
export const PROFILE = '/profile';
export const ONBOARDING = '/getting-started';
export const FORGOT_PASSWORD = '/forgot-password';
export const REVIEWERS= '/reviewers';

export const QUERY_PARAMS = {
  EMAIL: 'email',
  PROJECT_ID: 'projectId'
}
