import React from 'react';

import Routes from 'components/Routes';
import useAuthorisation from 'hooks/useAuthorisation';
import PageContainer from 'components/Common/PageContainer';
import ContentContainer from 'components/Common/ContentContainer';
import LoaderWrapper from 'components/Common/LoaderWrapper';

const Main: React.FC = () => {
  const { isLoading } = useAuthorisation();
  return (
    <>
      {isLoading ? (
        <>
          <PageContainer isSkeleton>
            <ContentContainer isFullHeight>
              <LoaderWrapper isLoading={isLoading}>
                <></>
              </LoaderWrapper>
            </ContentContainer>
          </PageContainer>
        </>
      ) : (
        <Routes />
      )}
    </>
  );
};

export default Main;
