/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';

import useForgotPassword from 'components/ForgotPassword/useForgotPassword';

import { SIGN_UP, LOGIN } from 'constants/routes';

enum ForgotPasswordFields {
  email = 'email',
}

const ForgotPassword: React.FC = () => {
  const { handleInputChange, form, handleSubmitClicked, requestError } =
    useForgotPassword();

  return (
    <div className="navigation">
      <div className="navigation__main-panel">
        <Link to={LOGIN} className="half-circle-btn half-circle-btn-left">
          Login
        </Link>
        <div className="forgot-password">
          <p className="upper-logo-text">Forgot Password</p>
          <div>
            <input
              type="text"
              className="forgot-password__input"
              placeholder="Email"
              onChange={handleInputChange}
              name={ForgotPasswordFields.email}
              value={form.email}
            />
            <div
              className="forgot-password__button forgot-password--email"
              onClick={handleSubmitClicked}
              onKeyDown={handleSubmitClicked}
              role="button"
              tabIndex={-1}
            >
              Submit
            </div>
            {requestError.length > 0 && (
              <p className="forgot-password__error">{requestError}</p>
            )}
          </div>
        </div>
        <Link to={SIGN_UP} className="half-circle-btn half-circle-btn-right">
          Register
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;
